import axios from "axios";
import userStore from '@/store/module/user'
// import {decrypt,encrypt} from '@/utils/Decryption.js'
import {nextTick} from "vue";
import {useI18n} from "vue-i18n";

axios.defaults.timeout = 100 * 1000
axios.defaults.headers['content-type'] = 'application/json';
// axios.defaults.headers.common['Accept'] = "*/*"
if (import.meta.env.DEV) {
    axios.defaults.baseURL = '/proxy_api'
} else if (!import.meta.env.DEV) {
    axios.defaults.baseURL = '';
}
//请求拦截
axios.interceptors.request.use(
    config => {
        const user = userStore()
        const token = user.token;
        config.headers['authorization'] = token || "";
        config.headers['language'] = xb.$sys.lang?.key?xb.$sys.lang?.key:xb.defaultLang
        return config;
    },
    error => {
        return Promise.error(error);
    })
// 响应拦截
axios.interceptors.response.use(
    response => {
        const user = userStore()
        //     TODO 待实现 cs
        if (response.status === 200) {
            let res;
            try {
                res = response.data
            } catch (e) {
                res = response.data
            }
            if (!res.code || res.code != 'ERROR') {

                if(res.code && res.code==='NOT_LOGIN' && (!xb.$user.isLogin) && response.request.responseURL.indexOf('login') == -1){
                    xb.$emit('noLogin',res)
                    if(res.code==='NOT_LOGIN'){
                        return Promise.reject(res)
                    }
                    return Promise.resolve(res)
                }
                return Promise.resolve(res)
            } else {
                let timmer = setTimeout(() => {
                    nextTick(() => {
                        xb.message.error(res.message)
                    })
                }, 100)
                let params = {
                    response: res,
                    callback: (err) => {
                        // console.log(err, '数据')
                        clearTimeout(timmer)
                    }
                }
                let promise = Promise.reject(params)
                return promise
            }
        }

    },
    error => {
        return Promise.reject(error?.response ?? error);
    });
export default axios
