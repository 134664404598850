export default {
    receiving:'Orario di ricezione',
    shipments:'tempo di elaborazione',
    size:'grandezza',
    rate:'Tasso di profitto',
    color:'Colore',
    "out":"Manutenzione offline in corso, contattare il servizio clienti",
    "rem":"Ricorda la password dell'account",
    confirm:'Confirmare la ricezione',
    "add4":{"t":"Memorizza I Dati","dj":"Livello di archiviazione","bl":"Tasso Di Commissione","je":"Importo Di Ricarica","jl":"Ricompense di ricarica","ll":"Supporto giornaliero al traffico"},
    "add2":{"fy1":"Carta d'identità anteriore","fy2":"Foto d'identità sul retro","fy3":"Foto d'identità portatile"},
    "add3":{"fy1":"Prezzo totale delle merci","fy2":"Utile Singolo Pezzo","fy3":"Utile Totale"},
    "newAdd":{"fy1":"Punteggio Di Credito","fy2":"Ricarica","fy3":"Il numero di prodotti che possono essere elencati è","fy4":"Ne Sono Consapevole","fy5":"Descrizione del pacchetto"},
    dongjies:'Questo account è stato bloccato, contatta il servizio clienti',
    dongjie:'I fondi sono stati congelati, si prega di contattare il servizio clienti',
    "mg": {
        "fy1": "Tesoro degli interessi",
        "fy2": "Coupon",
        "fy3": "Ricarica Mobile",
        "fy4": "Codice Invito",
        "fy5": "In Custodia",
        "fy6": "Giorno",
        "fy7": "Rendimento giornaliero",
        "fy8": "Acquisto Minimo",
        "fy9": "Abbonamento immediato",
        "fy10": "Saldo Disponibile",
        "fy11": "Inserisci l'importo dell'acquisto",
        "fy12": "Intero",
        "fy13": "Durata",
        "fy14": "Rendimento giornaliero",
        "fy15": "Tempo di pagamento dei dividendi",
        "fy16": "Tutti I Giorni",
        "fy17": "Fondi Fiduciari",
        "fy18": "Rimborso Dovuto",
        "fy19": "Acquisto Minimo",
        "fy20": "Entrate Previste",
        "fy21": "Abbonamento immediato",
        "fy22": "Acquisto Minimo",
        "fy23": "Equilibrio insufficiente, si prega di ricaricare e riprovare",
        "fy24": "Record Ordini",
        "fy25": "Tutti Gli Ordini",
        "fy26": "In Corso",
        "fy27": "Completato",
        "fy28": "Tempo Rimanente",
        "fy29": "In Corso",
        "fy30": "Completato",
        "fy31": "Numero D'ordine",
        "fy32": "Importo dell'abbonamento",
        "fy33": "Utile Giornaliero",
        "fy34": "Durata",
        "fy35": "Entrate Previste",
        "fy36": "Utile",
        "fy37": "Data Di Acquisto",
        "fy38": "Redenzione",
        "fy39": "Conferma Il Riscatto",
        "fy40": "Annulla Rimborso",
        "fy41": "Sei sicuro di riscattare i fondi di custodia",
        "fy42": "Nota: Una volta riscattati i fondi di custodia, gli interessi saranno liquidati",
        "fy43": "Ricarica Mobile",
        "fy44": "Record Di Ricarica",
        "fy45": "Inserisci il tuo numero di telefono",
        "fy46": "Importo Di Ricarica",
        "fy47": "Non Selezionato",
        "fy48": "Si prega di notare che dopo aver effettuato la ricarica, si prega di prestare attenzione al pagamento della bolletta telefonica",
        "fy49": "Ricarica Ora",
        "fy50": "Inserisci il tuo numero di telefono",
        "fy51": "Vai A Commentare",
        "fy52": "Recensito",
        "fy53": "Scrivi Un Commento",
        "fy54": "Valutazione del prodotto",
        "fy55": "Scrivi Un Commento",
        "fy56": "Lascia i tuoi preziosi commenti",
        "fy57": "Sole Bene",
        "fy58": "Invia",
        "fy59": "Molto Povero",
        "fy60": "Molto Povero",
        "fy61": "Comunemente",
        "fy62": "Molto Buono",
        "fy63": "Molto Buono",
        "fy64": "Record Di Ricarica",
        "fy65": "Ricaricato con successo",
        "fy66": "Codice Invito",
        "fy67": "Inserisci il codice dell'invito",
        "fy68": "Utile Totale",
        "fy69": "Il Profitto Di Oggi",
        "fy70": "Incompleto",
        "fy71": "Il coupon attuale ha raggiunto il limite di acquisto",
        "fy72": "Soglia cedola non raggiunta",
        "fy73": "Ricarica Utente",
        "fy74": "Questo Pagamento",
        "fy75": "Inserisci la quantità",
        "fy76": "Pagamento Rapido",
        "fy77": "Ricarica Ora",
        "fy78": "Seleziona il tuo portafoglio di pagamento",
        "fy79": "Corrente",
        "fy80": "Catena del campo d'onda",
        "fy81": "Catena Ethereum",
        "fy82": "Catena di sicurezza delle monete",
        "fy83": "Catena Italiana Europea",
        "fy84": "Ouyi",
        "fy85": "Catena",
        "fy86": "Canale",
        "fy87": "Preferito"
    },
    "newadd": { "fy1": "Nome titolare della carta", "fy2": "Inserisci il nome del titolare della carta", "fy3": "Numero della carta di credito", "fy4": "Inserisci il numero della tua carta di credito", "fy5": "Nome Della Banca", "fy6": "Inserisci il nome della banca", "fy7": "Codice Bancario", "fy8": "Inserisci il tuo codice bancario", "fy9": "Consegna del commercio elettronico", "fy10": "Semplifica il processo di consegna e fornisci ai tuoi clienti opzioni di consegna veloci e convenienti. La nostra piattaforma assicura che i vostri articoli siano consegnati in modo sicuro e puntuale ogni volta.", "fy11": "Tracciamento in tempo reale", "fy12": "Tieni i tuoi clienti informati sugli aggiornamenti di monitoraggio in tempo reale ad ogni passo. La nostra piattaforma ti offre l'esperienza utensile necessaria per una consegna di prima classe.", "fy13": "Scalabilità", "fy14": "Man mano che il tuo business cresce, anche la nostra piattaforma crescerà di conseguenza. La nostra rete di consegna mira a seguire la vostra attività e garantire che possiate gestire qualsiasi aumento del volume di consegna." },
    "components": {
        "fy1": "Pezzo",
        "fy2": "Pagamento",
        "fy3": "Password Dimenticata",
        "fy4": "Risoluzione immediata",
        "fy5": "Totale",
        "fy6": "Merce",
        "fy7": "Vai a scegliere i prodotti che ti piacciono",
        "fy8": "Si prega di aggiungere un indirizzo di spedizione prima",
        "fy9": "Si prega di impostare prima la password di pagamento",
        "fy10": "Inserisci la password",
        "fy11": "Per Pagare",
        "fy12": "Inserisci la password di pagamento",
        "fy13": "Aggiorna Password",
        "fy14": "Imposta Password",
        "fy15": "Vecchia Password",
        "fy16": "Password",
        "fy17": "Inserisci",
        "fy18": "Nuova Password",
        "fy19": "Conferma Password",
        "fy20": "Inserisci una nuova password",
        "fy21": "Conferma La Password",
        "fy22": "Conservare",
        "fy23": "Inserisci una password a 6 cifre",
        "fy24": "Inserisci la password corretta a 6 cifre",
        "fy25": "Le password inserite due volte non corrispondono",
        "fy26": "News",
        "fy27": "Invia",
        "fy28": "Caricamento dell'immagine non riuscito",
        "fy29": "Inserisci Attributi",
        "fy30": "Conferma",
        "fy31": "Carica Di Più",
        "fy32": "Acquirente",
        "fy33": "Venditore",
        "fy34": "Cassetta Postale",
        "fy35": "Telefono",
        "fy36": "Password",
        "fy37": "Password Dimenticata",
        "fy38": "Registro",
        "fy39": "Login",
        "fy40": "Accedi utilizzando il telefono",
        "fy41": "Accedi tramite e-mail",
        "fy42": "Aiuto",
        "fy43": "Inserisci un numero di telefono",
        "fy44": "Inserisci un indirizzo email",
        "fy45": "Verifica E-mail",
        "fy46": "Verifica Mobile",
        "fy47": "Inserisci il codice di verifica",
        "fy48": "Carica Di Più",
        "fy49": "Carica Di Più",
        "fy50": "Scegli Il Tuo Sesso",
        "fy51": "Ragazzi",
        "fy52": "Ragazze",
        "fy53": "Passo Successivo",
        "fy54": "Scegli La Tua Età",
        "fy55": "Scegli i tuoi interessi",
        "fy56": "Seguire almeno 4 interessi",
        "fy57": "Inizia Ad Esplorare",
        "fy58": "Scorri verso l'alto per selezionare l'età",
        "fy59": "Invia codice di verifica",
        "fy60": "Errore del codice di verifica",
        "fy61": "Anno",
        "fy62": "Riprova tra pochi secondi",
        "fy63": "Codice di verifica inviato con successo",
        "fy64": "Ripristino riuscito, per favore accedi",
        "fy65": "Sei sicuro di avviare il conto corrente?",
        "fy66": "Esci Dal Login",
        "fy67": "Sponsor",
        "fy68": "Vendite Mensili",
        "fy69": "Attualmente non ci sono dati disponibili",
        "fy70": "Pagamento Immediato",
        "fy71": "Portafoglio",
        "fy72": "Da Pagare",
        "fy73": "Conferma Pagamento",
        "fy74": "Carta d'identità, patente di guida, passaporto",
        "fy75": "Per favore carica prima il tuo ID",
        "fy76": "Applicazione riuscita, attendere la revisione",
        "fy77": "Seleziona"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Dettagli sul ritiro",
            "fy2": "Tempo",
            "fy3": "Tipo",
            "fy4": "Denaro",
            "fy5": "Stato",
            "fy6": "Revisione In Sospeso",
            "fy7": "Adottare",
            "fy8": "Rifiuti"
        },
        "withdraw": {
            "fy1": "Metodo di incorporazione",
            "fy2": "Niente",
            "fy3": "Tasso Di Servizio",
            "fy4": "Importo Del Prelievo",
            "fy5": "Inserisci L'importo",
            "fy6": "Tassa Di Servizio",
            "fy7": "Attività",
            "fy8": "Indirizzo di ritiro",
            "fy9": "Ritiro",
            "fy10": "Password di pagamento",
            "fy11": "Inserisci la password di pagamento",
            "fy12": "Conferma",
            "fy13": "Si prega di impostare prima la password di pagamento",
            "fy14": "Inserisci L'importo",
            "fy15": "Inserisci la password",
            "fy16": "Aggiungere il metodo di prelievo",
            "fy17": "Saldo Disponibile"
        },
        "shippingAddress": {
            "fy1": "Indirizzo di consegna"
        },
        "selected": {
            "fy1": "Negozi Selezionati",
            "fy2": "Ricerca",
            "fy3": "Seguire",
            "fy4": "Visite",
            "fy5": "Intero"
        },
        "orderInfo": {
            "fy1": "Dettagli Dell'ordine",
            "fy2": "Numero D'ordine",
            "fy3": "Tempo Dell'ordine",
            "fy4": "Quantità Di Prodotto",
            "fy5": "prezzo degli articoli",
            "fy6": "Pagamento Effettivo",
            "fy7": "Conferma"
        },
        "layout": {
            "fy1": "Su Di Noi",
            "fy2": "Torna All'inizio",
            "fy3": "Servizi di assistenza",
            "fy4": "Informativa sulla privacy",
            "fy5": "Aprire Un Negozio",
            "fy6": "Libero",
            "fy7": "Aprire immediatamente un negozio",
            "fy8": "Merce",
            "fy9": "Negozio",
            "fy10": "Ricerca",
            "fy11": "Annulla",
            "fy12": "Login",
            "fy13": "Di Più",
            "fy14": "Negozi Selezionati",
            "fy15": "Inserisci almeno tre caratteri per la ricerca",
            "fy16": "Cronologia Ricerca",
            "fy17": "Seguire",
            "fy18": "Visite",
            "fy19": "Visualizza Tutto"
        },
        "category": {
            "fy1": "Raccomandazioni selezionate",
            "fy2": "Negozi Selezionati",
            "fy3": "Selezionato"
        },
        "goodsDetail": {
            "fy1": "Quantità",
            "fy2": "Volume Delle Vendite",
            "fy3": "Aggiungi Al Carrello",
            "fy4": "Spedizione Urgente",
            "fy5": "Spedizione/reso gratuito",
            "fy6": "Descrizione del prodotto",
            "fy7": "Valutazione del prodotto",
            "fy8": "Stella",
            "fy9": "Nessuna valutazione attualmente disponibile",
            "fy10": "Questo prodotto è temporaneamente non disponibile per l'acquisto"
        },
        "home": {
            "fy1": "Ultimi Sconti",
            "fy2": "Buona raccomandazione del prodotto",
            "fy3": "Buy One Get One Free",
            "fy4": "Acquista Ora",
            "fy5": "Offerta speciale a tempo limitato",
            "fy6": "Conto Alla Rovescia",
            "fy7": "Prodotti Popolari",
            "fy8": "Spedizione Gratuita",
            "fy9": "Tutti I Prodotti",
            "fy10": "Negozi Popolari",
            "fy11": "Visualizza negozi popolari"
        },
        "myHeart": {
            "fy1": "Seguire",
            "fy2": "Visualizza Tutto",
            "fy3": "Saldo disponibile del portafoglio"
        },
        "shopDetail": {
            "fy1": "Seguire",
            "fy2": "Visite",
            "fy3": "Completo",
            "fy4": "Tempo",
            "fy5": "Volume Delle Vendite",
            "fy6": "Prezzo",
            "fy7": "Visualizza Tutto"
        },
        "personal": {
            "fy1": "Esci Dal Login",
            "fy2": "Centro Personale",
            "fy3": "Informazioni Di Base",
            "fy4": "Il Mio Portafoglio",
            "fy5": "Deposito",
            "fy6": "Ritiro",
            "fy7": "Dettagli della fattura",
            "fy8": "Registro dei depositi",
            "fy9": "Registro dei prelievi",
            "fy10": "Legare Usdt",
            "fy11": "Centro Ordini",
            "fy12": "Il Mio Ordine",
            "fy13": "Indirizzo di consegna",
            "fy14": "Servizi Funzionali",
            "fy15": "Negozi Selezionati",
            "fy16": "Domanda Di Fornitori",
            "fy17": "Cambia Password",
            "fy18": "Cambia password di pagamento",
            "fy19": "Imposta password di pagamento",
            "fy20": "Contatta il servizio clienti",
            "fy21": "Su Di Noi",
            "fy22": "Servizi di assistenza",
            "fy23": "Modificare",
            "fy24": "Aggiungi",
            "fy25": "Indirizzo",
            "fy26": "Nome",
            "fy27": "Inserisci",
            "fy28": "Informazioni di contatto",
            "fy29": "Conservare",
            "fy30": "Elimina",
            "fy31": "Modificato con successo",
            "fy32": "Aggiunta con successo",
            "fy33": "Impossibile eliminare l'indirizzo predefinito",
            "fy34": "Eliminato con successo",
            "fy35": "Seleziona la rete principale",
            "fy36": "Seleziona un protocollo",
            "fy37": "Indirizzo del portafoglio",
            "fy38": "Seleziona un indirizzo del portafoglio USDT",
            "fy39": "Membri Ordinari",
            "fy40": "Il Mio Equilibrio",
            "fy41": "Ricerca",
            "fy42": "La Mia Attenzione",
            "fy43": "Seguire",
            "fy44": "Visite",
            "fy45": "Intero",
            "fy46": "Dettagli della fattura",
            "fy47": "Tempo",
            "fy48": "Numero D'ordine",
            "fy49": "Denaro",
            "fy50": "Osservazioni",
            "fy51": "Password modificata correttamente, accedi",
            "fy52": "Le due password non corrispondono",
            "fy53": "La nuova password non può essere vuota",
            "fy54": "La vecchia password non può essere vuota",
            "fy55": "Cambia Password",
            "fy56": "Il Mio Account",
            "fy57": "Vecchia Password",
            "fy58": "Inserisci la tua vecchia password",
            "fy59": "Nuova Password",
            "fy60": "Inserisci una nuova password",
            "fy61": "Ripeti nuova password",
            "fy62": "Ripeti La Password",
            "fy63": "Aggiorna Password",
            "fy64": "Imposta Password",
            "fy65": "Conferma Password",
            "fy66": "Conferma La Password",
            "fy67": "Inserisci una password a 6 cifre",
            "fy68": "Inserisci la password corretta a 6 cifre",
            "fy69": "Password",
            "fy70": "Importo Del Deposito",
            "fy71": "Inserisci l'importo del deposito",
            "fy72": "Promemoria Gentile",
            "fy73": "Si tratta di una piattaforma di e-commerce che consente di acquistare prodotti noti online, scoprire nuovi negozi e venditori, cercare le necessità quotidiane nei negozi, o sfogliare una vasta selezione di prodotti in categorie come salute e bellezza, elettronica, moda, casa e vita, baby e giocattoli, ecc.",
            "fy74": "Importo Di Ricarica",
            "fy75": "Indirizzo Usdt",
            "fy76": "Copia",
            "fy77": "Ricarica UnionPay, si prega di contattare il segretario del servizio clienti per gestire l'attività",
            "fy78": "Carica il voucher di pagamento",
            "fy79": "Invia",
            "fy80": "Inserisci L'importo",
            "fy81": "Attualmente non è disponibile alcun canale di pagamento",
            "fy82": "File Troppo Grande",
            "fy83": "Dettagli del deposito",
            "fy84": "Tempo",
            "fy85": "Tipo",
            "fy86": "Denaro",
            "fy87": "Stato",
            "fy88": "Revisione In Sospeso",
            "fy89": "Adottare",
            "fy90": "Rifiuti",
            "fy91": "Cos'è Lo Shopping",
            "fy92": "Si tratta di una piattaforma di e-commerce che ti permette di acquistare marchi che già conosci online scoprendo nuovi negozi e venditori.",
            "fy93": "Cerca gli elementi essenziali quotidiani nel negozio o sfoglia una vasta selezione di prodotti in categorie come salute e bellezza, elettronica, moda, casa e vita, neonati e giocattoli.",
            "fy94": "Puoi anche prestare attenzione alle infinite attività promozionali sulla nostra piattaforma. Rimani aggiornato con offerte flash giornaliere, gioca a giochi per vincere prodotti o guarda conferenze dal vivo per godere di sconti esclusivi in streaming dal vivo.",
            "fy95": "Quando sei pronto per effettuare un acquisto, assicurati che la piattaforma ti permetta di acquistare attraverso un canale sicuro e senza soluzione di continuità. Puoi sfogliare le valutazioni e le recensioni dei prodotti, utilizzare Garanzia per pagamenti senza rischi e monitorare facilmente lo stato di consegna del prodotto.",
            "fy96": "Se vuoi saperne di più, consulta il nostro ultimo articolo del centro assistenza per lo shopping per iniziare, oppure puoi terminare il tour veloce attraverso questo video.",
            "fy97": "Il Mio Ordine",
            "fy98": "Numero D'ordine",
            "fy99": "Tempo Di Acquisto",
            "fy100": "Pagamento Effettivo",
            "fy101": "Dettagli",
            "fy102": "Rimborso",
            "fy103": "Contatta il venditore",
            "fy104": "Intero",
            "fy105": "Pagamento dell'acquirente",
            "fy106": "In attesa della spedizione",
            "fy107": "Spedito",
            "fy108": "Consegna In Corso",
            "fy109": "Ricevuta dell'acquirente",
            "fy110": "Ritorno",
            "fy111": "Restituito",
            "fy112": "Domanda Di Fornitori",
            "fy113": "Telefono Cellulare",
            "fy114": "Cassetta Postale",
            "fy115": "Inserisci il tuo indirizzo email",
            "fy116": "Inserisci un numero di telefono",
            "fy117": "Codice Di Verifica",
            "fy118": "Inserisci il codice di verifica",
            "fy119": "Invia Da Nuovo",
            "fy120": "Ottenere il codice di verifica",
            "fy121": "Password",
            "fy122": "Inserisci la password",
            "fy123": "Codice di raccomandazione",
            "fy124": "Inserisci il codice di raccomandazione",
            "fy125": "Categoria di fornitori",
            "fy126": "Seleziona il tipo di commerciante",
            "fy127": "Nome Archivio",
            "fy128": "Inserisci il nome del negozio",
            "fy129": "Conserva Logo",
            "fy130": "Carica il logo del negozio",
            "fy131": "Carta d'identità, patente di guida, passaporto",
            "fy132": "Carica la tua carta d'identità, patente di guida e passaporto",
            "fy133": "Invia",
            "fy134": "Seleziona una categoria",
            "fy135": "Verifica mobile non riuscita",
            "fy136": "Verifica e-mail non riuscita",
            "fy137": "Seleziona il tipo di commerciante"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Home Page",
            "fy2": "Strumenti Di Base",
            "fy3": "Servizio Online",
            "fy4": "Messaggi Di Sistema",
            "fy5": "Centro Personale"
        },
        "home": {
            "fy1": "Dati Aziendali",
            "fy2": "Numero totale di ordini",
            "fy3": "Utile Totale",
            "fy4": "Volume totale delle vendite",
            "fy5": "Ordine Riuscito",
            "fy6": "Volume totale delle transazioni",
            "fy7": "Totale Visite",
            "fy8": "Lo Stesso Giorno",
            "fy9": "Questa Settimana",
            "fy10": "Questo Mese",
            "fy11": "Numero Di Ordini",
            "fy12": "Numero di ordini completati",
            "fy13": "Numero Di Ordini",
            "fy14": "Numero di ordini completati",
            "fy15": "Esci Dal Login",
            "fy16": "Seguire",
            "fy17": "Visite",
            "fy18": "Saldo Del Conto",
            "fy19": "Servizio Ordini",
            "fy20": "Tutti Gli Ordini",
            "fy21": "Ordini Pendenti",
            "fy22": "Ordini Di Spedizione",
            "fy23": "Ordine di restituzione/rimborso",
            "fy24": "Ordini Da Valutare",
            "fy25": "Dati Aziendali",
            "fy26": "Dati Aziendali",
            "fy27": "Il Mio Ordine",
            "fy28": "Numero D'ordine",
            "fy29": "Tempo Di Acquisto",
            "fy30": "Pagamento Effettivo",
            "fy31": "Dettagli",
            "fy32": "Conferma",
            "fy33": "Intero",
            "fy34": "Pagamento dell'acquirente",
            "fy35": "In attesa della spedizione",
            "fy36": "Spedito",
            "fy37": "Consegna In Corso",
            "fy38": "Ricevuta dell'acquirente",
            "fy39": "Ritorno",
            "fy40": "Restituito",
            "fy41": "Pagamento dell'acquirente",
            "fy42": "In attesa della spedizione",
            "fy43": "Spedito",
            "fy44": "Consegna In Corso",
            "fy45": "Ricevuta dell'acquirente",
            "fy46": "Ritorno",
            "fy47": "Restituito",
            "fy48": "Dettagli Dell'ordine",
            "fy49": "Numero D'ordine",
            "fy50": "Copia",
            "fy51": "Tempo Dell'ordine",
            "fy52": "Quantità Di Prodotto",
            "fy53": "prezzo degli articoli",
            "fy54": "Pagamento Effettivo",
            "fy55": "Conferma"
        },
        "tool": {
            "fy1": "Esci Dal Login",
            "fy2": "Seguire",
            "fy3": "Visite",
            "fy4": "Strumenti Di Base",
            "fy5": "Centro All'ingrosso",
            "fy6": "Gestione dei prodotti",
            "fy7": "gestione degli ordini",
            "fy8": "Margine",
            "fy9": "Gestione della valutazione",
            "fy10": "Aggiornamento aziendale",
            "fy11": "Top Trending",
            "fy12": "Servizio Online",
            "fy13": "Revisione del negozio in corso",
            "fy14": "Top Trending",
            "fy15": "Modulo D'ordine",
            "fy16": "Spinta Mirata",
            "fy17": "Spingere Veloce",
            "fy18": "Sarà presente nelle raccomandazioni popolari",
            "fy19": "Giorno",
            "fy20": "Esclusiva per i nuovi negozi",
            "fy21": "Sarà In Futuro",
            "fy22": "Negozi Consigliati",
            "fy23": "Commissioni richieste",
            "fy24": "Leggere e utilizzare il Contratto di Servizio",
            "fy25": "Conferma Pagamento",
            "fy26": "Equilibrio insufficiente, si prega di ricaricare prima",
            "fy27": "Consigliare il negozio a potenziali utenti",
            "fy28": "Raccomandazione intelligente del sistema",
            "fy29": "Spinta diretta personalizzata",
            "fy30": "Il Mio Codice Push",
            "fy31": "Copia",
            "fy32": "Configurazione push personalizzata",
            "fy33": "Schema di corrispondenza automatica del codice push fisso in ingresso",
            "fy34": "Genere (scelta unica)",
            "fy35": "Età (scelte multiple)",
            "fy36": "Categoria di interesse (scelta unica)",
            "fy37": "Paese/regione",
            "fy38": "Conferma",
            "fy39": "Illimitato",
            "fy40": "Maschio",
            "fy41": "Femmina",
            "fy42": "Dettagli Dei Dati",
            "fy43": "Nuove Visite",
            "fy44": "Numero di nuovi ventilatori",
            "fy45": "Nuovo volume di transazione",
            "fy46": "Importo dell'operazione",
            "fy47": "Quantità di ordine nuovo",
            "fy48": "Percentuale",
            "fy49": "Oggi",
            "fy50": "Stato Di Avvio",
            "fy51": "Numero D'ordine",
            "fy52": "Tempo Di Acquisto",
            "fy53": "Ciclo Di Acquisto",
            "fy54": "Importo dell'acquisto",
            "fy55": "Tipo Di Acquisto",
            "fy56": "Spinta Mirata",
            "fy57": "Spingere Veloce",
            "fy58": "Dettagli Dei Dati",
            "fy59": "Tutti Gli Ordini",
            "fy60": "In Avvio",
            "fy61": "Fine Del Lancio",
            "fy62": "Centro All'ingrosso",
            "fy63": "Prodotto Selezionato",
            "fy64": "Codice Prodotto",
            "fy65": "Inserisci il codice del prodotto",
            "fy66": "Ricerca",
            "fy67": "Prezzo Minimo",
            "fy68": "Prezzo Massimo",
            "fy69": "Ricerca Di Prodotti",
            "fy70": "Classifica best seller",
            "fy71": "Prima di vendere bene",
            "fy72": "Classificazione degli utili",
            "fy73": "Prima Del Profitto",
            "fy74": "Classificazione dei requisiti",
            "fy75": "Prima Della Domanda",
            "fy76": "Elenco Rapido",
            "fy77": "Scaffale Manuali",
            "fy78": "Selezione Strategica",
            "fy79": "Seleziona un prodotto",
            "fy80": "Lanciato con successo",
            "fy81": "Scelta",
            "fy82": "Seleziona il tipo di prodotto (sono consentite scelte multiple)",
            "fy83": "Seleziona un tipo di prodotto",
            "fy84": "Inserisci L'importo",
            "fy85": "Inserisci la quantità di prodotti",
            "fy86": "Corrispondenza",
            "fy87": "Raccomandazione di corrispondenza del sistema",
            "fy88": "Corrispondenza Co",
            "fy89": "Voce",
            "fy90": "Rimborso",
            "fy91": "Pagare E Utilizzare",
            "fy92": "Inserisci",
            "fy93": "Numero",
            "fy94": "Seleziona un tipo di prodotto",
            "fy95": "Inserisci la quantità di prodotti",
            "fy96": "Inserisci L'importo",
            "fy97": "Ricerca Di Prodotti",
            "fy98": "Codice Prodotto",
            "fy99": "Copia",
            "fy100": "Prezzo D'acquisto",
            "fy101": "Prezzo Promozionale",
            "fy102": "Prodotti fuori scaffale",
            "fy103": "Margine",
            "fy104": "Invia",
            "fy105": "Tutti I Prodotti",
            "fy106": "Numero di osservazioni",
            "fy107": "Operazione",
            "fy108": "Visualizza Dettagli",
            "fy109": "Questo prodotto ha un totale di",
            "fy110": "Osservazioni"
        },
        "sys": {
            "fy1": "Messaggi Di Sistema",
            "fy2": "Impacchettate",
            "fy3": "Dettagli",
            "fy4": "Per Spedire"
        },
        "myCenter": {
            "fy1": "Esci Dal Login",
            "fy2": "Centro Personale",
            "fy3": "La Mia Delegazione",
            "fy4": "Il Mio Invito",
            "fy5": "Il Mio Portafoglio",
            "fy6": "Trasferimento",
            "fy7": "Deposito",
            "fy8": "Ritiro",
            "fy9": "Dettagli della fattura",
            "fy10": "Vincolante",
            "fy11": "Servizi Funzionali",
            "fy12": "Cambia Password",
            "fy13": "Cambia password di pagamento",
            "fy14": "Imposta password di pagamento",
            "fy15": "Informazioni sul deposito",
            "fy16": "Seguire",
            "fy17": "Visite",
            "fy18": "Saldo della promozione",
            "fy19": "Il Mio Equilibrio",
            "fy20": "La Mia Delegazione",
            "fy21": "La Mia Commissione",
            "fy22": "Negozio Invitato",
            "fy23": "Codice Invito",
            "fy24": "Invita Connessione",
            "fy25": "Codice Invito",
            "fy26": "Numero di personale di primo livello",
            "fy27": "Numero di personale secondario",
            "fy28": "Numero di personale di terzo livello",
            "fy29": "Il Mio Invito",
            "fy30": "I miei utenti invitati",
            "fy31": "Record degli inviti/benefici",
            "fy32": "Saldo",
            "fy33": "Saldo della promozione",
            "fy34": "Inserisci",
            "fy35": "Massimo Rimborsabile",
            "fy36": "Invia",
            "fy37": "Ricarica il mio equilibrio",
            "fy38": "Ingresso Massimo",
            "fy39": "Inserisci l'importo del bonifico",
            "fy40": "Trasferimento riuscito",
            "fy41": "Importo Del Deposito",
            "fy42": "Inserisci l'importo del deposito",
            "fy43": "Promemoria Gentile",
            "fy44": "Si tratta di una piattaforma di e-commerce che consente di acquistare prodotti noti online, scoprire nuovi negozi e venditori, cercare le necessità quotidiane nei negozi, o sfogliare una vasta selezione di prodotti in categorie come salute e bellezza, elettronica, moda, casa e vita, baby e giocattoli, ecc.",
            "fy45": "Importo Di Ricarica",
            "fy46": "Indirizzo",
            "fy47": "Copia",
            "fy48": "Ricarica UnionPay, si prega di contattare il segretario del servizio clienti per gestire l'attività",
            "fy49": "Carica il voucher di pagamento",
            "fy50": "Invia",
            "fy51": "Inserisci L'importo",
            "fy52": "Attualmente non è disponibile alcun canale di pagamento",
            "fy53": "Fino A Caricare",
            "fy54": "Metodo di sospensione",
            "fy55": "Seleziona il metodo di prelievo",
            "fy56": "Tasso Di Servizio",
            "fy57": "Importo Del Prelievo",
            "fy58": "Inserisci L'importo",
            "fy59": "Tassa Di Servizio",
            "fy60": "Attività",
            "fy61": "Saldo Disponibile",
            "fy62": "Rifletti Indirizzo",
            "fy63": "Ritiro",
            "fy64": "Password di pagamento",
            "fy65": "Inserisci la password di pagamento",
            "fy66": "Per Pagare",
            "fy67": "Inserisci L'importo",
            "fy68": "Aggiungere il metodo di prelievo",
            "fy69": "Dettagli della fattura",
            "fy70": "Tempo",
            "fy71": "Numero D'ordine",
            "fy72": "Denaro",
            "fy73": "Osservazioni",
            "fy74": "Vincolante",
            "fy75": "Seleziona un protocollo",
            "fy76": "Indirizzo del portafoglio",
            "fy77": "Inserisci",
            "fy78": "Conservare",
            "fy79": "Aggiunta con successo",
            "fy80": "Modificare",
            "fy81": "Aggiungi",
            "fy82": "Indirizzo",
            "fy83": "Seleziona la rete principale",
            "fy84": "Seleziona un protocollo",
            "fy85": "Indirizzo del portafoglio",
            "fy86": "Inserisci",
            "fy87": "Conservare",
            "fy88": "Elimina",
            "fy89": "Modificato con successo",
            "fy90": "Aggiunta con successo",
            "fy91": "Eliminato con successo",
            "fy92": "Aggiorna le informazioni dell'archivio",
            "fy93": "Inserisci il nome del negozio",
            "fy94": "Conservare",
            "fy95": "Fino A Caricare",
            "fy96": "Inserisci il nome del negozio"
        },
        size: {
            fy1: 'dimensione'
        }
    }
}
