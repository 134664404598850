import { defineStore } from 'pinia'
import sysStore from './system.js'
import { calc } from "@/utils/computed.js";

export default defineStore('user', {
    state: () => {
        return {
            userInfo: {},
            shopInfo: {},
            logo: '',
            token: '',
            isLogin: false,
            cartNum: 0,
            msgCount: 0,
            collectNum: 0,
            wallet: [],
            activeWallet: {},
            activeAddress: {},
            PayChannel: [],
            walletAddress: '',
            payPasswordSwitch: '', //支付密码
            cartInfo: {
                shopList: [],
                money: 0,
                number: 0
            },
            shopLevelMap: {},//商铺等级,
            editAddress: false,
            formUserName: '',
            msgList: [],
            showChatList: true,
            sysMessageCount: 0

        }
    },
    actions: {
        getLevel(level) {
            const sys = sysStore()
            if (!level) {
                return this.shopLevelMap[sys.lang.key ? sys.lang.key : 'en']['default']??''
            }
            return this.shopLevelMap[sys.lang.key][level] ? this.shopLevelMap[sys.lang.key][level] : this.shopLevelMap[sys.lang.key]['default']
        },
        getShopLevel() {
            return new Promise((resolve, reject) => {
                xb.$api.getShopLevelMap().then(res => {
                    this.shopLevelMap = res.data
                    resolve(res.data)
                })
            })

        },
        getUserSysMessage() {
            xb.$api.orderInformCount().then(res => {
                this.sysMessageCount = res.data
            })
        },
        async getUserInfo() {
            try {
                let result = await xb.$api.getUserInfo()
                this.userInfo = result
                if (import.meta.env.MODE === 'seller') {
                    this.shopInfo = await xb.$api.querySingleShop(result)

                    await this.getShopLevel()
                }
                await this.getShopLevel()
                return result
            } catch (e) {
                return Promise.reject(e)
            }
        },
        getMsgCount() {
            return new Promise((resolve, reject) => {
                xb.$api.getPcChat({ page: 0, size: 100 }).then(res => {
                    this.msgCount = res.chatList.content.reduce((a, b) => {
                        return calc('+', a, b.notReadChat)
                    }, 0)
                    this.msgList = res.chatList.content
                    resolve(res)
                })
            })

        },
        getCollectNum() {
            return new Promise((resolve, reject) => {
                xb.$api.collectCount().then(res => {
                    this.collectNum = res.data
                    resolve(res)
                })
            })
        },
        getCartData() {
            let _this = this
            return new Promise((resolve, reject) => {
                xb.$api.getCartList({ page: 1, size: 100, userId: _this.userInfo.userId }).then(res => {
                    _this.cartNum = res.data.content.reduce((a, i) => {
                        return a + i.quantity
                    },0)
                  
                 
                    resolve(res)
                })
            })
        },
        scheduledTasksForSeller(imm) {
            let _this = this
            let fn = () => {
                // _this.getCartData()
                _this.getMsgCount()
                _this.getUserSysMessage()
                // _this.getCollectNum()
            }
            if (imm) {
                fn()
            }
            window.setInterval(() => {
                requestAnimationFrame(() => {
                    fn()
                })
            }, 5000)
        },
        scheduledTasksForBuyer(imm) {
            let _this = this
            let fn = () => {
                _this.getCartData()
                _this.getMsgCount()
                _this.getCollectNum()
            }
            if (imm) {
                fn()
            }
            window.setInterval(() => {
                requestAnimationFrame(() => {
                    fn()
                })
            }, 5000)
        },
        logOut() {

            xb.$logOut().then(res => {
                sessionStorage.clear()
                // localStorage.clear()
                for (let key in this.$state) {
                    console.log(key, '仓库数据')
                    if (typeof this[key] === 'string') {
                        this[key] = ''
                    } else if (Array.isArray(this[key])) {
                        this[key] = []
                    } else if (this[key] === true) {
                        this[key] = false
                    } else if (typeof this[key] === 'number') {
                        this[key] = 0
                    } else {
                        this[key] = {}
                    }
                }
                xb.$emit('logOut')
                xb.$loading.show()
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            })
        },
        async getShopInfo() {

        }
    },
    // 使用该插件，开启数据缓存
    persist: {
        //这里存储默认使用的是session
        enabled: true,
        strategies: [
            {
                key: 'user',
                //更改默认存储，我更改为localStorage
                storage: sessionStorage,
                // 可以选择哪些进入local存储，这样就不用全部都进去存储了
                // 默认是全部进去存储
            }
        ]
    }
})
