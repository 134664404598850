import pwdComponents from '@/components/logOut/index.vue'
import { createApp } from 'vue'
// import i18nStore from "@/stores/modules/i18n";
// import {toast} from "@/utils/toast";
const pwdInput = function (option){
    return new Promise((resolve, reject) => {
        const confirmInstance = createApp(pwdComponents, {
            icon: option?.icon||'' ,
            iconWith: option?.iconWith||20,
            iconHeight: option?.iconHeight||20,
            show:true,
            confirm: (data) => {
                unmount()
                resolve(data)
            },
            close: () => {
                unmount()
                reject('close')
            }
        })
        // 卸载组件

        // 创建一个挂载容器
        const parentNode = document.createElement('div')
        parentNode.id = 'nft-pwd'
        document.body.appendChild(parentNode)
        const unmount = () => {
            confirmInstance.unmount()
            let node  = document.getElementById('nft-pwd')
            if(node){
                Array.from(node).forEach(item=>{
                    if(item){
                        document.body.removeChild(item)
                    }
                })
            }
            try{
                document.body.removeChild(parentNode)
            }catch (e) {

            }

        }
        // 挂载组件
        confirmInstance.mount(parentNode)
    })
}
export default pwdInput
