<script setup>
import { ref, watch } from 'vue'
import { ElDialog } from 'element-plus'
const dialogVisible = ref(true)
import xbButton from '@/components/xbButton/index.vue'
const emit = defineEmits(['close', 'confirm'])
const password = ref('')
const x = xb
const props = defineProps({
  confirm: {
    type: Function
  },
  close: {
    type: Function
  },
  show: {
    type: Boolean,
    default: false
  },
})
watch(props, (val) => {
  if (val.show) {
    setTimeout(() => {
      dialogVisible.value = true
    }, 100)
  }

}, { immediate: true })
const confirm = () => {
  dialogVisible.value = false
  setTimeout(() => {
    props?.confirm(password.value)
    emit('confirm', password.value)
  }, 100)

}
const close = () => {
  setTimeout(() => {
    props?.close(password.value)
    emit('close', password.value)
  }, 500)
}
</script>

<template>
  <ElDialog @close="close" :close-on-click-modal="false" style="border-radius: 15px" width="580px" v-model="dialogVisible"
   >
    <div class="content">
      <div class="input">
        <span>{{ x.$t('components.fy65') }} </span>
      </div>
      <xbButton @click="confirm" class="dy" style=" margin: auto;margin-top: 44px;border-radius: 5px;width: 440px;">{{
          x.$t('components.fy30')
        }}</xbButton>
    </div>

  </ElDialog>
</template>

<style scoped lang="scss">
:deep(.el-dialog__title) {
  font-weight: bold;
}

.input {
  width: 440px;
  height: 54px;
  flex-shrink: 0;
  //background-color:#f4f4f4;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;

  >input {
    flex: 1;
    border: none;
    outline: none;
    background-color: #f4f4f4;
    text-align: center;
  }

  span {
    text-align: center;
    color: #000C22;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 100% */
    text-transform: none;
  }
}</style>
