export default {
    receiving:'Время получения',
    shipments:'время обработки',
    color:'Цвет',
    size:'размер',
    "out":"При следующем обслуживании, пожалуйста, свяжитесь с клиентом.",
    "rem":"Запомните пароль учетной записи",
    confirm:'Подтвердить получение',
    "add4":{"t":"Данные Магазина","dj":"Класс Магазина","bl":"Коэффициент комиссионных","je":"Сумма Пополнения","jl":"Награда за пополнение","ll":"Поддержка ежедневного трафика"},
    "add2":{"fy1":"Идентификатор спереди","fy2":"Обратная Сторона Id","fy3":"Ручной Идентификатор"},
    "add3":{"fy1":"Общая стоимость товаров","fy2":"Индивидуальная прибыль","fy3":"Общая Прибыль"},
    "newAdd":{"fy1":"Кредиты","fy2":"Заполнение","fy3":"Количество товаров может быть","fy4":"Я Знаю.","fy5":"Описание Пакета"},
    dongjies:'Этот счет был заморожен, пожалуйста, свяжитесь со службой поддержки клиентов',
    donejie:'Средства были заморожены, пожалуйста, свяжитесь со службой поддержки клиентов',
    rate:'Процент прибыли',
    "mg": {
        "fy1": "Процентное Сокровище",
        "fy2": "Купоны",
        "fy3": "Мобильный Заряд",
        "fy4": "Код Приглашения",
        "fy5": "Под Опекой",
        "fy6": "Боже.",
        "fy7": "Ежедневная норма прибыли",
        "fy8": "Минимальная Покупка",
        "fy9": "Немедленная Заявка",
        "fy10": "Имеющийся Остаток",
        "fy11": "Пожалуйста, введите сумму покупки.",
        "fy12": "Все.",
        "fy13": "Продолжительность цикла",
        "fy14": "Ежедневная норма прибыли",
        "fy15": "Время Раздачи",
        "fy16": "Ежедневно",
        "fy17": "Средства, находящиеся под опекой",
        "fy18": "Срок Возврата",
        "fy19": "Минимальная Покупка",
        "fy20": "Ожидаемые поступления",
        "fy21": "Немедленная Заявка",
        "fy22": "Минимальная Покупка",
        "fy23": "Недостаточный остаток, попробуйте после пополнения.",
        "fy24": "Запись Заказа",
        "fy25": "Все Заказы",
        "fy26": "В Настоящее Время",
        "fy27": "Завершено",
        "fy28": "Остаток Времени",
        "fy29": "В Настоящее Время",
        "fy30": "Завершено",
        "fy31": "Номер Заказа",
        "fy32": "Сумма Заявки",
        "fy33": "Ежедневная Прибыль",
        "fy34": "Продолжительность цикла",
        "fy35": "Ожидаемые поступления",
        "fy36": "Выгодно",
        "fy37": "Дата Покупки",
        "fy38": "Выкуп.",
        "fy39": "Подтверждение выкупа.",
        "fy40": "Отмена Выкупа.",
        "fy41": "Подтверждение возврата средств, находящихся под опекой",
        "fy42": "Примечание: как только средства опеки будут выкуплены, проценты будут пустыми",
        "fy43": "Мобильный Заряд",
        "fy44": "Регистрация пополнения",
        "fy45": "Пожалуйста, введите свой телефон.",
        "fy46": "Сумма Пополнения",
        "fy47": "Не Выбрано",
        "fy48": "Обратите внимание, что после успешного пополнения, пожалуйста, следите за оплатой звонков.",
        "fy49": "Немедленное пополнение",
        "fy50": "Пожалуйста, введите свой телефон.",
        "fy51": "Перейти к комментарию",
        "fy52": "Комментарии",
        "fy53": "Написать Комментарий",
        "fy54": "Оценка Товаров",
        "fy55": "Написать Комментарий",
        "fy56": "Оставьте свой ценный комментарий.",
        "fy57": "Загар.",
        "fy58": "Представлено",
        "fy59": "Очень Плохо.",
        "fy60": "Плохо.",
        "fy61": "Общие Вопросы",
        "fy62": "Хорошо.",
        "fy63": "Очень Хорошо.",
        "fy64": "Регистрация пополнения",
        "fy65": "Успешное Пополнение",
        "fy66": "Код Приглашения",
        "fy67": "Введите код приглашения.",
        "fy68": "Общая Прибыль",
        "fy69": "Сегодня Прибыль",
        "fy70": "Не Завершено",
        "fy71": "В настоящее время купоны достигли верхнего предела покупки.",
        "fy72": "Не достигнут порог купона",
        "fy73": "Пользовательское пополнение",
        "fy74": "Текущий Платеж",
        "fy75": "Введите Количество",
        "fy76": "Быстрый Платеж",
        "fy77": "Немедленное пополнение",
        "fy78": "Пожалуйста, выберите Ваш платежный кошелек",
        "fy79": "В Настоящее Время",
        "fy80": "Цепь Волнового Поля",
        "fy81": "Цепочка Эфириума",
        "fy82": "Валютная Цепь",
        "fy83": "Евро - итальянская цепь",
        "fy84": "Европа И Италия",
        "fy85": "Цепь",
        "fy86": "Канал",
        "fy87": "Предпочтительно"
    },
    "newadd": { "fy1": "Имя владельца карточки", "fy2": "Введите имя владельца карты.", "fy3": "Номер банковской карты", "fy4": "Введите номер банковской карты.", "fy5": "Название Банка", "fy6": "Введите название банка.", "fy7": "Банковский Код", "fy8": "Введите Код Банка.", "fy9": "Электронные коммерческие поставки", "fy10": "Упрощите процесс доставки и предложите своим клиентам быстрые и удобные варианты доставки. Наша платформа обеспечивает безопасную и своевременную доставку ваших товаров каждый раз.", "fy11": "Отслеживание в реальном времени", "fy12": "Сообщите своим клиентам о каждом шаге отслеживания обновлений в режиме реального времени. Наша платформа предоставляет вам опыт работы с инструментами, необходимыми для доставки.", "fy13": "Масштабируемость", "fy14": "По мере роста вашего бизнеса наша платформа будет расти. Наша сеть доставки предназначена для вашего бизнеса, чтобы убедиться, что вы можете справиться с любым увеличением поставок." },
    "components": {
        "fy1": "Товар",
        "fy2": "Выплаты",
        "fy3": "Забыть Пароль",
        "fy4": "Немедленный Расчет",
        "fy5": "Всего",
        "fy6": "Товары",
        "fy7": "Выбирайте нужный товар.",
        "fy8": "Пожалуйста, добавьте адрес получения.",
        "fy9": "Сначала установите пароль оплаты.",
        "fy10": "Введите Пароль.",
        "fy11": "Выплатить",
        "fy12": "Введите пароль оплаты.",
        "fy13": "Обновить Пароль",
        "fy14": "Настройка Пароля",
        "fy15": "Старый Пароль",
        "fy16": "Пароль",
        "fy17": "Введите",
        "fy18": "Новый Пароль",
        "fy19": "Подтвердить Пароль",
        "fy20": "Введите новый пароль.",
        "fy21": "Подтвердите Пароль.",
        "fy22": "Сохранить",
        "fy23": "Введите шестизначный код.",
        "fy24": "Введите правильный 6 - значный пароль.",
        "fy25": "Пароль Не Совпадает.",
        "fy26": "Сообщение",
        "fy27": "Отправить",
        "fy28": "Ошибка загрузки изображения",
        "fy29": "Введите Атрибут",
        "fy30": "Подтверждение",
        "fy31": "Загрузить Больше",
        "fy32": "Покупатель",
        "fy33": "Продавец",
        "fy34": "Почтовый Ящик",
        "fy35": "Телефон",
        "fy36": "Пароль",
        "fy37": "Забыть Пароль",
        "fy38": "Регистрация",
        "fy39": "Регистрация",
        "fy40": "Регистрация по телефону",
        "fy41": "Использовать почтовый ящик для входа в систему",
        "fy42": "Помощь",
        "fy43": "Введите Телефон.",
        "fy44": "Введите адрес почтового ящика",
        "fy45": "Проверка почтового ящика",
        "fy46": "Проверка мобильного телефона",
        "fy47": "Введите код проверки.",
        "fy48": "Загрузить Больше",
        "fy49": "Загрузить Больше",
        "fy50": "Выберите Свой Пол",
        "fy51": "Мальчики",
        "fy52": "Девочки",
        "fy53": "Следующий Шаг",
        "fy54": "Выберите свой возраст",
        "fy55": "Выберите свой интерес",
        "fy56": "Как минимум 4 интереса.",
        "fy57": "Начать Поиск",
        "fy58": "Выберите Возраст",
        "fy59": "Отправить код проверки",
        "fy60": "Ошибка Кода Проверки",
        "fy61": "Годы",
        "fy62": "Попробуй через секунду.",
        "fy63": "Отправить код проверки успешно",
        "fy64": "Удалось сбросить, пожалуйста, войдите",
        "fy65": "Подтвердить запуск текущего аккаунта?",
        "fy66": "Выход Из Системы",
        "fy67": "Спонсорская поддержка",
        "fy68": "Месячные Продажи",
        "fy69": "Нет Данных",
        "fy70": "Немедленная Оплата",
        "fy71": "Бумажник",
        "fy72": "Подлежит Оплате",
        "fy73": "Подтверждение платежа",
        "fy74": "ID, водительское удостоверение, паспорт",
        "fy75": "Пожалуйста, загрузите документы.",
        "fy76": "Заявка успешна, ждите рассмотрения",
        "fy77": "Выберите"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Показать Деталей",
            "fy2": "Время",
            "fy3": "Тип",
            "fy4": "Сумма",
            "fy5": "Статус",
            "fy6": "Подлежит рассмотрению",
            "fy7": "Принято",
            "fy8": "Отказ"
        },
        "withdraw": {
            "fy1": "Способ Отражения",
            "fy2": "Нет",
            "fy3": "Ставка Оплаты Услуг",
            "fy4": "Выделенная Сумма",
            "fy5": "Введите Сумму",
            "fy6": "Расходы на обслуживание",
            "fy7": "Деятельность",
            "fy8": "Показать Адреса",
            "fy9": "Показать",
            "fy10": "Пароль Оплаты",
            "fy11": "Введите пароль оплаты.",
            "fy12": "Определение",
            "fy13": "Сначала установите пароль оплаты.",
            "fy14": "Введите Сумму",
            "fy15": "Введите Пароль.",
            "fy16": "Пожалуйста, добавьте форму",
            "fy17": "Имеющийся Остаток"
        },
        "shippingAddress": {
            "fy1": "Адрес Доставки"
        },
        "selected": {
            "fy1": "Избранные Магазины",
            "fy2": "Поиск",
            "fy3": "Внимание",
            "fy4": "Количество Посещений",
            "fy5": "Все."
        },
        "orderInfo": {
            "fy1": "Подробности Заказа",
            "fy2": "Номер Заказа",
            "fy3": "Время Заказа",
            "fy4": "Количество Товаров",
            "fy5": "Стоимость единицы товара",
            "fy6": "Выплачено",
            "fy7": "Определение"
        },
        "layout": {
            "fy1": "О Нас",
            "fy2": "Вернуться Наверх",
            "fy3": "Помощь в обслуживании",
            "fy4": "Политика конфиденциальности",
            "fy5": "Открытие Магазина",
            "fy6": "Бесплатно",
            "fy7": "Немедленно откройте магазин.",
            "fy8": "Товары",
            "fy9": "Магазины",
            "fy10": "Поиск",
            "fy11": "Отменить",
            "fy12": "Регистрация",
            "fy13": "Больше.",
            "fy14": "Избранные Магазины",
            "fy15": "Введите минимум три символа для поиска.",
            "fy16": "История Поиска",
            "fy17": "Внимание",
            "fy18": "Количество Посещений",
            "fy19": "Смотреть Все"
        },
        "category": {
            "fy1": "Избранные рекомендации",
            "fy2": "Избранные Магазины",
            "fy3": "Избранные"
        },
        "goodsDetail": {
            "fy1": "Количество",
            "fy2": "Продажи",
            "fy3": "Добавить В Корзину",
            "fy4": "Быстрая Доставка",
            "fy5": "Бесплатная доставка / возврат",
            "fy6": "Описание Товаров",
            "fy7": "Оценка Товаров",
            "fy8": "Звезда",
            "fy9": "Оценки Отсутствуют",
            "fy10": "Товар временно нельзя купить."
        },
        "home": {
            "fy1": "Последние предложения",
            "fy2": "Хорошая рекомендация.",
            "fy3": "Купить Подарок 1",
            "fy4": "Купить Сразу",
            "fy5": "Ограниченные по времени преференции",
            "fy6": "Обратный Отсчет",
            "fy7": "Горячие Товары",
            "fy8": "Бесплатный Фрахт",
            "fy9": "Все Товары",
            "fy10": "Горячие Магазины",
            "fy11": "Посмотреть Горячие магазины"
        },
        "myHeart": {
            "fy1": "Внимание",
            "fy2": "Смотреть Все",
            "fy3": "Наличные остатки на кошельке"
        },
        "shopDetail": {
            "fy1": "Внимание",
            "fy2": "Количество Посещений",
            "fy3": "Интеграция",
            "fy4": "Время",
            "fy5": "Продажи",
            "fy6": "Цены",
            "fy7": "Смотреть Все"
        },
        "personal": {
            "fy1": "Выход Из Системы",
            "fy2": "Индивидуальный Центр",
            "fy3": "Базовая Информация",
            "fy4": "Мой Бумажник.",
            "fy5": "Вклады",
            "fy6": "Показать",
            "fy7": "Описание Счета",
            "fy8": "Журнал Вкладов",
            "fy9": "Журнал Вывода",
            "fy10": "Привязка Usdt",
            "fy11": "Центр Заказов",
            "fy12": "Мой Заказ.",
            "fy13": "Адрес Доставки",
            "fy14": "Функциональные услуги",
            "fy15": "Избранные Магазины",
            "fy16": "Заявки Поставщиков",
            "fy17": "Изменить Пароль",
            "fy18": "Изменить пароль оплаты",
            "fy19": "Настройка пароля оплаты",
            "fy20": "Связаться С Клиентом",
            "fy21": "О Нас",
            "fy22": "Помощь в обслуживании",
            "fy23": "Изменения",
            "fy24": "Добавить",
            "fy25": "Адрес",
            "fy26": "Имя",
            "fy27": "Введите",
            "fy28": "Контактная информация",
            "fy29": "Сохранить",
            "fy30": "Удалить",
            "fy31": "Изменение Успешно",
            "fy32": "Добавить Успех",
            "fy33": "Адрес по умолчанию не может быть удален",
            "fy34": "Удалить Успешно",
            "fy35": "Выберите главную сеть",
            "fy36": "Выберите Протокол",
            "fy37": "Адрес Кошелька",
            "fy38": "Выберите адрес кошелька USDT",
            "fy39": "Обычные Члены",
            "fy40": "Мой Остаток",
            "fy41": "Поиск",
            "fy42": "Моя Забота.",
            "fy43": "Внимание",
            "fy44": "Количество Посещений",
            "fy45": "Все.",
            "fy46": "Описание Счета",
            "fy47": "Время",
            "fy48": "Номер Заказа",
            "fy49": "Сумма",
            "fy50": "Примечания",
            "fy51": "Изменение пароля успешно, пожалуйста, войдите",
            "fy52": "Пароль не совпадает дважды.",
            "fy53": "Новый пароль не может быть пустым",
            "fy54": "Старый пароль не может быть пустым",
            "fy55": "Изменить Пароль",
            "fy56": "Мой Аккаунт.",
            "fy57": "Старый Пароль",
            "fy58": "Введите старый пароль.",
            "fy59": "Новый Пароль",
            "fy60": "Введите новый пароль.",
            "fy61": "Повторить новый пароль",
            "fy62": "Повторите Пароль.",
            "fy63": "Обновить Пароль",
            "fy64": "Настройка Пароля",
            "fy65": "Подтвердить Пароль",
            "fy66": "Подтвердите Пароль.",
            "fy67": "Введите 6 - битный цифровой код.",
            "fy68": "Введите правильный 6 - битный цифровой пароль.",
            "fy69": "Пароль",
            "fy70": "Сумма Вкладов",
            "fy71": "Введите сумму депозита.",
            "fy72": "Теплый Совет",
            "fy73": "Это платформа электронной коммерции, которая позволяет вам покупать товары, которые вы знаете онлайн, и находить новые магазины и продавцов, искать предметы первой необходимости в магазинах или просматривать большое количество избранных товаров в категориях здоровья и красоты, электроники, моды, дома и жизни, младенцев и игрушек.",
            "fy74": "Сумма Пополнения",
            "fy75": "Адрес Usdt",
            "fy76": "Копирование",
            "fy77": "Для пополнения UnionPay свяжитесь с младшим секретарем по обслуживанию клиентов для ведения бизнеса",
            "fy78": "Пожалуйста, загрузите платежные документы.",
            "fy79": "Представлено",
            "fy80": "Введите Сумму",
            "fy81": "Нет платежного канала",
            "fy82": "Слишком Большой Файл",
            "fy83": "Депозиты Подробнее",
            "fy84": "Время",
            "fy85": "Тип",
            "fy86": "Сумма",
            "fy87": "Статус",
            "fy88": "Подлежит рассмотрению",
            "fy89": "Принято",
            "fy90": "Отказ",
            "fy91": "Что Такое Шопинг?",
            "fy92": "Это платформа электронной коммерции, которая позволяет вам покупать бренды, которые вы уже знаете онлайн, и находить новые магазины и продавцов.",
            "fy93": "Поиск повседневных предметов первой необходимости в магазине или просмотр здоровой красоты, электроники, моды, дома и жизни младенцев и игрушек и других категорий, чтобы получить большое количество избранных товаров.",
            "fy94": "Вы также можете следить за бесконечными рекламными кампаниями на нашей платформе. Догнать ежедневный флеш - трейдинг, поиграть в игру, чтобы выиграть товар, или посмотреть Live конференции, чтобы насладиться эксклюзивными предложениями в прямом эфире.",
            "fy95": "Когда вы готовитесь к покупке, убедитесь, что платформа позволяет совершать покупки по безопасным и бесшовным каналам, вы можете просматривать рейтинги и отзывы товаров, использовать Guarantee для безрисковых платежей и легко отслеживать доставку товаров.",
            "fy96": "Если вы хотите узнать больше, ознакомьтесь с нашей последней статьей в центре помощи покупкам, чтобы начать, или вы можете закончить быстрый просмотр с помощью этого видео.",
            "fy97": "Мой Заказ.",
            "fy98": "Номер Заказа",
            "fy99": "Время Покупки",
            "fy100": "Выплачено",
            "fy101": "Подробности",
            "fy102": "Возврат Средств",
            "fy103": "Свяжитесь с продавцом",
            "fy104": "Все.",
            "fy105": "Покупатель Платит",
            "fy106": "Ожидание Отправки",
            "fy107": "Отправлено",
            "fy108": "В Процессе Доставки",
            "fy109": "Покупатель Получает",
            "fy110": "Возврат.",
            "fy111": "Товар Возвращен",
            "fy112": "Заявки Поставщиков",
            "fy113": "Мобильные Телефоны",
            "fy114": "Почтовый Ящик",
            "fy115": "Введите почтовый ящик",
            "fy116": "Введите Телефон.",
            "fy117": "Код Проверки",
            "fy118": "Введите код проверки.",
            "fy119": "Из Новой Отправки",
            "fy120": "Получение кода проверки",
            "fy121": "Пароль",
            "fy122": "Введите Пароль.",
            "fy123": "Рекомендуемый Код",
            "fy124": "Введите код рекомендации.",
            "fy125": "Категория поставщиков",
            "fy126": "Выберите тип продавца",
            "fy127": "Название Магазина",
            "fy128": "Пожалуйста, введите название магазина.",
            "fy129": "Магазин Logo",
            "fy130": "Пожалуйста, загрузите логотип магазина.",
            "fy131": "ID, водительское удостоверение, паспорт",
            "fy132": "Пожалуйста, загрузите ID, водительское удостоверение, паспорт",
            "fy133": "Представлено",
            "fy134": "Выберите Категорию",
            "fy135": "Не удалось проверить телефон",
            "fy136": "Ошибка проверки почтового ящика",
            "fy137": "Выберите тип продавца"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Главная Страница",
            "fy2": "Основные Инструменты",
            "fy3": "Онлайн обслуживание клиентов",
            "fy4": "Системные Сообщения",
            "fy5": "Индивидуальный Центр"
        },
        "home": {
            "fy1": "Оперативные Данные",
            "fy2": "Всего",
            "fy3": "Общая Прибыль",
            "fy4": "Общий Объем Продаж",
            "fy5": "Успешный Заказ",
            "fy6": "Общий Объем Операций",
            "fy7": "Общее количество посещений",
            "fy8": "В Тот Же День",
            "fy9": "На Этой Неделе",
            "fy10": "В Этом Месяце",
            "fy11": "Количество Заказов",
            "fy12": "Количество выполненных заказов",
            "fy13": "Количество Заказов",
            "fy14": "Количество выполненных заказов",
            "fy15": "Выход Из Системы",
            "fy16": "Внимание",
            "fy17": "Количество Посещений",
            "fy18": "Сальдо Счета",
            "fy19": "Услуги По Заказу",
            "fy20": "Все Заказы",
            "fy21": "Необработанные заказы",
            "fy22": "Заказы В Отгрузке",
            "fy23": "Возврат / возврат заказа",
            "fy24": "Подлежащие оценке заказы",
            "fy25": "Оперативные Данные",
            "fy26": "Оперативные Данные",
            "fy27": "Мой Заказ.",
            "fy28": "Номер Заказа",
            "fy29": "Время Покупки",
            "fy30": "Выплачено",
            "fy31": "Подробности",
            "fy32": "Подтверждение",
            "fy33": "Все.",
            "fy34": "Покупатель Платит",
            "fy35": "Ожидание Отправки",
            "fy36": "Отправлено",
            "fy37": "В Процессе Доставки",
            "fy38": "Покупатель Получает",
            "fy39": "Возврат.",
            "fy40": "Товар Возвращен",
            "fy41": "Покупатель Платит",
            "fy42": "Ожидание Отправки",
            "fy43": "Отправлено",
            "fy44": "В Процессе Доставки",
            "fy45": "Покупатель Получает",
            "fy46": "Возврат.",
            "fy47": "Товар Возвращен",
            "fy48": "Подробности Заказа",
            "fy49": "Номер Заказа",
            "fy50": "Копирование",
            "fy51": "Время Заказа",
            "fy52": "Количество Товаров",
            "fy53": "Стоимость единицы товара",
            "fy54": "Выплачено",
            "fy55": "Определение"
        },
        "tool": {
            "fy1": "Выход Из Системы",
            "fy2": "Внимание",
            "fy3": "Количество Посещений",
            "fy4": "Основные Инструменты",
            "fy5": "Оптовый Центр",
            "fy6": "Управление товарными запасами",
            "fy7": "Управление Заказами",
            "fy8": "Гарантия",
            "fy9": "Управление Оценкой",
            "fy10": "Повышение эффективности работы",
            "fy11": "Популярные",
            "fy12": "Онлайн обслуживание клиентов",
            "fy13": "Магазин Проверяется.",
            "fy14": "Популярные",
            "fy15": "Заказы",
            "fy16": "Направленный толкатель",
            "fy17": "Быстрый Толчок",
            "fy18": "Будут рекомендованы в горячем",
            "fy19": "Боже.",
            "fy20": "Новый Магазин",
            "fy21": "В Будущем",
            "fy22": "Рекомендуем Магазин",
            "fy23": "Потребности в ресурсах",
            "fy24": "Прочитано и распространено Соглашение об обслуживании",
            "fy25": "Определение Платежей",
            "fy26": "Недостаточный остаток, пожалуйста, восполните.",
            "fy27": "Рекомендуем магазин потенциальным пользователям.",
            "fy28": "Системный интеллект рекомендует",
            "fy29": "Настройка направленного push",
            "fy30": "Мой Твиттер.",
            "fy31": "Копирование",
            "fy32": "Настройка Push",
            "fy33": "Введите программу автоматического согласования",
            "fy34": "Пол (по Выбору)",
            "fy35": "Возраст (избранный)",
            "fy36": "Категории интересов (по отдельности)",
            "fy37": "Национальный Район",
            "fy38": "Определение",
            "fy39": "Без Ограничений",
            "fy40": "Мужчины",
            "fy41": "Женщины",
            "fy42": "Подробности Данных",
            "fy43": "Дополнительные посещения",
            "fy44": "Количество новых фанатов",
            "fy45": "Дополнительные сделки",
            "fy46": "Сумма Сделки",
            "fy47": "Дополнительные заказы",
            "fy48": "Доля",
            "fy49": "Сегодня.",
            "fy50": "Режим Сброса",
            "fy51": "Номер Заказа",
            "fy52": "Время Покупки",
            "fy53": "Цикл Покупки",
            "fy54": "Сумма Покупки",
            "fy55": "Тип Покупки",
            "fy56": "Направленный толкатель",
            "fy57": "Быстрый Толчок",
            "fy58": "Подробности Данных",
            "fy59": "Все Заказы",
            "fy60": "Сброс.",
            "fy61": "Конец Сброса",
            "fy62": "Оптовый Центр",
            "fy63": "Выбранные Товары",
            "fy64": "Товарный Код",
            "fy65": "Пожалуйста, введите код товара.",
            "fy66": "Поиск",
            "fy67": "Минимальная Цена",
            "fy68": "Максимальная Цена",
            "fy69": "Поиск Товаров",
            "fy70": "Список самых продаваемых товаров",
            "fy71": "До Продажи",
            "fy72": "Рейтинг Прибылей",
            "fy73": "До Получения Прибыли",
            "fy74": "Требования",
            "fy75": "До Спроса",
            "fy76": "Быстрая Посадка",
            "fy77": "Ручной Привод",
            "fy78": "Выбор Стратегии",
            "fy79": "Пожалуйста, выберите товар.",
            "fy80": "Успешно.",
            "fy81": "Выбор",
            "fy82": "Выберите тип товара (можно выбрать больше)",
            "fy83": "Пожалуйста, выберите тип товара",
            "fy84": "Введите Сумму",
            "fy85": "請輸入商品數量",
            "fy86": "Соответствие",
            "fy87": "Системы соответствуют рекомендациям",
            "fy88": "Совокупное совпадение",
            "fy89": "Товары",
            "fy90": "Пересогласование",
            "fy91": "Платить и использовать",
            "fy92": "Введите",
            "fy93": "Цифры",
            "fy94": "Пожалуйста, выберите тип товара",
            "fy95": "Пожалуйста, введите количество товаров.",
            "fy96": "Введите Сумму",
            "fy97": "Поиск Товаров",
            "fy98": "Товарный Код",
            "fy99": "Копирование",
            "fy100": "Закупочная Цена",
            "fy101": "Рекламная Цена",
            "fy102": "Следующий Товар",
            "fy103": "Гарантия",
            "fy104": "Представлено",
            "fy105": "Все Товары",
            "fy106": "Количество комментариев",
            "fy107": "Операция",
            "fy108": "Смотрите Подробнее",
            "fy109": "Всего Товаров",
            "fy110": "Комментарий"
        },
        "sys": {
            "fy1": "Системные Сообщения",
            "fy2": "Убрать",
            "fy3": "Подробности",
            "fy4": "Отправить Товар"
        },
        "myCenter": {
            "fy1": "Выход Из Системы",
            "fy2": "Индивидуальный Центр",
            "fy3": "Мое Поручение.",
            "fy4": "Мое Приглашение.",
            "fy5": "Мой бумажник.",
            "fy6": "Переход",
            "fy7": "Вклады",
            "fy8": "Показать",
            "fy9": "Описание Счета",
            "fy10": "Привязка",
            "fy11": "Функциональные услуги",
            "fy12": "Изменить Пароль",
            "fy13": "Изменить пароль оплаты",
            "fy14": "Настройка пароля оплаты",
            "fy15": "Информация о магазине",
            "fy16": "Внимание",
            "fy17": "Количество Посещений",
            "fy18": "Распространенный остаток",
            "fy19": "Мой Остаток",
            "fy20": "Мое Поручение.",
            "fy21": "Моя Прислуга.",
            "fy22": "Магазины Приглашены.",
            "fy23": "Код Приглашения",
            "fy24": "Приглашение к соединению",
            "fy25": "Код Приглашения",
            "fy26": "Уровень I",
            "fy27": "Число сотрудников уровня II",
            "fy28": "Уровень Iii",
            "fy29": "Мое Приглашение.",
            "fy30": "Мой приглашенный пользователь",
            "fy31": "Запись приглашений / поступлений",
            "fy32": "Остаток",
            "fy33": "Распространенный остаток",
            "fy34": "Введите",
            "fy35": "Максимально конвертируемый",
            "fy36": "Представлено",
            "fy37": "На Мой Остаток.",
            "fy38": "Максимально допустимый вход",
            "fy39": "Введите переводную сумму.",
            "fy40": "Перевод Удался",
            "fy41": "Сумма Вкладов",
            "fy42": "Введите сумму депозита.",
            "fy43": "Теплый Совет",
            "fy44": "Это платформа электронной коммерции, которая позволяет вам покупать товары, которые вы знаете онлайн, и находить новые магазины и продавцов, искать предметы первой необходимости в магазинах или просматривать большое количество избранных товаров в категориях здоровья и красоты, электроники, моды, дома и жизни, младенцев и игрушек.",
            "fy45": "Сумма Пополнения",
            "fy46": "Адрес",
            "fy47": "Копирование",
            "fy48": "Банк пополнения, пожалуйста, свяжитесь с секретарём обслуживания клиентов для бизнеса",
            "fy49": "Пожалуйста, загрузите платежные документы.",
            "fy50": "Представлено",
            "fy51": "Введите Сумму",
            "fy52": "Нет платежного канала",
            "fy53": "Максимум Загрузки",
            "fy54": "Способ Вывода",
            "fy55": "Пожалуйста, выберите способ",
            "fy56": "Ставка Оплаты Услуг",
            "fy57": "Выделенная Сумма",
            "fy58": "Введите Сумму",
            "fy59": "Расходы на обслуживание",
            "fy60": "Деятельность",
            "fy61": "Возможный Остаток",
            "fy62": "Показать Адреса",
            "fy63": "Показать",
            "fy64": "Пароль Оплаты",
            "fy65": "Введите пароль оплаты.",
            "fy66": "Выплатить",
            "fy67": "Введите Сумму",
            "fy68": "Пожалуйста, добавьте форму",
            "fy69": "Описание Счета",
            "fy70": "Время",
            "fy71": "Номер Заказа",
            "fy72": "Сумма",
            "fy73": "Примечания",
            "fy74": "Привязка",
            "fy75": "Выберите Протокол",
            "fy76": "Адрес Кошелька",
            "fy77": "Введите",
            "fy78": "Сохранить",
            "fy79": "Добавить Успех",
            "fy80": "Изменения",
            "fy81": "Добавить",
            "fy82": "Адрес",
            "fy83": "Выберите главную сеть",
            "fy84": "Выберите Протокол",
            "fy85": "Адрес Кошелька",
            "fy86": "Введите",
            "fy87": "Сохранить",
            "fy88": "Удалить",
            "fy89": "Изменение Успешно",
            "fy90": "Добавить Успех",
            "fy91": "Удалить Успешно",
            "fy92": "Обновить информацию о магазине",
            "fy93": "Введите название магазина.",
            "fy94": "Сохранить",
            "fy95": "Максимум Загрузки",
            "fy96": "Введите название магазина."
        },
        size: {
            fy1: 'Размер'
        }
    }
}
