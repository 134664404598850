import request from "./request";
// import {decrypt,encrypt} from '@/utils/Decryption.js'
class Request {
    url='';
    constructor(url='') {
        this.url = url
    }
    get(url,data,headers){
        return request({
            url:this.url+url,
            method:"GET",
            params:{...data},
            headers
        })
    }
    post(url,data,headers){

        return request({
            url:this.url+url,
            method:"POST",
            data,
            headers,
        })
    }
}
export default Request