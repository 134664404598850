<script setup>
const x = xb
import {ref,watch} from 'vue'
import { ElDialog,ElInput } from 'element-plus'
const dialogVisible = ref(true)
import xbButton from '@/components/xbButton/index.vue'
const emit = defineEmits(['close','confirm'])
const password = ref('')
const props = defineProps({
  confirm:{
    type:Function
  },
  close:{
    type:Function
  },
  show:{
    type:Boolean,
    default:false
  },
})

watch(props,(val)=>{
  if(val.show){
    setTimeout(()=>{
      if(!xb.$user.userInfo.transPassword){
          xb.$emit('setpwd')
        return
      }
      dialogVisible.value = true
    },100)
  }

},{immediate:true})
const confirm = () =>{
  if(password.value.length!==6){
    xb.message.error(xb.$t('components.fy23'))
    return
  }
  dialogVisible.value = false
  setTimeout(()=>{
    props?.confirm(password.value)
    emit('confirm',password.value)
  },100)

}
const close  =()=>{
  setTimeout(()=>{
    props?.close(password.value)
    emit('close',password.value)
  },500)
}
</script>

<template>
  <ElDialog @close="close" :close-on-click-modal="false" style="border-radius: 15px" width="580px" v-model="dialogVisible" :title="x.$t('components.fy10')">
    <div class="content">
      <div class="input">
        <el-input
            v-model="password"
            type="password"
            :placeholder="x.$t('components.fy10')"
            show-password
            maxlength="6"
            style="flex:1;z-index: 999999"
        />
<!--        <input v-model="password" type="password" maxlength="6" placeholder="请输入密码">-->
      </div>
      <xbButton @click="confirm" style=" margin: auto;margin-top: 44px;border-radius: 5px;width: 440px;">{{ x.$t('components.fy30') }}</xbButton>
    </div>

  </ElDialog>
</template>

<style scoped lang="scss">
:deep(.el-dialog__title){
  font-weight: bold;
}
.input{
  width: 440px;
  height: 54px;
  flex-shrink: 0;
  background-color:#f4f4f4;
  display:flex;
  margin: auto;
  >input{
    flex:1;
    border:none;
    outline: none;
    background-color:#f4f4f4;
    text-align: center;
  }
}
</style>
