export default {
    receiving:'收貨時間',
    shipments:'處理時間',
    rate:'利潤率',
    confirm:'确认收货',
    rem:'记住账号密码',
    out:'下架维护中，请联系客服',
    add4:{
        t:'店铺数据',
        dj:'店铺等级',
        bl:'佣金比例',
        je:'充值金额',
        jl:'充值奖励',
        ll:'每日流量扶持'
    },
    add3:{
        fy1:'商品总价',
        fy2:'单件利润',
        fy3:'总利润'
    },
    newAdd:{
        fy1:'信用分',
        fy2:'充值',
        fy3:'可上架商品数量为',
        fy4:'我已知晓',
        fy5:'套餐说明'
    },
    add2:{
        fy1:'ID照正面',
        fy2:'ID照反面',
        fy3:'手持ID照'
    },
    color:'颜色',
    size:'尺寸',
    dongjie:'资金已被凍結,請聯繫客服',
    dongjies:'此賬號已被凍結,請聯繫客服',
    mg: {
        fy1: '利息寶',
        fy2: '優惠券',
        fy3: '手機充值',
        fy4: '邀請碼',
        fy5: '托管中',
        fy6: '天',
        fy7: '日收益率',
        fy8: '最低購買',
        fy9: '立即申購',
        fy10: '可用餘額',
        fy11: '請輸入購買金額',
        fy12: '全部',
        fy13: '持續週期',
        fy14: '日收益率',
        fy15: '派息時間',
        fy16: '每日',
        fy17: '託管資金',
        fy18: '到期返還',
        fy19: '最低購買',
        fy20: '預計收益',
        fy21: '立即申購',
        fy22: '最低購買',
        fy23: '餘額不足請充值後再試',
        // 訂單記錄
        fy24: '訂單記錄',
        fy25: '全部訂單',
        fy26: '進行中',
        fy27: '已完成',
        fy28: '剩餘時間',
        fy29: '進行中',
        fy30: '已完成',
        fy31: '訂單號',
        fy32: '申購金額',
        fy33: '日利潤',
        fy34: '持續週期',
        fy35: '預計收益',
        fy36: '已獲利',
        fy37: '購買日期',
        fy38: '贖回',
        fy39: '確認贖回',
        fy40: '取消贖回',
        fy41: '是否確認贖回託管資金',
        fy42: '注意：一旦贖回託管資金,利息都將會清空',
        //     手機充值
        fy43: '手機充值',
        fy44: '充值記錄',
        fy45: '請輸入您的電話',
        fy46: '充值金額',
        fy47: '未選擇',
        fy48: '請注意,充值成功後請留意話費到賬',
        fy49: '立即充值',
        fy50: '請輸入您的電話',
        //     評論
        fy51: '去評論',
        fy52: '已評論',
        fy53: '寫評論',
        fy54: '商品評價',
        fy55: '寫評論',
        fy56: '留下你珍貴的評論吧',
        fy57: '曬好物',
        fy58: '提交',
        fy59: '非常差',
        fy60: '很差',
        fy61: '一般',
        fy62: '挺好的',
        fy63: '非常好',
        fy64: '充值記錄',
        fy65: '充值成功',
        fy66: '邀請碼',
        fy67: '請輸入邀請碼',
        fy68: '總盈利',
        fy69: '今日盈利',
        fy70: '未完成',
        fy71: '當前優惠券已達到購買上限',
        fy72: '未達到優惠券門檻',
        fy73: '用戶充值',
        fy74: '本次支付',
        fy75: '請輸入數量',
        fy76: '快捷支付',
        fy77: '立即充值',
        fy78: '請選擇您的付款錢包',
        fy79: '當前',
        fy80: '波場鏈',
        fy81: '以太坊鏈',
        fy82: '幣安鏈',
        fy83: '歐意鏈',
        fy84: '歐意',
        fy85: '鏈',
        fy86: '通道',
        fy87: '優選'

    },
    newadd: {
        fy1: '持卡人姓名',
        fy2: '請輸入持卡人姓名',
        fy3: '銀行卡號',
        fy4: '請輸入銀行卡號',
        fy5: '銀行名稱',
        fy6: '請輸入銀行名稱',
        fy7: '銀行代碼',
        fy8: '請輸入銀行代碼',
        fy9: '電子商務交付',
        fy10: '簡化您的交付流程並提供您的客戶快速便捷的交付選項。 我們的平臺確保您的物品每次都安全準時交付。',
        fy11: '實时跟踪',
        fy12: '讓您的客戶瞭解每一步實时跟踪更新。 我們的平臺為您提供提供一流交付所需的工具經驗。',
        fy13: "可擴展性",
        fy14: '隨著您的業務增長,我們的平臺也會隨之增長。 我們交付網絡旨在隨您的業務,確保您可以處理任何新增交貨量。'
    },
    components: {
        fy1: '件',
        fy2: '支付',
        fy3: '忘記密碼',
        fy4: '立即結算',
        fy5: '共計',
        fy6: '商品',
        fy7: '去挑選中意的商品吧',
        fy8: '請先添加收貨地址',
        fy9: '請先設置支付密碼',
        fy10: '請輸入密碼',
        fy11: '去支付',
        fy12: '請輸入支付密碼',
        fy13: '更新密碼',
        fy14: '設置密碼',
        fy15: '舊密碼',
        fy16: '密碼',
        fy17: '請輸入',
        fy18: '新密碼',
        fy19: '確認密碼',
        fy20: '請輸入新密碼',
        fy21: '請確認密碼',
        fy22: '保存',
        fy23: '請輸入6位數密碼',
        fy24: '請輸入正確的6位數密碼',
        fy25: '兩次輸入的密碼不一致',
        fy26: '消息',
        fy27: '發送',
        fy28: '圖片上傳失敗',
        fy29: '請輸入内容',
        fy30: '確認',
        fy31: '加載更多',
        fy32: '買家',
        fy33: '賣家',
        fy34: '郵箱',
        fy35: '電話',
        fy36: '密碼',
        fy37: '忘記密碼',
        fy38: '注冊',
        fy39: '登錄',
        fy40: '使用電話登錄',
        fy41: '使用郵箱登錄',
        fy42: '幫助',
        fy43: '請輸入電話',
        fy44: '請輸入郵箱地址',
        fy45: '郵箱驗證',
        fy46: '手機驗證',
        fy47: '請輸入驗證碼',
        fy48: '加載更多',
        fy49: '加載更多',
        fy50: '選擇你的性別',
        fy51: '男生',
        fy52: '女生',
        fy53: '下一步',
        fy54: '選擇你的年齡',
        fy55: '選擇你的興趣',
        fy56: '至少關注4個興趣',
        fy57: '開始探索',
        fy58: '向上滑動選擇年齡',
        fy59: '發送驗證碼',
        fy60: '驗證碼錯誤',
        fy61: '嵗',
        fy62: '秒后再試',
        fy63: '發送驗證碼成功',
        fy64: '重置成功,請登錄',
        fy65: '確認推出當前賬號？',
        fy66: '退出登錄',
        fy67: '贊助',
        fy68: '月銷量',
        fy69: '暫無數據',
        fy70: '立即支付',
        fy71: '錢包',
        fy72: '待支付',
        fy73: '確認支付',
        fy74: 'ID,駕駛證,護照',
        fy75: '請先上傳證件',
        fy76: '申請成功,請等待審核',
        fy77: '請選擇',


    },

    buyer: {
        withdrawList: {
            fy1: "提現詳情",
            fy2: '時間',
            fy3: '類型',
            fy4: '金額',
            fy5: '狀態',
            fy6: '待審核',
            fy7: '通過',
            fy8: '拒絕'
        },
        withdraw: {
            fy1: '提現方式',
            fy2: '無',
            fy3: '服務費率',
            fy4: '提現金額',
            fy5: '請輸入金額',
            fy6: '服務費',
            fy7: '活動',
            fy8: '提現地址',
            fy9: '提現',
            fy10: '支付密碼',
            fy11: '請輸入支付密碼',
            fy12: '確定',
            fy13: '請先設置支付密碼',
            fy14: '請輸入金額',
            fy15: '請輸入密碼',
            fy16: '請添加提現方式',
            fy17: '可用餘額',
        },
        shippingAddress: {
            fy1: '送貨地址',
        },
        selected: {
            fy1: '精選店鋪',
            fy2: '搜索',
            fy3: '關注',
            fy4: '訪問量',
            fy5: '全部'
        },
        orderInfo: {
            fy1: '訂單詳情',
            fy2: '訂單號',
            fy3: '下單時間',
            fy4: '商品數量',
            fy5: '商品單價',
            fy6: '實付',
            fy7: '確定'
        },
        layout: {
            "fy1": "關於我們",
            "fy2": "回到頂部",
            "fy3": "幫助服務",
            "fy4": "隱私政策",
            "fy5": "開店",
            "fy6": "免費",
            "fy7": "立即開店",
            "fy8": "商品",
            "fy9": "店鋪",
            "fy10": "搜索",
            "fy11": "取消",
            "fy12": "登錄",
            "fy13": "更多",
            "fy14": "精選店鋪",
            "fy15": "請至少輸入三個字符進行搜索",
            "fy16": "搜索歷史",
            "fy17": "關注",
            "fy18": "訪問量",
            "fy19": "查看所有",
        },
        category: {
            "fy1": "精選推薦",
            "fy2": "精選店鋪",
            "fy3": "精選",

        },
        goodsDetail: {
            "fy1": "數量",
            "fy2": "銷量",
            "fy3": "添加到購物車",
            "fy4": "急速發貨",
            "fy5": "免費發貨/退貨",
            "fy6": "商品描述",
            "fy7": "商品評價",
            "fy8": "星",
            "fy9": "暫無評價",
            "fy10": "該商品暫時無法購買",
        },
        home: {
            "fy1": "最新優惠",
            "fy2": "好物推薦",
            "fy3": "買一贈一",
            "fy4": "立即購買",
            "fy5": "限時特惠",
            "fy6": "倒計時",
            "fy7": "熱門商品",
            "fy8": "免運費",
            "fy9": "全部商品",
            "fy10": "熱門店鋪",
            "fy11": "查看熱門店鋪",

        },
        myHeart: {
            "fy1": "關注",
            "fy2": "查看所有",
            "fy3": "錢包可用餘額",
        },
        shopDetail: {
            "fy1": "關注",
            "fy2": "訪問量",
            "fy3": "綜合",
            "fy4": "時間",
            "fy5": "銷量",
            "fy6": "價格",
            "fy7": "查看所有",
        },
        personal: {
            "fy1": "退出登錄",
            "fy2": "個人中心",
            "fy3": "基礎信息",
            "fy4": "我的錢包",
            "fy5": "存款",
            "fy6": "提現",
            "fy7": "賬單明細",
            "fy8": "存款日志",
            "fy9": "提現日志",
            "fy10": "綁定USDT",
            "fy11": "訂單中心",
            "fy12": "我的訂單",
            "fy13": "送貨地址",
            "fy14": "功能服務",
            "fy15": "精選商鋪",
            "fy16": "申請供應商",
            "fy17": "修改密碼",
            "fy18": "修改支付密碼",
            "fy19": "設置支付密碼",
            "fy20": "聯係客服",
            "fy21": "關於我們",
            "fy22": "幫助服務",
            "fy23": "修改",
            "fy24": "添加",
            "fy25": "地址",
            "fy26": "姓名",
            "fy27": "請輸入",
            "fy28": "联系方式",
            "fy29": "保存",
            "fy30": "刪除",
            "fy31": "修改成功",
            "fy32": "新增成功",
            "fy33": "默認地址不能刪除",
            "fy34": "刪除成功",
            "fy35": "請選擇主網",
            "fy36": "請選擇協議",
            "fy37": "錢包地址",
            "fy38": "請選擇USDT錢包地址",
            "fy39": "普通會員",
            "fy40": "我的餘額",
            "fy41": "搜索",
            "fy42": "我的關注",
            "fy43": "關注",
            "fy44": "訪問量",
            "fy45": "全部",
            "fy46": "賬單明細",
            "fy47": "時間",
            "fy48": "訂單號",
            "fy49": "金額",
            "fy50": "備注",
            "fy51": "更改密碼成功,請登錄",
            "fy52": "兩次密碼不一致",
            "fy53": "新密碼不能爲空",
            "fy54": "舊密碼不能爲空",
            "fy55": "修改密碼",
            "fy56": "我的賬號",
            "fy57": "舊密碼",
            "fy58": "請輸入舊密碼",
            "fy59": "新密碼",
            "fy60": "請輸入新密碼",
            "fy61": "重複新密碼",
            "fy62": "請重複密碼",
            "fy63": "更新密碼",
            "fy64": "設置密碼",
            "fy65": "確認密碼",
            "fy66": "請確認密碼",
            "fy67": "請輸入6位數字密碼",
            "fy68": "請輸入正確的6位數字密碼",
            "fy69": "密碼",
            "fy70": "存款金額",
            "fy71": "請輸入存款金額",
            "fy72": "溫馨提示",
            "fy73": "是一個電子商務平臺,可讓您線上購買您已知的商品,同時發現新的商店和賣家,在商店尋找日常必需品,或瀏覽健康與美容、電子商品、時尚、家居與生活、嬰兒與玩具等類別的大量精選商品。",
            "fy74": "充值金額",
            "fy75": "USDT地址",
            "fy76": "複製",
            "fy77": "銀聯充值,請聯繫客服小秘書辦理業務",
            "fy78": "請上傳支付憑證",
            "fy79": "提交",
            "fy80": "請輸入金額",
            "fy81": "暫無支付通道",
            "fy82": "文件太大",
            "fy83": "存款詳情",
            "fy84": "時間",
            "fy85": "類型",
            "fy86": "金額",
            "fy87": "狀態",
            "fy88": "待審核",
            "fy89": "通過",
            "fy90": "拒絕",
            "fy91": "購物是什麼",
            "fy92": "是一個電子商務平臺,讓您可以線上購買您已經知道的品牌,同時發現新的商店和賣家。",
            "fy93": "在商店內尋找日常必須品,或瀏覽健康美容、電子商品、時尚、家居與生活嬰兒與玩具等類別得大量精選商品。",
            "fy94": "您還可以留意我們平臺上無窮無盡的促銷活動。 趕上每日快閃交易,玩遊戲贏取商品,或觀看Live會議,享受直播獨家優惠。",
            "fy95": "當您準備購買時,請確保平臺允許您以安全無縫的管道進行購買,您可以瀏覽商品評級和評語,使用Guarantee進行無風險付款,並輕鬆跟踪您的商品交付情况。",
            "fy96": "如果您想瞭解更多資訊,請瀏覽我們最終購物幫助中心文章以開始,或者您可以通過此視頻結束快速流覽。",
            "fy97": "我的訂單",
            "fy98": "訂單號",
            "fy99": "購買時間",
            "fy100": "實付",
            "fy101": "詳情",
            "fy102": "退款",
            "fy103": "聯係賣家",
            "fy104": "全部",
            "fy105": "買家付款",
            "fy106": "等待發貨",
            "fy107": "已發貨",
            "fy108": "配送中",
            "fy109": "買家收貨",
            "fy110": "退貨中",
            "fy111": "已退貨",
            "fy112": "申請供應商",
            "fy113": "手機",
            "fy114": "郵箱",
            "fy115": "請輸入郵箱",
            "fy116": "請輸入電話",
            "fy117": "驗證碼",
            "fy118": "請輸入驗證碼",
            "fy119": "從新發送",
            "fy120": "獲取驗證碼",
            "fy121": "密碼",
            "fy122": "請輸入密碼",
            "fy123": "推薦碼",
            "fy124": "請輸入推薦碼",
            "fy125": "供應商類別",
            "fy126": "請選擇商戶類型",
            "fy127": "店鋪名字",
            "fy128": "請輸入店鋪名字",
            "fy129": "店鋪lOGO",
            "fy130": "請上傳店鋪LOGO",
            "fy131": "ID、駕駛證、護照",
            "fy132": "請上傳ID、駕駛證、護照",
            "fy133": "提交",
            "fy134": "請選擇類別",
            "fy135": "手機驗證失敗",
            "fy136": "郵箱驗證失敗",
            "fy137": "請選擇商戶類型",



        },
    }
    ,

    seller: {
        layout: {
            "fy1": "首頁",
            "fy2": "基礎工具",
            "fy3": "在線客服",
            "fy4": "系統消息",
            "fy5": "個人中心",
        },
        home: {
            "fy1": "業務數據",
            "fy2": "總訂單數",
            "fy3": "總利潤",
            "fy4": "總銷售量",
            "fy5": "成功訂單",
            "fy6": "總交易額",
            "fy7": "總訪問量",
            "fy8": "當天",
            "fy9": "本週",
            "fy10": "本月",
            "fy11": "訂單數",
            "fy12": "成交訂單數",
            "fy13": "訂單數",
            "fy14": "成交訂單數",
            "fy15": "退出登錄",
            "fy16": "關注",
            "fy17": "訪問量",
            "fy18": "賬戶餘額",
            "fy19": "訂單服務",
            "fy20": "全部訂單",
            "fy21": "待處理訂單",
            "fy22": "發貨中訂單",
            "fy23": "退貨/退款訂單",
            "fy24": "待評價訂單",
            fy25: "業務數據",
            fy26: '業務數據',
            fy27: '我的訂單',
            fy28: '訂單號',
            fy29: '購買時間',
            fy30: '實付',
            fy31: '詳情',
            fy32: '確認',
            fy33: '全部',
            fy34: '買家付款',
            fy35: '等待發貨',
            fy36: '已發貨',
            fy37: '配送中',
            fy38: '買家收貨',
            fy39: '退貨中',
            fy40: '已退貨',
            fy41: '買家付款',
            fy42: '等待發貨',
            fy43: '已發貨',
            fy44: '配送中',
            fy45: '買家收貨',
            fy46: '退貨中',
            fy47: '已退貨',
            fy48: '訂單詳情',
            fy49: '訂單號',
            fy50: '複製',
            fy51: '下單時間',
            fy52: '商品數量',
            fy53: '商品單價',
            fy54: '實付',
            fy55: '確定'

        },
        tool: {
            "fy1": "退出登錄",
            "fy2": "關注",
            "fy3": "訪問量",
            "fy4": "基礎工具",
            "fy5": "批發中心",
            "fy6": "商品管理",
            "fy7": "訂單管理",
            "fy8": "保證金",
            "fy9": "評價管理",
            "fy10": "業務升級",
            "fy11": "上熱門",
            "fy12": "在線客服",
            "fy13": "店鋪審核中",
            "fy14": "上熱門",
            "fy15": "訂單",
            "fy16": "定向推送",
            "fy17": "快速推送",
            "fy18": "將在熱門推薦",
            "fy19": "天",
            "fy20": "新店專享",
            "fy21": "將在未來",
            "fy22": "推薦店鋪",
            "fy23": "所需費用",
            "fy24": "已閱讀並通用《服務協議》",
            "fy25": '確定支付',
            fy26: '餘額不足請先充值',
            fy27: '把店鋪推薦給潛在用戶',
            fy28: '系統智能推薦',
            fy29: '自定義定向推送',
            fy30: '我的定推碼',
            fy31: '複製',
            fy32: '自定義推送配置',
            fy33: '輸入定推碼自動匹配方案',
            fy34: '性別（單選）',
            fy35: '年齡（多選）',
            fy36: '興趣類別（單選）',
            fy37: '國家地區',
            fy38: '確定',
            fy39: '不限',
            fy40: '男',
            fy41: '女',
            fy42: '數據詳情',
            fy43: '新增訪問量',
            fy44: '新增粉絲數',
            fy45: '新增成交額',
            fy46: '成交金額',
            fy47: '新增下單量',
            fy48: '所占比例',
            fy49: '今天',
            fy50: '投放狀態',
            fy51: '訂單號',
            fy52: '購買時間',
            fy53: '購買周期',
            fy54: '購買金額',
            fy55: '購買類型',
            fy56: '定向推送',
            fy57: '快速推送',
            fy58: '數據詳情',
            fy59: '全部訂單',
            fy60: '投放中',
            fy61: '投放結束',
            fy62: '批發中心',
            fy63: '已選擇商品',
            fy64: '商品碼',
            fy65: '請輸入商品碼',
            fy66: '搜索',
            fy67: '最小價格',
            fy68: '最大價格',
            fy69: '搜索商品',
            fy70: '暢銷排行',
            fy71: '暢銷前',
            fy72: '利潤排行',
            fy73: '利潤前',
            fy74: '需求排行',
            fy75: '需求前',
            fy76: '快速上架',
            fy77: '手動上架',
            fy78: '策略選品',
            fy79: '請選擇商品',
            fy80: '上架成功',
            fy81: '選擇',
            fy82: '選擇商品類型（可多選）',
            fy83: '請選擇商品類型',
            fy84: '請輸入金額',
            fy85: '請輸入商品數量',
            fy86: '匹配',
            fy87: '系統匹配推薦',
            fy88: '共匹配',
            fy89: '件商品',
            fy90: '重新匹配',
            fy91: '支付並使用',
            fy92: '請輸入',
            fy93: '數字',
            fy94: '請選擇商品類型',
            fy95: '請輸入商品數量',
            fy96: '請輸入金額',
            fy97: '搜索商品',
            fy98: '商品碼',
            fy99: '複製',
            fy100: '購買價格',
            fy101: '促銷價格',
            fy102: '下架商品',
            fy103: '保證金',
            fy104: '提交',
            fy105: '所有商品',
            fy106: '評論數量',
            fy107: '操作',
            fy108: '查看詳情',
            fy109: '本商品共',
            fy110: '條評論'
        },
        sys: {
            "fy1": "系統消息",
            "fy2": "收起",
            "fy3": "詳情",
            "fy4": "去發貨",
        },
        myCenter: {
            "fy1": "退出登錄",
            "fy2": "個人中心",
            "fy3": "我的委托",
            "fy4": "我的邀請",
            "fy5": "我的錢包",
            "fy6": "劃轉",
            "fy7": "存款",
            "fy8": "提現",
            "fy9": "賬單明細",
            "fy10": "綁定",
            "fy11": "功能服務",
            "fy12": "修改密碼",
            "fy13": "修改支付密碼",
            "fy14": "設置支付密碼",
            "fy15": "店鋪信息",
            "fy16": "關注",
            "fy17": "訪問量",
            "fy18": "推廣餘額",
            "fy19": "我的餘額",
            "fy20": "我的委託",
            "fy21": "我的返傭",
            "fy22": "已邀請店鋪",
            "fy23": "邀請碼",
            "fy24": "邀請鏈接",
            fy25: '邀請碼',
            fy26: '一級人數',
            fy27: '二級人數',
            fy28: '三級人數',
            fy29: '我的邀請',
            fy30: '我的邀請用戶',
            fy31: '邀請/收益記錄',
            fy32: '餘額',
            fy33: '推廣餘額',
            fy34: '請輸入',
            fy35: '最多可兌換',
            fy36: '提交',
            fy37: '充值我的餘額',
            fy38: '最大可輸入',
            fy39: '請輸入劃轉金額',
            fy40: '劃轉成功',
            //------
            fy41: '存款金額',
            fy42: '請輸入存款金額',
            fy43: '溫馨提示',
            fy44: '是一个电子商务平台,可让您在线购买您已知的商品,同时发现新的商店和卖家,在商店寻找日常必需品,或浏览健康与美容、电子 商品、时尚、家居与生活、婴儿与玩具等类别的大量精选商品。',
            fy45: '充值金額',
            fy46: '地址',
            fy47: '複製',
            fy48: '銀聯充值,請聯繫客服小秘辦理業務',
            fy49: '請上傳支付憑證',
            fy50: '提交',
            fy51: '請輸入金額',
            fy52: '暫無支付通道',
            fy53: '最多上傳',
            //*-*-*--
            fy54: '提現方式',
            fy55: '請選擇提現方式',
            fy56: '服務費率',
            fy57: '提現金額',
            fy58: '請輸入金額',
            fy59: '服務費',
            fy60: '活動',
            fy61: '可以餘額',
            fy62: '提現地址',
            fy63: '提現',
            fy64: '支付密碼',
            fy65: '請輸入支付密碼',
            fy66: '去支付',
            fy67: '請輸入金額',
            fy68: '請添加提現方式',
            // ----
            fy69: '賬單明細',
            fy70: '時間',
            fy71: '訂單號',
            fy72: '金額',
            fy73: '備註',
            fy74: '綁定',
            fy75: '請選擇協議',
            fy76: '錢包地址',
            fy77: '請輸入',
            fy78: '保存',
            fy79: '新增成功',
            fy80: '修改',
            fy81: '添加',
            fy82: '地址',
            fy83: '請選擇主網',
            fy84: '請選擇協議',
            fy85: '錢包地址',
            fy86: '請輸入',
            fy87: '保存',
            fy88: '刪除',
            fy89: '修改成功',
            fy90: '新增成功',
            fy91: '刪除成功',
            fy92: '更新商鋪信息',
            fy93: '請輸入商鋪名',
            fy94: '保存',
            fy95: '最多上傳',
            fy96: '請輸入商鋪名'
        },
        size: {
            fy1: '尺寸'
        }
    }

}
