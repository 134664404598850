export default {
    receiving:'Temps de réception',
    shipments:'temps de traitement',
    "rem":"Mémoriser le numéro de compte mot de passe",
    confirm:'Confirmer la réception',
    rate:'Taux de profit',
    "add2":{"fy1":"Id Photo Avant","fy2":"Id Au Contraire","fy3":"Photo d'identité à main levée"},
    "add3":{"fy1":"Prix total des marchandises","fy2":"Profit d'une seule pièce","fy3":"Bénéfice Total"},
    "out":"Maintenance de l'étagère inférieure, veuillez contacter le service clientèle",
    "add4":{"t":"Données de la boutique","dj":"Niveau du magasin","bl":"Proportion des commissions","je":"Montant de la recharge","jl":"Récompenses de recharge","ll":"Soutien quotidien du trafic"},
    size:'taille',
    color:'Couleur',
    "newAdd":{"fy1":"Points De Crédit","fy2":"Recharge","fy3":"Le nombre d'articles pouvant être mis en rayon est","fy4":"Je Sais Déjà","fy5":"Description du package"},
    dongjies:'Ce compte a été gelé, veuillez contacter le service client',
    dongjie:'Les fonds ont été gelés, veuillez contacter le service client',
    "mg": {
        "fy1": "Trésor D'intérêts",
        "fy2": "Coupons",
        "fy3": "Recharge de téléphone portable",
        "fy4": "Code D'invitation",
        "fy5": "En Hébergement",
        "fy6": "Jours",
        "fy7": "Rendement Quotidien",
        "fy8": "Achat Minimum",
        "fy9": "Demande Immédiate",
        "fy10": "Solde Disponible",
        "fy11": "Veuillez entrer le montant de l'achat",
        "fy12": "Tous",
        "fy13": "Cycle Continu",
        "fy14": "Rendement Quotidien",
        "fy15": "Temps de distribution",
        "fy16": "Quotidien",
        "fy17": "Fonds Gérés",
        "fy18": "Retour Expiré",
        "fy19": "Achat Minimum",
        "fy20": "Bénéfices Attendus",
        "fy21": "Demande Immédiate",
        "fy22": "Achat Minimum",
        "fy23": "Solde insuffisant Veuillez réessayer après avoir rechargé",
        "fy24": "Enregistrement des commandes",
        "fy25": "Toutes Les Commandes",
        "fy26": "En Cours",
        "fy27": "Terminé",
        "fy28": "Temps Restant",
        "fy29": "En Cours",
        "fy30": "Terminé",
        "fy31": "Numéro De Commande",
        "fy32": "Montant Demandé",
        "fy33": "Bénéfices Quotidiens",
        "fy34": "Cycle Continu",
        "fy35": "Bénéfices Attendus",
        "fy36": "Déjà Rentable",
        "fy37": "Date D'achat",
        "fy38": "Rédemption",
        "fy39": "Confirmer Le Rachat",
        "fy40": "Annulation Du Rachat",
        "fy41": "Confirmer le rachat des fonds gérés",
        "fy42": "Remarque: les intérêts seront vidés une fois que les fonds en dépôt seront remboursés",
        "fy43": "Recharge de téléphone portable",
        "fy44": "Enregistrement de recharge",
        "fy45": "Veuillez entrer votre téléphone",
        "fy46": "Montant de la recharge",
        "fy47": "Non Sélectionné",
        "fy48": "Veuillez noter que lorsque la recharge est réussie, gardez un œil sur les frais de facturation",
        "fy49": "Recharge Immédiate",
        "fy50": "Veuillez entrer votre téléphone",
        "fy51": "Aller aux commentaires",
        "fy52": "Déjà Commenté",
        "fy53": "Écrire un commentaire",
        "fy54": "Évaluation des marchandises",
        "fy55": "Écrire un commentaire",
        "fy56": "Laissez vos précieux commentaires",
        "fy57": "Le bien - être solaire",
        "fy58": "Soumettre",
        "fy59": "Très Mauvais",
        "fy60": "Très Mauvais",
        "fy61": "Général",
        "fy62": "Très Bien.",
        "fy63": "Très Bon",
        "fy64": "Enregistrement de recharge",
        "fy65": "Recharge Réussie",
        "fy66": "Code D'invitation",
        "fy67": "Veuillez entrer le Code d'invitation",
        "fy68": "Bénéfice Total",
        "fy69": "Rentable Aujourd'hui",
        "fy70": "Pas Terminé",
        "fy71": "Le coupon actuel a atteint le plafond d'achat",
        "fy72": "Coupon non atteint menkan",
        "fy73": "Recharge Utilisateur",
        "fy74": "Ce Paiement",
        "fy75": "Veuillez entrer la quantité",
        "fy76": "Paiement Rapide",
        "fy77": "Recharge Immédiate",
        "fy78": "Veuillez sélectionner votre portefeuille de paiement",
        "fy79": "Actuellement",
        "fy80": "Chaîne de champ d'onde",
        "fy81": "Chaîne Ethereum",
        "fy82": "Chaîne De Devises",
        "fy83": "Chaîne Européenne",
        "fy84": "Européenne",
        "fy85": "Chaîne",
        "fy86": "Canaux",
        "fy87": "De Préférence"
    },
    "newadd": { "fy1": "Nom du titulaire de la carte", "fy2": "Veuillez entrer le nom du titulaire de la carte", "fy3": "Numéro de carte bancaire", "fy4": "Veuillez entrer le numéro de carte bancaire", "fy5": "Nom De La Banque", "fy6": "Veuillez entrer le nom de votre banque", "fy7": "Code De La Banque", "fy8": "Veuillez entrer le Code bancaire", "fy9": "Livraison e - commerce", "fy10": "Simplifiez votre processus de livraison et offrez à vos clients des options de livraison rapides et faciles. Notre plate - forme garantit que vos articles sont livrés en toute sécurité et à temps à chaque fois.", "fy11": "Suivi En Temps Réel", "fy12": "Tenez vos clients informés de chaque étape du suivi des mises à jour en temps réel. Notre plate - forme vous offre l'expérience des outils dont vous avez besoin pour fournir une livraison de première classe.", "fy13": "Extensibilité", "fy14": "Au fur et à mesure que votre entreprise grandit, notre plateforme grandit avec elle. Notre réseau de livraison est conçu pour accompagner votre entreprise, en veillant à ce que vous puissiez gérer toute augmentation du volume de livraison." },
    "components": {
        "fy1": "Pièces",
        "fy2": "Paiement",
        "fy3": "Mot De Passe Oublié",
        "fy4": "Règlement Immédiat",
        "fy5": "Total",
        "fy6": "Marchandises",
        "fy7": "Choisissez des produits chinois",
        "fy8": "Veuillez d'abord ajouter l'adresse de réception",
        "fy9": "Veuillez d'abord définir un mot de passe de paiement",
        "fy10": "Veuillez entrer votre mot de passe",
        "fy11": "Aller Payer",
        "fy12": "Veuillez entrer le mot de passe de paiement",
        "fy13": "Mettre à jour votre mot de passe",
        "fy14": "Définir un mot de passe",
        "fy15": "Ancien Mot De Passe",
        "fy16": "Mot De Passe",
        "fy17": "Veuillez Entrer",
        "fy18": "Nouveau Mot De Passe",
        "fy19": "Confirmer le mot de passe",
        "fy20": "Veuillez entrer un nouveau mot de passe",
        "fy21": "Veuillez confirmer votre mot de passe",
        "fy22": "Sauvegarder",
        "fy23": "Veuillez entrer un mot de passe à 6 chiffres",
        "fy24": "Veuillez entrer le mot de passe correct à 6 chiffres",
        "fy25": "Le mot de passe saisi deux fois est incohérent",
        "fy26": "Message",
        "fy27": "Envoyer",
        "fy28": "Le téléchargement de l'image a échoué",
        "fy29": "Veuillez entrer les propriétés",
        "fy30": "Confirmation",
        "fy31": "Charger Plus",
        "fy32": "Acheteurs",
        "fy33": "Vendeur",
        "fy34": "Boîte Aux Lettres",
        "fy35": "Téléphone",
        "fy36": "Mot De Passe",
        "fy37": "Mot De Passe Oublié",
        "fy38": "Enregistrement",
        "fy39": "Se Connecter",
        "fy40": "Se connecter avec le téléphone",
        "fy41": "Se connecter avec la boîte aux lettres",
        "fy42": "Aide",
        "fy43": "Veuillez entrer un téléphone",
        "fy44": "Veuillez entrer une adresse mail",
        "fy45": "Vérification de la boîte aux lettres",
        "fy46": "Vérification du téléphone portable",
        "fy47": "Veuillez entrer le Code de vérification",
        "fy48": "Charger Plus",
        "fy49": "Charger Plus",
        "fy50": "Choisissez votre sexe",
        "fy51": "Garçons",
        "fy52": "Filles",
        "fy53": "Prochaines Étapes",
        "fy54": "Choisissez Votre Âge",
        "fy55": "Choisissez vos intérêts",
        "fy56": "Concentrez - vous sur au moins 4 intérêts",
        "fy57": "Commencez À Explorer",
        "fy58": "Balayez vers le haut pour sélectionner l'âge",
        "fy59": "Envoyer le Code de vérification",
        "fy60": "Erreur de code de vérification",
        "fy61": "Âge",
        "fy62": "Réessayez dans secondes",
        "fy63": "Envoi du Code de vérification réussi",
        "fy64": "Réinitialisation réussie, veuillez vous connecter",
        "fy65": "Confirmer le lancement de votre compte actuel?",
        "fy66": "Se déconnecter du login",
        "fy67": "Sponsorisé",
        "fy68": "Ventes Mensuelles",
        "fy69": "Pas de données pour le moment",
        "fy70": "Payer Maintenant",
        "fy71": "Portefeuille",
        "fy72": "À Payer",
        "fy73": "Confirmer le paiement",
        "fy74": "ID, permis de conduire, passeport",
        "fy75": "Veuillez d'abord télécharger les documents",
        "fy76": "Application réussie, veuillez attendre un audit",
        "fy77": "Veuillez sélectionner"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Détails de l'Présente",
            "fy2": "Le Temps",
            "fy3": "Type",
            "fy4": "Montant",
            "fy5": "Statut",
            "fy6": "À Auditer",
            "fy7": "Par",
            "fy8": "Refusé"
        },
        "withdraw": {
            "fy1": "Mode D'Présente",
            "fy2": "Aucun",
            "fy3": "Tarifs Des Services",
            "fy4": "Montant À Retirer",
            "fy5": "Veuillez entrer le montant",
            "fy6": "Frais De Service",
            "fy7": "Activités",
            "fy8": "Adresse Incarnée",
            "fy9": "Présente",
            "fy10": "Mot de passe de paiement",
            "fy11": "Veuillez entrer le mot de passe de paiement",
            "fy12": "Déterminer",
            "fy13": "Veuillez d'abord définir un mot de passe de paiement",
            "fy14": "Veuillez entrer le montant",
            "fy15": "Veuillez entrer votre mot de passe",
            "fy16": "Veuillez ajouter une méthode de retrait",
            "fy17": "Solde Disponible"
        },
        "shippingAddress": {
            "fy1": "Adresse De Livraison"
        },
        "selected": {
            "fy1": "Boutiques sélectionnées",
            "fy2": "Rechercher",
            "fy3": "Attention",
            "fy4": "Volume De Visites",
            "fy5": "Tous"
        },
        "orderInfo": {
            "fy1": "Détails de la commande",
            "fy2": "Numéro De Commande",
            "fy3": "Temps de passer une commande",
            "fy4": "Quantité de marchandises",
            "fy5": "Prix unitaire des marchandises",
            "fy6": "Paiement Réel",
            "fy7": "Déterminer"
        },
        "layout": {
            "fy1": "À Propos De Nous",
            "fy2": "Retour Au Top",
            "fy3": "Services D'aide",
            "fy4": "Politique de confidentialité",
            "fy5": "Ouvrir Une Boutique",
            "fy6": "Gratuit",
            "fy7": "Ouvrir une boutique maintenant",
            "fy8": "Marchandises",
            "fy9": "Boutique",
            "fy10": "Rechercher",
            "fy11": "Annulation",
            "fy12": "Se Connecter",
            "fy13": "Plus",
            "fy14": "Boutiques sélectionnées",
            "fy15": "Veuillez entrer au moins trois caractères pour rechercher",
            "fy16": "Rechercher l'historique",
            "fy17": "Attention",
            "fy18": "Volume De Visites",
            "fy19": "Voir Tous"
        },
        "category": {
            "fy1": "Recommandations sélectionnées",
            "fy2": "Boutiques sélectionnées",
            "fy3": "Sélection"
        },
        "goodsDetail": {
            "fy1": "Quantité",
            "fy2": "Volume Des Ventes",
            "fy3": "Ajouter Au Panier",
            "fy4": "Expédition Rapide",
            "fy5": "Livraison / retour gratuit",
            "fy6": "Description des marchandises",
            "fy7": "Évaluation des marchandises",
            "fy8": "Les Étoiles",
            "fy9": "Pas encore d'évaluation",
            "fy10": "Cet article est temporairement indisponible à l'achat"
        },
        "home": {
            "fy1": "Dernières Offres",
            "fy2": "Bonnes choses à recommander",
            "fy3": "Achetez - en un et obtenez - en un",
            "fy4": "Acheter Maintenant",
            "fy5": "Offres spéciales à durée limitée",
            "fy6": "Compte À Rebours",
            "fy7": "Produits Populaires",
            "fy8": "Livraison Gratuite",
            "fy9": "Toutes les marchandises",
            "fy10": "Boutiques Populaires",
            "fy11": "Voir les boutiques populaires"
        },
        "myHeart": {
            "fy1": "Attention",
            "fy2": "Voir Tous",
            "fy3": "Solde disponible du portefeuille"
        },
        "shopDetail": {
            "fy1": "Attention",
            "fy2": "Volume De Visites",
            "fy3": "Synthèse",
            "fy4": "Le Temps",
            "fy5": "Volume Des Ventes",
            "fy6": "Prix",
            "fy7": "Voir Tous"
        },
        "personal": {
            "fy1": "Se déconnecter du login",
            "fy2": "Centre Personnel",
            "fy3": "Informations De Base",
            "fy4": "Mon Portefeuille",
            "fy5": "Dépôts",
            "fy6": "Présente",
            "fy7": "Détails de facturation",
            "fy8": "Journal Des Dépôts",
            "fy9": "Log De Retrait",
            "fy10": "Liaison Usdt",
            "fy11": "Centre De Commande",
            "fy12": "Ma Commande",
            "fy13": "Adresse De Livraison",
            "fy14": "Services fonctionnels",
            "fy15": "Boutique sélectionnée",
            "fy16": "Demander un fournisseur",
            "fy17": "Modifier le mot de passe",
            "fy18": "Modifier le mot de passe de paiement",
            "fy19": "Définir un mot de passe de paiement",
            "fy20": "Contacter le service clientèle",
            "fy21": "À Propos De Nous",
            "fy22": "Services D'aide",
            "fy23": "Modifier",
            "fy24": "Ajouter",
            "fy25": "Adresse",
            "fy26": "Nom Et Prénom",
            "fy27": "Veuillez Entrer",
            "fy28": "Formulaire de contact",
            "fy29": "Sauvegarder",
            "fy30": "Supprimer",
            "fy31": "Modification Réussie",
            "fy32": "Nouveau Succès",
            "fy33": "L'adresse par défaut ne peut pas être supprimée",
            "fy34": "Supprimer avec succès",
            "fy35": "Veuillez sélectionner le réseau principal",
            "fy36": "Veuillez sélectionner un accord",
            "fy37": "Adresse du portefeuille",
            "fy38": "Veuillez sélectionner une adresse de portefeuille usdt",
            "fy39": "Membres Ordinaires",
            "fy40": "Mon Solde",
            "fy41": "Rechercher",
            "fy42": "Mon Attention",
            "fy43": "Attention",
            "fy44": "Volume De Visites",
            "fy45": "Tous",
            "fy46": "Détails de facturation",
            "fy47": "Le Temps",
            "fy48": "Numéro De Commande",
            "fy49": "Montant",
            "fy50": "Remarques",
            "fy51": "Changement de mot de passe réussi, veuillez vous connecter",
            "fy52": "Deux fois le mot de passe est incohérent",
            "fy53": "Le nouveau mot de passe ne peut pas être vide",
            "fy54": "L'ancien mot de passe ne peut pas être vide",
            "fy55": "Modifier le mot de passe",
            "fy56": "Mon Numéro De Compte",
            "fy57": "Ancien Mot De Passe",
            "fy58": "Veuillez entrer votre ancien mot de passe",
            "fy59": "Nouveau Mot De Passe",
            "fy60": "Veuillez entrer un nouveau mot de passe",
            "fy61": "Répéter le nouveau mot de passe",
            "fy62": "Veuillez répéter le mot de passe",
            "fy63": "Mettre à jour votre mot de passe",
            "fy64": "Définir un mot de passe",
            "fy65": "Confirmer le mot de passe",
            "fy66": "Veuillez confirmer votre mot de passe",
            "fy67": "Veuillez entrer un mot de passe à 6 chiffres",
            "fy68": "Veuillez saisir le bon mot de passe à 6 chiffres",
            "fy69": "Mot De Passe",
            "fy70": "Montant Du Dépôt",
            "fy71": "Veuillez entrer le montant du dépôt",
            "fy72": "Conseils Chaleureux",
            "fy73": "Est une plate - forme de commerce électronique qui vous permet d'acheter en ligne ce que vous connaissez, tout en découvrant de nouveaux magasins et vendeurs, en recherchant des articles essentiels du quotidien dans les magasins ou en parcourant une vaste sélection d'articles dans les catégories Santé & beauté, articles électroniques, mode, Maison & vie, bébés & jouets et plus encore.",
            "fy74": "Montant de la recharge",
            "fy75": "Adresse Usdt",
            "fy76": "Copier",
            "fy77": "UnionPay recharge, s'il vous plaît contacter le service à la clientèle petite secrétaire pour les affaires",
            "fy78": "Veuillez télécharger le justificatif de paiement",
            "fy79": "Soumettre",
            "fy80": "Veuillez entrer le montant",
            "fy81": "Pas encore de canal de paiement",
            "fy82": "Le fichier est trop grand",
            "fy83": "Détails Du Dépôt",
            "fy84": "Le Temps",
            "fy85": "Type",
            "fy86": "Montant",
            "fy87": "Statut",
            "fy88": "À Auditer",
            "fy89": "Par",
            "fy90": "Refusé",
            "fy91": "Qu'est - ce que le shopping",
            "fy92": "Est une plate - forme de commerce électronique qui vous permet d'acheter en ligne des marques que vous connaissez déjà tout en découvrant de nouveaux magasins et vendeurs.",
            "fy93": "Recherchez les incontournables du quotidien en magasin ou parcourez les catégories Santé et beauté, produits électroniques, mode, maison et vie pour bébés et jouets avec une large sélection d'articles.",
            "fy94": "Vous pouvez également garder un œil sur les promotions sans fin sur notre plate - forme. Rattrapez - vous avec les offres Flash quotidiennes, jouez à des jeux pour gagner des marchandises ou regardez des réunions live avec des offres exclusives en direct.",
            "fy95": "Lorsque vous êtes prêt à acheter, assurez - vous que la plate - forme vous permet de faire vos achats dans un pipeline sécurisé et transparent où vous pouvez parcourir les évaluations et les évaluations des articles, effectuer des paiements sans risque avec Guarantee et suivre facilement la livraison de vos articles.",
            "fy96": "Si vous souhaitez en savoir plus, consultez notre article du Centre d'aide final shopping pour commencer, ou vous pouvez terminer le streaming rapide avec cette vidéo.",
            "fy97": "Ma Commande",
            "fy98": "Numéro De Commande",
            "fy99": "Temps D'achat",
            "fy100": "Paiement Réel",
            "fy101": "Détails",
            "fy102": "Remboursement",
            "fy103": "Contacter Le Vendeur",
            "fy104": "Tous",
            "fy105": "Paiement des acheteurs",
            "fy106": "En attente d'expédition",
            "fy107": "Expédié",
            "fy108": "Dans La Distribution",
            "fy109": "Réception par l'acheteur",
            "fy110": "Dans Les Retours",
            "fy111": "Marchandises retournées",
            "fy112": "Demander un fournisseur",
            "fy113": "Téléphone Portable",
            "fy114": "Boîte Aux Lettres",
            "fy115": "Veuillez entrer une boîte aux lettres",
            "fy116": "Veuillez entrer un téléphone",
            "fy117": "Code De Vérification",
            "fy118": "Veuillez entrer le Code de vérification",
            "fy119": "Envoyé Depuis New",
            "fy120": "Obtenir le Code de vérification",
            "fy121": "Mot De Passe",
            "fy122": "Veuillez entrer votre mot de passe",
            "fy123": "Code Recommandé",
            "fy124": "Veuillez entrer le Code de recommandation",
            "fy125": "Catégories de fournisseurs",
            "fy126": "Veuillez sélectionner un type de commerçant",
            "fy127": "Nom De La Boutique",
            "fy128": "Veuillez entrer le nom de la boutique",
            "fy129": "Boutique Logo",
            "fy130": "Veuillez télécharger le logo de la boutique",
            "fy131": "ID, permis de conduire, passeport",
            "fy132": "Veuillez télécharger ID, permis de conduire, passeport",
            "fy133": "Soumettre",
            "fy134": "Veuillez sélectionner une catégorie",
            "fy135": "La vérification du téléphone a échoué",
            "fy136": "La vérification de la boîte aux lettres a échoué",
            "fy137": "Veuillez sélectionner un type de commerçant"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Accueil",
            "fy2": "Outils De Base",
            "fy3": "Service à la clientèle en ligne",
            "fy4": "Messages Système",
            "fy5": "Centre Personnel"
        },
        "home": {
            "fy1": "Données opérationnelles",
            "fy2": "Nombre total de commandes",
            "fy3": "Bénéfice Total",
            "fy4": "Volume total des ventes",
            "fy5": "Ordre Réussi",
            "fy6": "Volume total des transactions",
            "fy7": "Total Des Visites",
            "fy8": "Le Jour",
            "fy9": "Cette Semaine",
            "fy10": "Ce Mois - Ci",
            "fy11": "Nombre De Commandes",
            "fy12": "Nombre d'ordres clôturés",
            "fy13": "Nombre De Commandes",
            "fy14": "Nombre d'ordres clôturés",
            "fy15": "Se déconnecter du login",
            "fy16": "Attention",
            "fy17": "Volume De Visites",
            "fy18": "Solde Du Compte",
            "fy19": "Service De Commande",
            "fy20": "Toutes Les Commandes",
            "fy21": "Commandes en attente de traitement",
            "fy22": "Commandes en expédition",
            "fy23": "Retour / remboursement des commandes",
            "fy24": "Ordre À Évaluer",
            "fy25": "Données opérationnelles",
            "fy26": "Données opérationnelles",
            "fy27": "Ma Commande",
            "fy28": "Numéro De Commande",
            "fy29": "Temps D'achat",
            "fy30": "Paiement Réel",
            "fy31": "Détails",
            "fy32": "Confirmation",
            "fy33": "Tous",
            "fy34": "Paiement des acheteurs",
            "fy35": "En attente d'expédition",
            "fy36": "Expédié",
            "fy37": "Dans La Distribution",
            "fy38": "Réception par l'acheteur",
            "fy39": "Dans Les Retours",
            "fy40": "Marchandises retournées",
            "fy41": "Paiement des acheteurs",
            "fy42": "En attente d'expédition",
            "fy43": "Expédié",
            "fy44": "Dans La Distribution",
            "fy45": "Réception par l'acheteur",
            "fy46": "Dans Les Retours",
            "fy47": "Marchandises retournées",
            "fy48": "Détails de la commande",
            "fy49": "Numéro De Commande",
            "fy50": "Copier",
            "fy51": "Temps de passer une commande",
            "fy52": "Quantité de marchandises",
            "fy53": "Prix unitaire des marchandises",
            "fy54": "Paiement Réel",
            "fy55": "Déterminer"
        },
        "tool": {
            "fy1": "Se déconnecter du login",
            "fy2": "Attention",
            "fy3": "Volume De Visites",
            "fy4": "Outils De Base",
            "fy5": "Centre de vente en gros",
            "fy6": "Gestion des marchandises",
            "fy7": "Gestion des commandes",
            "fy8": "Dépôt De Garantie",
            "fy9": "Gestion de l'évaluation",
            "fy10": "Mise à niveau des affaires",
            "fy11": "Top Populaire",
            "fy12": "Service à la clientèle en ligne",
            "fy13": "Audit De La Boutique",
            "fy14": "Top Populaire",
            "fy15": "Commandes",
            "fy16": "Push Directionnel",
            "fy17": "Push Rapide",
            "fy18": "Sera recommandé dans hot",
            "fy19": "Jours",
            "fy20": "Exclusivité nouvelle boutique",
            "fy21": "Sera Dans Le Futur",
            "fy22": "Boutique Recommandée",
            "fy23": "Dépenses Nécessaires",
            "fy24": "Lu et générique contrat de service",
            "fy25": "Déterminer le paiement",
            "fy26": "Solde insuffisant Veuillez recharger en premier",
            "fy27": "Recommander une boutique à des utilisateurs potentiels",
            "fy28": "Système intelligent recommandé",
            "fy29": "Push directionnel personnalisé",
            "fy30": "Mon Code Push Défini",
            "fy31": "Copier",
            "fy32": "Configuration Push personnalisée",
            "fy33": "Entrez un schéma de correspondance automatique de code Push défini",
            "fy34": "Sexe (sélection unique)",
            "fy35": "Âge (choix Multiple)",
            "fy36": "Catégories d'intérêt (sélection unique)",
            "fy37": "Régions Nationales",
            "fy38": "Déterminer",
            "fy39": "Illimité",
            "fy40": "Homme",
            "fy41": "Femmes",
            "fy42": "Détails Des Données",
            "fy43": "Ajout De Visites",
            "fy44": "Nombre de fans ajoutés",
            "fy45": "Nouveau chiffre d'affaires",
            "fy46": "Montant de la transaction",
            "fy47": "Ajouter un volume de commande",
            "fy48": "Proportion De",
            "fy49": "Aujourd'hui",
            "fy50": "État de mise en place",
            "fy51": "Numéro De Commande",
            "fy52": "Temps D'achat",
            "fy53": "Cycle D'achat",
            "fy54": "Montant De L'achat",
            "fy55": "Type D'achat",
            "fy56": "Push Directionnel",
            "fy57": "Push Rapide",
            "fy58": "Détails Des Données",
            "fy59": "Toutes Les Commandes",
            "fy60": "Mise En Place Dans",
            "fy61": "Fin Du Placement",
            "fy62": "Centre de vente en gros",
            "fy63": "Marchandises sélectionnées",
            "fy64": "Code De L'article",
            "fy65": "Veuillez entrer le Code article",
            "fy66": "Rechercher",
            "fy67": "Prix Minimum",
            "fy68": "Prix Maximum",
            "fy69": "Rechercher des produits",
            "fy70": "Classement des meilleures ventes",
            "fy71": "Avant la meilleure vente",
            "fy72": "Rangée De Profits",
            "fy73": "Avant Le Profit",
            "fy74": "Rangée De Besoins",
            "fy75": "Avant La Demande",
            "fy76": "Rapidement sur les étagères",
            "fy77": "Monter les étagères manuellement",
            "fy78": "Options De Stratégie",
            "fy79": "Veuillez sélectionner un article",
            "fy80": "Succès sur les étagères",
            "fy81": "Sélection",
            "fy82": "Sélectionnez le type d'article (choix multiple possible)",
            "fy83": "Veuillez sélectionner un type d'article",
            "fy84": "Veuillez entrer le montant",
            "fy85": "Veuillez entrer le nombre d'articles",
            "fy86": "Match",
            "fy87": "System Match recommandé",
            "fy88": "Total Match",
            "fy89": "Produits",
            "fy90": "Re - Match",
            "fy91": "Payer Et Utiliser",
            "fy92": "Veuillez Entrer",
            "fy93": "Numérique",
            "fy94": "Veuillez sélectionner un type d'article",
            "fy95": "Veuillez entrer le nombre d'articles",
            "fy96": "Veuillez entrer le montant",
            "fy97": "Rechercher des produits",
            "fy98": "Code De L'article",
            "fy99": "Copier",
            "fy100": "Prix D'achat",
            "fy101": "Prix Promotionnels",
            "fy102": "Marchandises En Bas",
            "fy103": "Dépôt De Garantie",
            "fy104": "Soumettre",
            "fy105": "Toutes les marchandises",
            "fy106": "Nombre de commentaires",
            "fy107": "Opérations",
            "fy108": "Voir Les Détails",
            "fy109": "Ce Produit Est Total",
            "fy110": "Commentaires"
        },
        "sys": {
            "fy1": "Messages Système",
            "fy2": "Fermer",
            "fy3": "Détails",
            "fy4": "Aller À L'expédition"
        },
        "myCenter": {
            "fy1": "Se déconnecter du login",
            "fy2": "Centre Personnel",
            "fy3": "Mes Commissions",
            "fy4": "Mon Invitation",
            "fy5": "Mon Portefeuille",
            "fy6": "Scratch",
            "fy7": "Dépôts",
            "fy8": "Présente",
            "fy9": "Détails de facturation",
            "fy10": "Liaison",
            "fy11": "Services fonctionnels",
            "fy12": "Modifier le mot de passe",
            "fy13": "Modifier le mot de passe de paiement",
            "fy14": "Définir un mot de passe de paiement",
            "fy15": "Informations sur la boutique",
            "fy16": "Attention",
            "fy17": "Volume De Visites",
            "fy18": "Promouvoir Le Solde",
            "fy19": "Mon Solde",
            "fy20": "Mes Commissions",
            "fy21": "Mon retour à la maison",
            "fy22": "Boutiques Invitées",
            "fy23": "Code D'invitation",
            "fy24": "Inviter à se connecter",
            "fy25": "Code D'invitation",
            "fy26": "Nombre de personnes au premier niveau",
            "fy27": "Nombre de personnes de niveau II",
            "fy28": "Nombre de personnes au troisième niveau",
            "fy29": "Mon Invitation",
            "fy30": "Mes utilisateurs invités",
            "fy31": "Enregistrement des invitations / gains",
            "fy32": "Solde",
            "fy33": "Promouvoir Le Solde",
            "fy34": "Veuillez Entrer",
            "fy35": "Le Plus Convertible",
            "fy36": "Soumettre",
            "fy37": "Recharger Mon Solde",
            "fy38": "Max. Peut Être Entré",
            "fy39": "Veuillez entrer le montant du Scrap",
            "fy40": "Scratch Réussi",
            "fy41": "Montant Du Dépôt",
            "fy42": "Veuillez entrer le montant du dépôt",
            "fy43": "Conseils Chaleureux",
            "fy44": "Est une plate - forme de commerce électronique qui vous permet d'acheter en ligne ce que vous connaissez, tout en découvrant de nouveaux magasins et vendeurs, en recherchant des articles essentiels du quotidien dans les magasins ou en parcourant une vaste sélection d'articles dans les catégories Santé & beauté, articles électroniques, mode, Maison & vie, bébés & jouets et plus encore.",
            "fy45": "Montant de la recharge",
            "fy46": "Adresse",
            "fy47": "Copier",
            "fy48": "Recharge UnionPay, veuillez contacter le service clientèle pour les affaires",
            "fy49": "Veuillez télécharger le justificatif de paiement",
            "fy50": "Soumettre",
            "fy51": "Veuillez entrer le montant",
            "fy52": "Pas encore de canal de paiement",
            "fy53": "Upload Max",
            "fy54": "Mode De Présentation",
            "fy55": "Veuillez choisir une méthode de retrait",
            "fy56": "Tarifs Des Services",
            "fy57": "Montant À Retirer",
            "fy58": "Veuillez entrer le montant",
            "fy59": "Frais De Service",
            "fy60": "Activités",
            "fy61": "Peut Balance",
            "fy62": "Adresse Incarnée",
            "fy63": "Présente",
            "fy64": "Mot de passe de paiement",
            "fy65": "Veuillez entrer le mot de passe de paiement",
            "fy66": "Aller Payer",
            "fy67": "Veuillez entrer le montant",
            "fy68": "Veuillez ajouter une méthode de retrait",
            "fy69": "Détails de facturation",
            "fy70": "Le Temps",
            "fy71": "Numéro De Commande",
            "fy72": "Montant",
            "fy73": "Remarques",
            "fy74": "Liaison",
            "fy75": "Veuillez sélectionner un accord",
            "fy76": "Adresse du portefeuille",
            "fy77": "Veuillez Entrer",
            "fy78": "Sauvegarder",
            "fy79": "Nouveau Succès",
            "fy80": "Modifier",
            "fy81": "Ajouter",
            "fy82": "Adresse",
            "fy83": "Veuillez sélectionner le réseau principal",
            "fy84": "Veuillez sélectionner un accord",
            "fy85": "Adresse du portefeuille",
            "fy86": "Veuillez Entrer",
            "fy87": "Sauvegarder",
            "fy88": "Supprimer",
            "fy89": "Modification Réussie",
            "fy90": "Nouveau Succès",
            "fy91": "Supprimer avec succès",
            "fy92": "Mise à jour des informations de la boutique",
            "fy93": "Veuillez entrer le nom du magasin",
            "fy94": "Sauvegarder",
            "fy95": "Upload Max",
            "fy96": "Veuillez entrer le nom du magasin"
        }, size: {
            fy1: 'Dimensions'
        }
    }
}
