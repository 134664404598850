export default {
    receiving:'Menerima waktu',
    shipments:'waktu pengerjaan',
    rate:'Tingkat keuntungan',
    rem: 'Ingat kata sandi rekening',
    confirm:'Konfirmasi penerimaan',

    size:'tingginya',
    color:'warna',
    newAdd:{
        fy1: 'Skor kredit',
        fy2: 'Setoran',
        fy3: 'Jumlah produk yang tersedia untuk dicantumkan adalah',
        fy4: 'Saya sudah tahu',
        fy5: 'Deskripsi Paket'
    },

    add4:{
        t: 'Simpan data',
        dj: 'Tingkat simpan',
        bl: 'nisbah komisi',
        je: 'Angkat ulang',
        jl: "Memberikan balasan",
        ll: 'Dukungan lalu lintas sehari-hari'
    },
    out: 'Dikeluarkan dari rak untuk pemeliharaan, silakan hubungi layanan pelanggan',
    "add2" : {" fy1 ": " ID positif",fy2 : "bertolak belakang sesuai dengan identitas", "fy3" : "sesuai dengan identitas yang digenggam"},
    add3:{
        fy1: 'Total harga barang',
        fy2: 'keuntungan per potong',
        fy3: 'Total Keuntungan'
    },
    dongjies:'Akun ini telah dibekukan, silakan hubungi layanan pelanggan',
    dongjie:'Dana telah dibekukan, silakan hubungi layanan pelanggan',
    mg: { // komentar
        fy51: 'Pergi Komentar',
        fy52: 'Berkomentar',
        fy53: 'Tulis ulasan',
        fy54: 'Evaluasi Produk',
        fy55: 'Tulis ulasan',
        fy56: 'Tinggalkan komentar berharga Anda',
        fy57: 'Merusak yang baik',
        fy58: 'Berkomitmen',
        fy59: 'Sangat miskin',
        fy60: 'Sangat miskin',
        fy61: 'Umum',
        fy62: 'Sangat bagus',
        fy63: 'Sangat bagus',
    },
    newadd: {
        fy1: 'Nama Pemegang Kartu',
        fy2: 'Silakan masukkan nama pemegang kartu',
        ff3: 'Nomor kartu bank',
        fy4: 'Silakan masukkan nomor kartu bank',
        fY5: 'Nama Bank',
        fy6: 'Silakan masukkan nama bank',
        fy7: 'Kode Bank',
        fy8: 'Silakan masukkan kode bank',
        fY9: 'Pengiriman e-commerce',
        fy10: 'Sederhanakan proses pengiriman Anda dan berikan pelanggan Anda opsi pengiriman yang cepat dan nyaman. Platform kami memastikan bahwa barang Anda dikirim dengan aman dan tepat waktu, setiap saat.',
        fY11: 'Pelacakan waktu nyata',
        fy12: 'Terus beri tahu pelanggan Anda dan lacak pembaruan secara real-time di setiap langkah. Platform kami memberi Anda pengalaman yang Anda butuhkan untuk memberikan pengiriman terbaik di kelasnya.',
        fY13: "skalabilitas",
        fy14: 'Seiring pertumbuhan bisnis Anda, begitu juga platform kami. Jaringan pengiriman kami dirancang untuk mengikuti bisnis Anda, memastikan Anda dapat menangani volume pengiriman yang meningkat.'
    },
    components: {
        fy1: 'Bagian',
        fy2: 'Pembayaran',
        fy3: 'Lupakan Kata Sandi',
        fy4: 'Pengaturan Segera',
        fy5: 'Total',
        fy6: 'Komoditas',
        fy7: 'Pergi memilih produk yang Anda suka',
        fy8: 'Silakan tambah alamat pengiriman pertama',
        fy9: 'Silakan tetapkan kata sandi pembayaran pertama',
        fy10: 'Silakan Masukkan Sandi',
        fy11: 'Untuk Membayar',
        fy12: 'Silakan masukkan sandi pembayaran',
        fy13: 'Kemaskini Kata Sandi',
        fy14: 'Tetapkan Sandi',
        fy15: 'Kata Sandi Lama',
        fy16: 'Sandi',
        fy17: 'Silakan Masukkan',
        fy18: 'Kata Sandi Baru',
        fy19: 'Konfirmasi Sandi',
        fy20: 'Silakan masukkan sandi baru',
        fy21: 'Silakan konfirmasi kata sandinya',
        fy22: 'Simpan',
        fy23: 'Silakan masukkan kata sandi 6 digit',
        fy24: 'Silakan masukkan kata sandi 6 digit yang benar',
        fy25: 'Kata sandi yang dimasukkan dua kali tidak cocok',
        fy26: 'Berita',
        fy27: 'Kirim',
        fy28: 'Mengupload Gambar Gagal',
        fy29: 'Silakan Masukkan Atribut',
        fy30: 'Konfirmasi',
        fy31: 'Muat Lebih',
        fy32: 'Pembeli',
        fy33: 'Penjual',
        fy34: 'Kotak Surat',
        fy35: 'Telepon',
        fy36: 'Sandi',
        fy37: 'Lupakan Kata Sandi',
        fy38: 'Register',
        fy39: 'Login',
        fy40: 'Daftar masuk menggunakan telepon',
        fy41: 'Daftar masuk menggunakan email',
        fy42: 'Bantuan',
        fy43: 'Silakan masukkan nomor telepon',
        fy44: 'Silakan masukkan alamat email',
        fy45: 'Verifikasi Surel',
        fy46: 'Verifikasi Ponsel',
        fy47: 'Silakan masukkan kode verifikasi',
        fy48: 'Muat Lebih',
        fy49: 'Muat Lebih',
        fy50: 'Pilih Jenis Anda',
        fy51: 'Anak-Anak',
        fy52: 'Gadis',
        fy53: 'Langkah Berikutnya',
        fy54: 'Pilih Usiamu',
        fy55: 'Pilih Kepentingan Anda',
        fy56: 'Lkuti Setidaknya 4 Kepentingan',
        fy57: 'Mulai Mengeksplorasi',
        fy58: 'Tukar ke atas untuk memilih usia',
        fy59: 'Kirim Kode Verifikasi',
        fy60: 'Galat Kode Verifikasi',
        fy61: 'Tahun',
        fy62: 'Coba lagi dalam detik',
        fy63: 'Kode verifikasi dikirim dengan sukses',
        fy64: 'Reset berhasil, silakan log masuk',
        fy65: 'Apakah Anda yakin untuk meluncurkan akun saat ini?',
        fy66: 'Daftar keluar dari daftar masuk',
        fy67: 'Sponsor',
        fy68: 'Penjualan Bulanan',
        fy69: 'Tidak ada data yang tersedia',
        fy70: 'Pembayaran Segera',
        fy71: 'Dompet',
        fy72: 'Untuk Dibayar',
        fy73: 'Konfirmasi Pembayaran',
        fy74: 'ID, lisensi pengemudi, paspor',
        fy75: 'Silakan mengunggah ID Anda terlebih dahulu',
        fy76: 'Aplikasi berhasil, silakan tunggu penelitian',
        fy77: 'Silakan Pilih',


    },

    buyer: {
        withdrawList: {
            fy1: "Pengunduran Rincian",
            fy2: 'Waktu',
            fy3: 'Tipe',
            fy4: 'Uang',
            fy5: 'Keadaan',
            fy6: 'Revisi Tunggu',
            fy7: 'Adopsi',
            fy8: 'Menolak'
        },
        withdraw: {
            fy1: 'Metode Embodiment',
            fy2: 'Tidak Ada',
            fy3: 'Tingkat Layanan',
            fy4: 'Jumlah Tarik',
            fy5: 'Silakan Masukkan Jumlah',
            fy6: 'Service Charge',
            fy7: 'Aktivitas',
            fy8: 'Pengunduran Alamat',
            fy9: 'Pengunduran',
            fy10: 'Kata Sandi Pembayaran',
            fy11: 'Silakan masukkan sandi pembayaran',
            fy12: 'Konfirmasi',
            fy13: 'Silakan tetapkan kata sandi pembayaran pertama',
            fy14: 'Silakan Masukkan Jumlah',
            fy15: 'Silakan Masukkan Sandi',
            fy16: 'Silakan tambah metode penarikan',
            fy17: 'Kesimbangan Yang Tersedia',
        },
        shippingAddress: {
            fy1: 'Alamat Pengiriman',
        },
        selected: {
            fy1: 'Toko Dipilih',
            fy2: 'Cari',
            fy3: 'Mengikuti',
            fy4: 'Kunjungan',
            fy5: 'Seluruh'
        },
        orderInfo: {
            fy1: 'Perintah Rincian',
            fy2: 'Nomor Perintah',
            fy3: 'Waktu Perintah',
            fy4: 'Kuantitas Produk',
            fy5: 'Penghargaan Item',
            fy6: 'Pembayaran Sebenarnya',
            fy7: 'Konfirmasi'
        },
        layout: {
            "fy1": "Tentang Kita",
            "fy2": "Kembali Ke Atas",
            "fy3": "Layanan Bantuan",
            "fy4": "Polisi Privasi",
            "fy5": "Membuka Toko",
            "fy6": "Bebas",
            "fy7": "Buka Toko Segera",
            "fy8": "Komoditas",
            "fy9": "Toko",
            "fy10": "Cari",
            "fy11": "Batalkan",
            "fy12": "Login",
            "fy13": "Lebih",
            "fy14": "Toko Dipilih",
            "fy15": "Silakan masukkan setidaknya tiga karakter untuk pencarian",
            "fy16": "Sejarah Pencarian",
            "fy17": "Mengikuti",
            "fy18": "Kunjungan",
            "fy19": "Lihat Semua",
        },
        category: {
            "fy1": "Rekomendasi Yang Dipilih",
            "fy2": "Toko Dipilih",
            "fy3": "Dipilih",

        },
        goodsDetail: {
            "fy1": "Jumlah",
            "fy2": "Volum Penjualan",
            "fy3": "Tambah ke kereta belanja",
            "fy4": "Pengiriman Mendesak",
            "fy5": "Pengiriman/Kembalian Bebas",
            "fy6": "Deskripsi Produk",
            "fy7": "Evaluasi Produk",
            "fy8": "Bintang",
            "fy9": "Tidak ada evaluasi yang tersedia saat ini",
            "fy10": "Produk ini sementara tidak tersedia untuk membeli",
        },
        home: {
            "fy1": "Diskon Terakhir",
            "fy2": "Rekomendasi produk yang bagus",
            "fy3": "Buy One Get One Free",
            "fy4": "Beli Sekarang",
            "fy5": "tawaran khusus waktu terbatas",
            "fy6": "Kiraan Mundur",
            "fy7": "Produk Popular",
            "fy8": "Pengiriman Bebas",
            "fy9": "Semua Produk",
            "fy10": "Toko Popular",
            "fy11": "Lihat Toko Populer",

        },
        myHeart: {
            "fy1": "Mengikuti",
            "fy2": "Lihat Semua",
            "fy3": "Kesimbangan dompet yang tersedia",
        },
        shopDetail: {
            "fy1": "Mengikuti",
            "fy2": "Kunjungan",
            "fy3": "Komprensif",
            "fy4": "Waktu",
            "fy5": "Volum Penjualan",
            "fy6": "Harga",
            "fy7": "Lihat Semua",
        },
        personal: {
            "fy1": "Daftar keluar dari daftar masuk",
            "fy2": "Pusat Pribadi",
            "fy3": "Lnformasi Dasar",
            "fy4": "Dompetku.",
            "fy5": "Deposit",
            "fy6": "Pengunduran",
            "fy7": "Rincian Bill",
            "fy8": "Log Deposit",
            "fy9": "Log Pengunduran",
            "fy10": "Lkat USDT",
            "fy11": "Pusat Order",
            "fy12": "Perintahku.",
            "fy13": "Alamat Pengiriman",
            "fy14": "Layanan Fungsi",
            "fy15": "Toko Dipilih",
            "fy16": "Melakukan Untuk Penyedia",
            "fy17": "Ubah Kata Sandi",
            "fy18": "Ubah kata sandi pembayaran",
            "fy19": "Tetapkan kata sandi pembayaran",
            "fy20": "Hubungi Layanan Pelanggan",
            "fy21": "Tentang Kita",
            "fy22": "Layanan Bantuan",
            "fy23": "Ubah",
            "fy24": "Tambah",
            "fy25": "Alamat",
            "fy26": "Nama",
            "fy27": "Silakan Masukkan",
            "fy28": "Lnformasi Kontak",
            "fy29": "Simpan",
            "fy30": "Hapus",
            "fy31": "Bersukses Diubah",
            "fy32": "Sangat Berhasil Ditambah",
            "fy33": "Alamat piawai tidak dapat dihapus",
            "fy34": "Dihapus Dengan Sukses",
            "fy35": "Silakan pilih jaringan utama",
            "fy36": "Silakan Pilih Protokol",
            "fy37": "Alamat Dompet",
            "fy38": "Silakan pilih alamat dompet USDT",
            "fy39": "Anggota Biasa",
            "fy40": "Kesimbanganku",
            "fy41": "Cari",
            "fy42": "Perhatianku",
            "fy43": "Mengikuti",
            "fy44": "Kunjungan",
            "fy45": "Seluruh",
            "fy46": "Rincian Bill",
            "fy47": "Waktu",
            "fy48": "Nomor Perintah",
            "fy49": "Uang",
            "fy50": "Catatan",
            "fy51": "Sandi yang diubah dengan sukses, silakan log masuk",
            "fy52": "Dua kata sandi tidak cocok",
            "fy53": "Kata sandi baru tidak dapat kosong",
            "fy54": "Kata sandi lama tidak dapat kosong",
            "fy55": "Ubah Kata Sandi",
            "fy56": "Akaunku",
            "fy57": "Kata Sandi Lama",
            "fy58": "Silakan masukkan kata sandi lama Anda",
            "fy59": "Kata Sandi Baru",
            "fy60": "Silakan masukkan sandi baru",
            "fy61": "Ulangi kata sandi baru",
            "fy62": "Silakan Ulangi Sandi",
            "fy63": "Kemaskini Kata Sandi",
            "fy64": "Tetapkan Sandi",
            "fy65": "Konfirmasi Sandi",
            "fy66": "Silakan konfirmasi kata sandinya",
            "fy67": "Silakan masukkan kata sandi 6 digit",
            "fy68": "Silakan masukkan kata sandi 6 digit yang benar",
            "fy69": "Sandi",
            "fy70": "Jumlah Deposit",
            "fy71": "Silakan masukkan jumlah deposit",
            "fy72": "Peringatan Jenis",
            "fy73": "Ini adalah platform e-commerce yang memungkinkan Anda untuk membeli produk terkenal online, menemukan toko dan penjual baru, mencari kebutuhan sehari-hari di toko, atau melayari seleksi besar produk dalam kategori seperti kesehatan dan keindahan, elektronik, mode, rumah dan kehidupan, bayi dan mainan, dll.",
            "fy74": "Jumlah Muat Ulang",
            "fy75": "Alamat USDT",
            "fy76": "Salin",
            "fy77": "UnionPay recharge, silakan hubungi sekretaris layanan pelanggan untuk menangani bisnis",
            "fy78": "Silakan mengunggah surat bayaran",
            "fy79": "Kirim",
            "fy80": "Silakan Masukkan Jumlah",
            "fy81": "Tidak ada saluran pembayaran yang tersedia",
            "fy82": "Berkas Terlalu Besar",
            "fy83": "Detail Deposit",
            "fy84": "Waktu",
            "fy85": "Tipe",
            "fy86": "Uang",
            "fy87": "Keadaan",
            "fy88": "Revisi Tunggu",
            "fy89": "Adopsi",
            "fy90": "Menolak",
            "fy91": "Apa Yang Berbelanja",
            "fy92": "Ini adalah platform e-commerce yang memungkinkan Anda untuk membeli merk yang sudah Anda ketahui online sambil menemukan toko dan penjual baru。",
            "fy93": "Cari dasar sehari-hari di toko, atau melalui seleksi besar produk dalam kategori seperti kesehatan dan keindahan, produk elektronik, fashion, home and life, babies, dan mainan。",
            "fy94": "Anda juga dapat memperhatikan aktivitas promosional yang tak berakhir di platform kami. Menemukan perjanjian flash sehari-hari, bermain permainan untuk memenangkan produk, atau menonton konferensi Live untuk menikmati diskon eksklusif streaming live。",
            "fy95": "Ketika Anda siap untuk membuat pembelian, pastikan platform memungkinkan Anda untuk membeli melalui saluran yang aman dan cerdas. Anda dapat melacak nilai dan penelitian produk, menggunakan Garansi untuk pembayaran bebas risiko, dan dengan mudah melacak status pengiriman produk Anda。",
            "fy96": "Jika Anda ingin belajar lebih banyak informasi, silakan melayari artikel pusat bantuan belanja terakhir kita untuk memulai, atau Anda dapat mengakhiri tur cepat melalui video ini。",
            "fy97": "Perintahku.",
            "fy98": "Nomor Perintah",
            "fy99": "Waktu Pembelian",
            "fy100": "Pembayaran Sebenarnya",
            "fy101": "Rincian",
            "fy102": "Refund",
            "fy103": "Hubungi Penjual",
            "fy104": "Seluruh",
            "fy105": "Pembayaran Pembeli",
            "fy106": "Menunggu Pengiriman",
            "fy107": "Dikirim",
            "fy108": "Pengiriman Dalam Proses",
            "fy109": "Penerimaan Pembeli",
            "fy110": "Kembali",
            "fy111": "Kembalian Lengkap",
            "fy112": "Melakukan Untuk Penyedia",
            "fy113": "Ponsel",
            "fy114": "Kotak Surat",
            "fy115": "Silakan masukkan alamat email Anda",
            "fy116": "Silakan masukkan nomor telepon",
            "fy117": "Kode Verifikasi",
            "fy118": "Silakan masukkan kode verifikasi",
            "fy119": "Kirim Dari Baru",
            "fy120": "Dapatkan Kode Verifikasi",
            "fy121": "Sandi",
            "fy122": "Silakan Masukkan Sandi",
            "fy123": "Kode Rekomendasi",
            "fy124": "Silakan masukkan kode rekomendasi",
            "fy125": "Kategori Penyedia",
            "fy126": "Silakan pilih tipe pedagang",
            "fy127": "Simpan Nama",
            "fy128": "Silakan masukkan nama toko",
            "fy129": "Simpan IOGO",
            "fy130": "Silakan mengunggah logo toko",
            "fy131": "ID, lisensi pengemudi, paspor",
            "fy132": "Silakan mengunggah ID Anda, lisensi pengemudi, dan paspor",
            "fy133": "Kirim",
            "fy134": "Silakan pilih sebuah kategori",
            "fy135": "Verifikasi Ponsel Gagal",
            "fy136": "Verifikasi Surel Gagal",
            "fy137": "Silakan pilih tipe pedagang",



        },
    }
    ,

    seller: {
        layout: {
            "fy1": "Halaman Rumah",
            "fy2": "Alat Dasar",
            "fy3": "Layanan Online",
            "fy4": "Pesan Sistem",
            "fy5": "Pusat Pribadi",
        },
        home: {
            "fy1": "Data Bisnis",
            "fy2": "Jumlah Perintah",
            "fy3": "Total Keuntungan",
            "fy4": "Total Volum Penjualan",
            "fy5": "Perintah Berhasil",
            "fy6": "Total Volume Transaksi",
            "fy7": "Total Kunjungan",
            "fy8": "Pada hari yang sama",
            "fy9": "Minggu Ini",
            "fy10": "Bulan Ini",
            "fy11": "Jumlah Perintah",
            "fy12": "Jumlah Perintah Selesai",
            "fy13": "Jumlah Perintah",
            "fy14": "Jumlah Perintah Selesai",
            "fy15": "Daftar keluar dari daftar masuk",
            "fy16": "Mengikuti",
            "fy17": "Kunjungan",
            "fy18": "Balas Rekening",
            "fy19": "Layanan Perintah",
            "fy20": "Semua Perintah",
            "fy21": "Perintah Menunggu",
            "fy22": "Perintah Pengiriman",
            "fy23": "Perintah Kembali/Kembali",
            "fy24": "Perintah Untuk Dievaluasi",
            fy25: "Data Bisnis",
            fy26: 'Data Bisnis',
            fy27: 'Perintahku.',
            fy28: 'Nomor Perintah',
            fy29: 'Waktu Pembelian',
            fy30: 'Pembayaran Sebenarnya',
            fy31: 'Rincian',
            fy32: 'Konfirmasi',
            fy33: 'Seluruh',
            fy34: 'Pembayaran Pembeli',
            fy35: 'Menunggu Pengiriman',
            fy36: 'Dikirim',
            fy37: 'Pengiriman Dalam Proses',
            fy38: 'Penerimaan Pembeli',
            fy39: 'Kembali',
            fy40: 'Kembali Selesai',
            fy41: 'Pembayaran Pembeli',
            fy42: 'Menunggu Pengiriman',
            fy43: 'Dikirim',
            fy44: 'Pengiriman Dalam Proses',
            fy45: 'Penerimaan Pembeli',
            fy46: 'Kembali',
            fy47: 'Kembali Selesai',
            fy48: 'Perintah Rincian',
            fy49: 'Nomor Perintah',
            fy50: 'Salin',
            fy51: 'Waktu Perintah',
            fy52: 'Kuantitas Produk',
            fy53: 'Penghargaan Item',
            fy54: 'Pembayaran Sebenarnya',
            fy55: 'Konfirmasi'

        },
        tool: {
            "fy1": "Daftar keluar dari daftar masuk",
            "fy2": "Mengikuti",
            "fy3": "Kunjungan",
            "fy4": "Alat Dasar",
            "fy5": "Pusat Wholesale",
            "fy6": "Manajemen Produk",
            "fy7": "Manajemen Perintah",
            "fy8": "Margin",
            "fy9": "Manajemen Evaluasi",
            "fy10": "Pemutakhiran Bisnis",
            "fy11": "Tendensi Atas",
            "fy12": "Layanan Online",
            "fy13": "Simpan ulang dalam proses",
            "fy14": "Tendensi Atas",
            "fy15": "Bentuk Perintah",
            "fy16": "Dorong Sasaran",
            "fy17": "Dorong Cepat",
            "fy18": "Akan ditampilkan dalam rekomendasi populer",
            "fy19": "Hari",
            "fy20": "Exclusif untuk toko baru",
            "fy21": "Akan ada di masa depan",
            "fy22": "Toko Yang Direkomendasikan",
            "fy23": "Gaji Yang Diperlukan",
            "fy24": "Baca Dan Universal《Perjanjian Layanan》",
            "fy25": 'Konfirmasi Pembayaran',
            fy26: 'Tidak cukup keseimbangan, tolong isi ulang dulu',
            fy27: 'Rekomendasikan toko untuk pengguna potensi',
            fy28: 'Rekomendasi Intelijen Sistem',
            fy29: 'Dorong Direksi Suai',
            fy30: 'Kode Doronganku',
            fy31: 'Salin',
            fy32: 'Konfigurasi Tekan Tersendiri',
            fy33: 'Masukkan skema pemadaman otomatis kode dorong tetap',
            fy34: 'Gender (Pilihan Tunggal)',
            fy35: 'Umur (Pilihan Berbilang)',
            fy36: 'Kategori bunga (pilihan tunggal)',
            fy37: 'Negara/Wilayah',
            fy38: 'Konfirmasi',
            fy39: 'Tidak Terbatas',
            fy40: 'Laki-Laki',
            fy41: 'Wanita',
            fy42: 'Rincian Data',
            fy43: 'Kunjungan Baru',
            fy44: 'Jumlah Penggemar Baru',
            fy45: 'Volum Transaksi Baru',
            fy46: 'Jumlah Transaksi',
            fy47: 'Kuantitas Perintah Baru',
            fy48: 'Proporci',
            fy49: 'Hari Lni',
            fy50: 'Status Pelancaran',
            fy51: 'Nomor Perintah',
            fy52: 'Waktu Pembelian',
            fy53: 'Siklus Pembelian',
            fy54: 'Jumlah Pembelian',
            fy55: 'Jenis Pembelian',
            fy56: 'Dorong Sasaran',
            fy57: 'Dorong Cepat',
            fy58: 'Rincian Data',
            fy59: 'Semua Perintah',
            fy60: 'Dalam Peluncuran',
            fy61: 'Akhir Peluncuran',
            fy62: 'Pusat Wholesale',
            fy63: 'Produk Dipilih',
            fy64: 'Kode Produk',
            fy65: 'Silakan masukkan kode produk',
            fy66: 'Cari',
            fy67: 'Harga Minimum',
            fy68: 'Harga Maksimum',
            fy69: 'Cari Produk',
            fy70: 'Ranking Bestseller',
            fy71: 'Sebelum menjual dengan baik',
            fy72: 'Rangkaian Keuntungan',
            fy73: 'Sebelum Keuntungan',
            fy74: 'Rangkaian Perlukan',
            fy75: 'Sebelum Permintaan',
            fy76: 'Daftar Cepat',
            fy77: 'Penjara Manual',
            fy78: 'Pilihan Strategi',
            fy79: 'Silakan Pilih Produk',
            fy80: 'Lanjutkan Dengan Sukses',
            fy81: 'Pilihan',
            fy82: 'Pilih Tipe Produk (Banyak Pilihan Dibenarkan)',
            fy83: 'Silakan pilih jenis produk',
            fy84: 'Silakan Masukkan Jumlah',
            fy85: 'Silakan masukkan jumlah produk',
            fy86: 'Cocok',
            fy87: 'Rekomendasi Persamaan Sistem',
            fy88: 'Ko Cocok',
            fy89: 'Ltem',
            fy90: 'Menghantar',
            fy91: 'Bayar Dan Gunakan',
            fy92: 'Silakan Masukkan',
            fy93: 'Nomor',
            fy94: 'Silakan pilih jenis produk',
            fy95: 'Silakan masukkan jumlah produk',
            fy96: 'Silakan Masukkan Jumlah',
            fy97: 'Cari Produk',
            fy98: 'Kode Produk',
            fy99: 'Salin',
            fy100: 'Harga Pembelian',
            fy101: 'Harga Promosi',
            fy102: 'Produk Dari Rak',
            fy103: 'Margin',
            fy104: 'Kirim',
            fy105: 'Semua Produk',
            fy106: 'Jumlah Komentar',
            fy107: 'Operasi',
            fy108: 'Lihat Rincian',
            fy109: 'Produk ini memiliki total',
            fy110: 'Komentar'
        },
        sys: {
            "fy1": "Pesan Sistem",
            "fy2": "Kemasi",
            "fy3": "Rincian",
            "fy4": "Untuk Kapal",
        },
        myCenter: {
            "fy1": "Daftar keluar dari daftar masuk",
            "fy2": "Pusat Pribadi",
            "fy3": "Delegasi Saya",
            "fy4": "Undanganku",
            "fy5": "Dompetku.",
            "fy6": "Transfer",
            "fy7": "Deposit",
            "fy8": "Pengunduran",
            "fy9": "Rincian Bill",
            "fy10": "Mengikat",
            "fy11": "Layanan Fungsi",
            "fy12": "Ubah Kata Sandi",
            "fy13": "Ubah kata sandi pembayaran",
            "fy14": "Tetapkan kata sandi pembayaran",
            "fy15": "Simpan Lnformasi",
            "fy16": "Mengikuti",
            "fy17": "Kunjungan",
            "fy18": "Kesimbangan Promosi",
            "fy19": "Kesimbanganku",
            "fy20": "Delegasi Saya",
            "fy21": "Komisiku",
            "fy22": "Toko Yang Diundang",
            "fy23": "Kode Undangan",
            "fy24": "Panggil Sambungan",
            fy25: 'Kode Undangan',
            fy26: 'Jumlah personel tingkat pertama',
            fy27: 'Jumlah Staf Sekunder',
            fy28: 'Jumlah personel tingkat ketiga',
            fy29: 'Undanganku',
            fy30: 'Pengguna yang diundang saya',
            fy31: 'Rekaman Invitation/Benefit',
            fy32: 'Balance',
            fy33: 'Kesimbangan Promosi',
            fy34: 'Silakan Masukkan',
            fy35: 'Maksimum Penebus',
            fy36: 'Kirim',
            fy37: 'Memuatkan Kembali Keseimbanganku',
            fy38: 'Lnput Maksimum',
            fy39: 'Silakan masukkan jumlah transfer',
            fy40: 'Transfer Berhasil',
            //------
            fy41: 'Jumlah Deposit',
            fy42: 'Silakan masukkan jumlah deposit',
            fy43: 'Peringatan Jenis',
            fy44: 'Ini adalah platform e-commerce yang memungkinkan Anda untuk membeli produk terkenal online, menemukan toko dan penjual baru, mencari kebutuhan sehari-hari di toko, atau melayari seleksi besar produk dalam kategori seperti kesehatan dan kecantikan, elektronik, fashion, home and life, bayi dan mainan, dll.。',
            fy45: 'Jumlah Muat Ulang',
            fy46: 'Alamat',
            fy47: 'Salin',
            fy48: 'UnionPay recharge, silakan hubungi sekretaris layanan pelanggan untuk menangani bisnis',
            fy49: 'Silakan mengunggah surat bayaran',
            fy50: 'Kirim',
            fy51: 'Silakan Masukkan Jumlah',
            fy52: 'Tidak ada saluran pembayaran yang tersedia',
            fy53: 'Sampai Mengunggah',
            //*-*-*--
            fy54: 'Metode Tarik',
            fy55: 'Silakan pilih metode penarikan',
            fy56: 'Tingkat Layanan',
            fy57: 'Jumlah Tarik',
            fy58: 'Silakan Masukkan Jjumlah',
            fy59: 'Service Charge',
            fy60: 'Aktivitas',
            fy61: 'Balance Tersedia',
            fy62: 'Pengunduran Alamat',
            fy63: 'Pengunduran',
            fy64: 'Kata Sandi Pembayaran',
            fy65: 'Silakan masukkan sandi pembayaran',
            fy66: 'Untuk Membayar',
            fy67: 'Silakan Masukkan Jumlah',
            fy68: 'Silakan tambah metode penarikan',
            // ----
            fy69: 'Rincian Bill',
            fy70: 'Waktu',
            fy71: 'Nomor Perintah',
            fy72: 'Uang',
            fy73: 'Catatan',
            fy74: 'Mengikat',
            fy75: 'Silakan Pilih Protokol',
            fy76: 'Alamat Dompet',
            fy77: 'Silakan Masukkan',
            fy78: 'Simpan',
            fy79: 'Sangat Berhasil Ditambah',
            fy80: 'Ubah',
            fy81: 'Tambah',
            fy82: 'Alamat',
            fy83: 'Silakan pilih jaringan utama',
            fy84: 'Silakan Pilih Protokol',
            fy85: 'Alamat Dompet',
            fy86: 'Silakan Masukkan',
            fy87: 'Simpan',
            fy88: 'Hapus',
            fy89: 'Bersukses Diubah',
            fy90: 'Sangat Berhasil Ditambah',
            fy91: 'Dihapus Dengan Sukses',
            fy92: 'Kemaskini Informasi Penyimpanan',
            fy93: 'Silakan masukkan nama toko',
            fy94: 'Simpan',
            fy95: 'Sampai Mengunggah',
            fy96: 'Silakan masukkan nama toko'
        },
        size: {
            fy1: 'ukuran'
        }
    }

}
