
export const xbUtils = {
    test:'这里是工具全局对象',
    defaultLang:'en',
    eventList:[],
    $on(name, cb) {
        if (typeof cb != 'function') {
            console.error('callBack must be a frunction')
            return new Error('callBack must be a frunction')
        }
        this.eventList[name] = this.eventList[name] || [];
        this.eventList[name].push(cb);
    },
    $emit(name,data){
        this.eventList[name]?.forEach(fn => fn(data))
    },
    /*
    *@params:name 取消监听的回调 type:[string|Array]
    *@return Object
    *@Date:2023-02-27-15:44
    *@edtor:cxiaobaiy
    *@desc: 取消回调
    */
    $off(name) {
        if (typeof name === 'string' && this.eventList[name]) {
            delete this.eventList[name];
        } else {
            try {
                let arr = name
                arr.forEach((names) => {
                    delete this.eventList[names]
                });
            } catch (e) {
                console.log(new ReferenceError(e + ''))
            }
        }
    }
}
