export default {
    receiving:'Receipt time',
    shipments:'Processing time',
    "out":"Offline maintenance in progress, please contact customer service",
    "rem":"Remember account password",
    rate:'Profit rate' ,
    confirm:'Confirm Receipt',
    "add4":{"t":"Store Data","dj":"Store Level","bl":"Commission Rate ","je":"Recharge Amount","jl":"Recharge rewards","ll":"Daily traffic support"},
    "add3":{"fy1":"Total Price Of Goods","fy2":"Single Piece Profit","fy3":"Total Profit"},
    "add2":{"fy1":"Id Photo Front","fy2":"ID photo on the reverse side","fy3":"Handheld Id Photo"},
    "newAdd":{"fy1":"Credit Score","fy2":"Recharge","fy3":"The number of products that can be listed is","fy4":"I Am Aware Of It","fy5":"Package Description"},
    color:'Color',
    size:'Size',
    dongjies:'This account has been frozen, please contact customer service',
    dongjie:'Funds have been frozen, please contact customer service',
    "mg": {
        "fy1": "Interest Treasure",
        "fy2": "Coupon",
        "fy3": "Mobile Recharge",
        "fy4": "Invitation Code",
        "fy5": "In Custody",
        "fy6": "Day",
        "fy7": "Daily Yield",
        "fy8": "Minimum Purchase",
        "fy9": "Immediate subscription",
        "fy10": "Available Balance",
        "fy11": "Please enter the purchase amount",
        "fy12": "Whole",
        "fy13": "Duration Period",
        "fy14": "Daily Yield",
        "fy15": "Dividend Payout Time",
        "fy16": "Everyday",
        "fy17": "Escrow Funds",
        "fy18": "Due Return",
        "fy19": "Minimum Purchase",
        "fy20": "Expected Revenue",
        "fy21": "Immediate subscription",
        "fy22": "Minimum Purchase",
        "fy23": "Insufficient balance, please recharge and try again",
        "fy24": "Order Record",
        "fy25": "All Orders",
        "fy26": "In Progress",
        "fy27": "Completed",
        "fy28": "Remaining Time",
        "fy29": "In Progress",
        "fy30": "Completed",
        "fy31": "Order Number",
        "fy32": "Subscription Amount",
        "fy33": "Daily Profit",
        "fy34": "Duration Period",
        "fy35": "Expected Benefits",
        "fy36": "Profitable",
        "fy37": "Purchase Date",
        "fy38": "Redemption",
        "fy39": "Confirm Redemption",
        "fy40": "Cancel Redemption",
        "fy41": "Are you sure to redeem the custody funds",
        "fy42": "Note: Once the custody funds are redeemed, the interest will be cleared",
        "fy43": "Mobile Recharge",
        "fy44": "Recharge Record",
        "fy45": "Please enter your phone number",
        "fy46": "Recharge Amount",
        "fy47": "Not Selected",
        "fy48": "Please note that after successful recharge, please pay attention to the payment of the phone bill",
        "fy49": "Recharge Now",
        "fy50": "Please enter your phone number",
        "fy51": "Go Comment",
        "fy52": "Reviewed",
        "fy53": "Write A Comment",
        "fy54": "Product Evaluation",
        "fy55": "Write A Comment",
        "fy56": "Leave your precious comments",
        "fy57": "Sun Well",
        "fy58": "Submit",
        "fy59": "Very Poor",
        "fy60": "Very Poor",
        "fy61": "Commonly",
        "fy62": "Very Good",
        "fy63": "Very Good",
        "fy64": "Recharge Record",
        "fy65": "Recharged successfully",
        "fy66": "Invitation Code",
        "fy67": "Please enter the invitation code",
        "fy68": "Total Profit",
        "fy69": "Today's Profit",
        "fy70": "Incomplete",
        "fy71": "The current coupon has reached the purchase limit",
        "fy72": "Coupon threshold not reached",
        "fy73": "User Recharge",
        "fy74": "This Payment",
        "fy75": "Please enter the quantity",
        "fy76": "Quick Payment",
        "fy77": "Recharge Now",
        "fy78": "Please select your payment wallet",
        "fy79": "Current",
        "fy80": "Wave Field Chain",
        "fy81": "Ethereum Chain",
        "fy82": "Coin Security Chain",
        "fy83": "European Italian Chain",
        "fy84": "Ouyi",
        "fy85": "Chain",
        "fy86": "Channel",
        "fy87": "Preferred"
    },
    "newadd": { "fy1": "Cardholder Name", "fy2": "Please enter the cardholder's name", "fy3": "Bank Card Number", "fy4": "Please enter your bank card number", "fy5": "Bank Name", "fy6": "Please enter the bank name", "fy7": "Bank Code", "fy8": "Please enter your bank code", "fy9": "E-commerce Delivery", "fy10": "Simplify your delivery process and provide your customers with fast and convenient delivery options. Our platform ensures that your items are delivered safely and on time every time.", "fy11": "Real Time Tracking", "fy12": "Keep your customers informed of real-time tracking updates at every step. Our platform provides you with the tool experience required for first-class delivery.", "fy13": "Scalability", "fy14": "As your business grows, our platform will also grow accordingly. Our delivery network aims to follow your business and ensure that you can handle any increase in delivery volume." },
    "components": {
        "fy1": "Piece",
        "fy2": "Payment",
        "fy3": "Forgot Password",
        "fy4": "Immediate Settlement",
        "fy5": "Total",
        "fy6": "Commodity",
        "fy7": "Go pick the products you like",
        "fy8": "Please add a shipping address first",
        "fy9": "Please set the payment password first",
        "fy10": "Please enter the password",
        "fy11": "To Pay",
        "fy12": "Please enter the payment password",
        "fy13": "Update Password",
        "fy14": "Set Password",
        "fy15": "Old Password",
        "fy16": "Password",
        "fy17": "Please Enter",
        "fy18": "New Password",
        "fy19": "Confirm Password",
        "fy20": "Please enter a new password",
        "fy21": "Please confirm the password",
        "fy22": "Preserve",
        "fy23": "Please enter a 6-digit password",
        "fy24": "Please enter the correct 6-digit password",
        "fy25": "The passwords entered twice do not match",
        "fy26": "News",
        "fy27": "Send",
        "fy28": "Image Upload Failed",
        "fy29": "Please enter attributes",
        "fy30": "Confirm",
        "fy31": "Load More",
        "fy32": "Buyer",
        "fy33": "Seller",
        "fy34": "Mail",
        "fy35": "Telephone",
        "fy36": "Password",
        "fy37": "Forgot Password",
        "fy38": "Register",
        "fy39": "Login",
        "fy40": "Log in using the phone",
        "fy41": "Log In Using Email",
        "fy42": "Help",
        "fy43": "Please enter a phone number",
        "fy44": "Please enter an email address",
        "fy45": "Email Verification",
        "fy46": "Mobile Verification",
        "fy47": "Please enter the verification code",
        "fy48": "Load More",
        "fy49": "Load More",
        "fy50": "Choose Your Gender",
        "fy51": "Boys",
        "fy52": "Girls",
        "fy53": "Next Step",
        "fy54": "Choose Your Age",
        "fy55": "Choose your interests",
        "fy56": "Follow at least 4 interests",
        "fy57": "Start Exploring",
        "fy58": "Swipe up to select age",
        "fy59": "Send verification code",
        "fy60": "Verification code error",
        "fy61": "Year",
        "fy62": "Try Again In Seconds",
        "fy63": "Successfully sent verification code",
        "fy64": "Reset successful, please log in",
        "fy65": "Are you sure to launch the current account?",
        "fy66": "Log Out Of Login",
        "fy67": "Sponsor",
        "fy68": "Monthly Sales",
        "fy69": "There is currently no data available",
        "fy70": "Immediate Payment",
        "fy71": "Wallet",
        "fy72": "To Be Paid",
        "fy73": "Confirm Payment",
        "fy74": "ID, driver's license, passport",
        "fy75": "Please upload your ID first",
        "fy76": "Application successful, please wait for review",
        "fy77": "Please Select"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Withdrawal Details",
            "fy2": "Time",
            "fy3": "Type",
            "fy4": "Money",
            "fy5": "State",
            "fy6": "Pending Review",
            "fy7": "Adopt",
            "fy8": "Refuse"
        },
        "withdraw": {
            "fy1": "Embodiment Method",
            "fy2": "Nothing",
            "fy3": "Service Rate",
            "fy4": "Withdrawal Amount",
            "fy5": "Please enter the amount",
            "fy6": "Service Charge",
            "fy7": "Activity",
            "fy8": "Withdrawal Address",
            "fy9": "Withdrawal",
            "fy10": "Payment Password",
            "fy11": "Please enter the payment password",
            "fy12": "Confirm",
            "fy13": "Please set the payment password first",
            "fy14": "Please enter the amount",
            "fy15": "Please enter the password",
            "fy16": "Please add withdrawal method",
            "fy17": "Available Balance"
        },
        "shippingAddress": {
            "fy1": "Delivery Address"
        },
        "selected": {
            "fy1": "Selected Stores",
            "fy2": "Search",
            "fy3": "Follow",
            "fy4": "Visits",
            "fy5": "Whole"
        },
        "orderInfo": {
            "fy1": "Order Details",
            "fy2": "Order Number",
            "fy3": "Order Time ",
            "fy4": "Product Quantity",
            "fy5": "Item Pricing ",
            "fy6": "Actual Payment",
            "fy7": "Confirm"
        },
        "layout": {
            "fy1": "About Us",
            "fy2": "Back To Top",
            "fy3": "Help Services",
            "fy4": "Privacy Policy",
            "fy5": "Opening A Store",
            "fy6": "Free",
            "fy7": "Open a store immediately",
            "fy8": "Commodity",
            "fy9": "Shop",
            "fy10": "Search",
            "fy11": "Cancel",
            "fy12": "Login",
            "fy13": "More",
            "fy14": "Selected Stores",
            "fy15": "Please enter at least three characters for search",
            "fy16": "Search History",
            "fy17": "Follow",
            "fy18": "Visits",
            "fy19": "View All"
        },
        "category": {
            "fy1": "Selected recommendations",
            "fy2": "Selected Stores",
            "fy3": "Selected"
        },
        "goodsDetail": {
            "fy1": "Quantity",
            "fy2": "Sales Volume",
            "fy3": "Add To Shopping Cart",
            "fy4": "Urgent Shipment",
            "fy5": "Free Shipping/return",
            "fy6": "Product Description",
            "fy7": "Product Evaluation",
            "fy8": "Star",
            "fy9": "No evaluation currently available",
            "fy10": "This product is temporarily unavailable for purchase"
        },
        "home": {
            "fy1": "Latest Discounts",
            "fy2": "Good product recommendation",
            "fy3": "Buy One Get One Free",
            "fy4": "Buy Now",
            "fy5": "Limited time special offer",
            "fy6": "Countdown",
            "fy7": "Popular Products",
            "fy8": "Free Shipping",
            "fy9": "All Products",
            "fy10": "Popular Stores",
            "fy11": "View Popular Stores"
        },
        "myHeart": {
            "fy1": "Follow",
            "fy2": "View All",
            "fy3": "Available balance of wallet"
        },
        "shopDetail": {
            "fy1": "Follow",
            "fy2": "Visits",
            "fy3": "Comprehensive",
            "fy4": "Time",
            "fy5": "Sales Volume",
            "fy6": "Price",
            "fy7": "View All"
        },
        "personal": {
            "fy1": "Log Out Of Login",
            "fy2": "Personal Center",
            "fy3": "Basic Information",
            "fy4": "My Wallet",
            "fy5": "Deposit",
            "fy6": "Withdrawal",
            "fy7": "Bill Details",
            "fy8": "Deposit Log",
            "fy9": "Withdrawal Log",
            "fy10": "Bind Usdt",
            "fy11": "Order Center",
            "fy12": "My Order",
            "fy13": "Delivery Address",
            "fy14": "Functional Services",
            "fy15": "Selected Shops",
            "fy16": "Applying for suppliers",
            "fy17": "Change Password",
            "fy18": "Change payment password",
            "fy19": "Set Payment Password",
            "fy20": "Contact customer service",
            "fy21": "About Us",
            "fy22": "Help Services",
            "fy23": "Modify",
            "fy24": "Add",
            "fy25": "Address",
            "fy26": "Name",
            "fy27": "Please Enter",
            "fy28": "Contact Information",
            "fy29": "Preserve",
            "fy30": "Delete",
            "fy31": "Successfully modified",
            "fy32": "Successfully Added",
            "fy33": "Default address cannot be deleted",
            "fy34": "Successfully Deleted",
            "fy35": "Please select the main network",
            "fy36": "Please select a protocol",
            "fy37": "Wallet Address",
            "fy38": "Please select a USDT wallet address",
            "fy39": "Ordinary Members",
            "fy40": "My Balance",
            "fy41": "Search",
            "fy42": "My Attention",
            "fy43": "Follow",
            "fy44": "Visits",
            "fy45": "Whole",
            "fy46": "Bill Details",
            "fy47": "Time",
            "fy48": "Order Number",
            "fy49": "Money",
            "fy50": "Remarks",
            "fy51": "Successfully changed password, please log in",
            "fy52": "The two passwords do not match",
            "fy53": "The new password cannot be empty",
            "fy54": "Old password cannot be empty",
            "fy55": "Change Password",
            "fy56": "My Account ",
            "fy57": "Old Password",
            "fy58": "Please enter your old password",
            "fy59": "New Password",
            "fy60": "Please enter a new password",
            "fy61": "Repeat New Password",
            "fy62": "Please repeat the password",
            "fy63": "Update Password",
            "fy64": "Set Password",
            "fy65": "Confirm Password",
            "fy66": "Please confirm the password",
            "fy67": "Please enter a 6-digit password",
            "fy68": "Please enter the correct 6-digit password",
            "fy69": "Password",
            "fy70": "Deposit Amount",
            "fy71": "Please enter the deposit amount",
            "fy72": "Kind Reminder",
            "fy73": "It is an e-commerce platform that allows you to purchase known products online, discover new stores and sellers, search for daily necessities in stores, or browse a large selection of products in categories such as health and beauty, electronics, fashion, home and life, baby and toys, etc.",
            "fy74": "Recharge Amount",
            "fy75": "Usdt Address",
            "fy76": "Copy",
            "fy77": "UnionPay recharge, please contact the customer service secretary to handle the business",
            "fy78": "Please upload payment voucher",
            "fy79": "Submit",
            "fy80": "Please enter the amount",
            "fy81": "There is currently no payment channel available",
            "fy82": "File Too Large",
            "fy83": "Deposit Details",
            "fy84": "Time",
            "fy85": "Type",
            "fy86": "Money",
            "fy87": "State",
            "fy88": "Pending Review",
            "fy89": "Adopt",
            "fy90": "Refuse",
            "fy91": "What Is Shopping",
            "fy92": "It is an e-commerce platform that allows you to purchase brands you already know online while discovering new stores and sellers.",
            "fy93": "Search for daily essentials in the store, or browse through a large selection of products in categories such as health and beauty, electronics, fashion, home and life, babies, and toys.",
            "fy94": "You can also pay attention to the endless promotional activities on our platform. Catch up with daily flash deals, play games to win products, or watch Live conferences to enjoy exclusive live streaming discounts.",
            "fy95": "When you are ready to make a purchase, please ensure that the platform allows you to purchase through a secure and seamless channel. You can browse product ratings and reviews, use Guarantee for risk-free payments, and easily track your product delivery status.",
            "fy96": "If you would like to learn more information, please browse our final shopping help center article to start, or you can end the quick tour through this video.",
            "fy97": "My Order",
            "fy98": "Order Number",
            "fy99": "Purchase Time",
            "fy100": "Actual Payment",
            "fy101": "Details",
            "fy102": "Refund",
            "fy103": "Contact The Seller",
            "fy104": "Whole",
            "fy105": "Buyer Payment",
            "fy106": "Waiting For Shipment",
            "fy107": "Shipped",
            "fy108": "Delivery In Progress",
            "fy109": "Buyer Receipt",
            "fy110": "Returning",
            "fy111": "Returned",
            "fy112": "Applying for suppliers",
            "fy113": "Mobile Phone",
            "fy114": "Mail",
            "fy115": "Please enter your email address",
            "fy116": "Please enter a phone number",
            "fy117": "Verification Code",
            "fy118": "Please enter the verification code",
            "fy119": "Send From New",
            "fy120": "Obtain verification code",
            "fy121": "Password",
            "fy122": "Please enter the password",
            "fy123": "Recommendation Code",
            "fy124": "Please enter the recommendation code",
            "fy125": "Supplier Category",
            "fy126": "Please select merchant type",
            "fy127": "Store Name",
            "fy128": "Please enter the store name",
            "fy129": "Store Logo",
            "fy130": "Please upload the store logo",
            "fy131": "ID, driver's license, passport",
            "fy132": "Please upload your ID, driver's license, and passport",
            "fy133": "Submit",
            "fy134": "Please select a category",
            "fy135": "Mobile verification failed",
            "fy136": "Email verification failed",
            "fy137": "Please select merchant type"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Home Page",
            "fy2": "Basic Tools",
            "fy3": "Online Service ",
            "fy4": "System Messages",
            "fy5": "Personal Center"
        },
        "home": {
            "fy1": "Business Data",
            "fy2": "Total number of orders",
            "fy3": "Total profit",
            "fy4": "Total sales volume",
            "fy5": "Successful Order",
            "fy6": "Total transaction volume",
            "fy7": "Total Visits",
            "fy8": "On The Same Day",
            "fy9": "This Week",
            "fy10": "This Month",
            "fy11": "Number Of Orders",
            "fy12": "Number of completed orders",
            "fy13": "Number Of Orders",
            "fy14": "Number of completed orders",
            "fy15": "Log Out Of Login",
            "fy16": "Follow",
            "fy17": "Visits",
            "fy18": "Account Balance",
            "fy19": "Order Service",
            "fy20": "All Orders",
            "fy21": "Pending Orders",
            "fy22": "Shipping Orders",
            "fy23": "Return/refund Order",
            "fy24": "Orders to be evaluated",
            "fy25": "Business Data",
            "fy26": "Business Data",
            "fy27": "My Order",
            "fy28": "Order Number",
            "fy29": "Purchase Time",
            "fy30": "Actual Payment",
            "fy31": "Details",
            "fy32": "Confirm",
            "fy33": "Whole",
            "fy34": "Buyer Payment",
            "fy35": "Waiting For Shipment",
            "fy36": "Shipped",
            "fy37": "Delivery In Progress",
            "fy38": "Buyer Receipt",
            "fy39": "Returning",
            "fy40": "Returned",
            "fy41": "Buyer Payment",
            "fy42": "Waiting For Shipment",
            "fy43": "Shipped",
            "fy44": "Delivery In Progress",
            "fy45": "Buyer Receipt",
            "fy46": "Returning",
            "fy47": "Returned",
            "fy48": "Order Details",
            "fy49": "Order Number",
            "fy50": "Copy",
            "fy51": "Order Time ",
            "fy52": "Product Quantity",
            "fy53": "Item Pricing ",
            "fy54": "Actual Payment",
            "fy55": "Confirm"
        },
        "tool": {
            "fy1": "Log Out Of Login",
            "fy2": "Follow",
            "fy3": "Visits",
            "fy4": "Basic Tools",
            "fy5": "Wholesale Center",
            "fy6": "Product Management",
            "fy7": "Order Management",
            "fy8": "Margin",
            "fy9": "Evaluation management",
            "fy10": "Business Upgrade",
            "fy11": "Top Trending",
            "fy12": "Online Service ",
            "fy13": "Store review in progress",
            "fy14": "Top Trending",
            "fy15": "Order Form",
            "fy16": "Targeted Push",
            "fy17": "Quick Push",
            "fy18": "Will be featured in popular recommendations",
            "fy19": "Day",
            "fy20": "Exclusive for new stores",
            "fy21": "Will be in the future",
            "fy22": "Recommended Stores",
            "fy23": "Required Fees",
            "fy24": "Read and use the Service Agreement",
            "fy25": "Confirm Payment",
            "fy26": "Insufficient balance, please recharge first",
            "fy27": "Recommend the store to potential users",
            "fy28": "System intelligent recommendation",
            "fy29": "Custom Directed Push",
            "fy30": "My Push Code",
            "fy31": "Copy",
            "fy32": "Custom Push Configuration",
            "fy33": "Input fixed push code automatic matching scheme",
            "fy34": "Gender (single choice)",
            "fy35": "Age (multiple choices)",
            "fy36": "Interest category (single choice)",
            "fy37": "Country/region",
            "fy38": "Confirm",
            "fy39": "Unlimited",
            "fy40": "Male",
            "fy41": "Female",
            "fy42": "Data Details",
            "fy43": "New Visits",
            "fy44": "Number Of New Fans",
            "fy45": "New transaction volume",
            "fy46": "Transaction Amount",
            "fy47": "New Order Quantity",
            "fy48": "Proportion",
            "fy49": "Today",
            "fy50": "Launch Status",
            "fy51": "Order Number",
            "fy52": "Purchase Time",
            "fy53": "Purchase Cycle",
            "fy54": "Purchase Amount",
            "fy55": "Purchase Type",
            "fy56": "Targeted Push",
            "fy57": "Quick Push",
            "fy58": "Data Details",
            "fy59": "All Orders",
            "fy60": "In Launch",
            "fy61": "End Of Launch",
            "fy62": "Wholesale Center",
            "fy63": "Product Selected",
            "fy64": "Product Code",
            "fy65": "Please enter the product code",
            "fy66": "Search",
            "fy67": "Minimum Price",
            "fy68": "Maximum Price",
            "fy69": "Search For Products",
            "fy70": "Bestseller Rankings",
            "fy71": "Before Selling Well",
            "fy72": "Profit Ranking",
            "fy73": "Before profit",
            "fy74": "Requirement Ranking",
            "fy75": "Before Demand",
            "fy76": "Quick Listing",
            "fy77": "Manual Shelving",
            "fy78": "Strategic Selection",
            "fy79": "Please select a product",
            "fy80": "Successfully launched",
            "fy81": "Choice",
            "fy82": "Select product type (multiple choices are allowed)",
            "fy83": "Please select a product type",
            "fy84": "Please enter the amount",
            "fy85": "Please enter the quantity of products",
            "fy86": "Matching",
            "fy87": "System matching recommendation",
            "fy88": "Co Matching",
            "fy89": "Item",
            "fy90": "Rematch",
            "fy91": "Pay And Use",
            "fy92": "Please Enter",
            "fy93": "Number",
            "fy94": "Please select a product type",
            "fy95": "Please enter the quantity of products",
            "fy96": "Please enter the amount",
            "fy97": "Search For Products",
            "fy98": "Product Code",
            "fy99": "Copy",
            "fy100": "Purchase Price",
            "fy101": "Promotion Price",
            "fy102": "Off Shelf Products",
            "fy103": "Margin",
            "fy104": "Submit",
            "fy105": "All Products",
            "fy106": "Number Of Comments",
            "fy107": "Operation",
            "fy108": "View Details",
            "fy109": "This product has a total of",
            "fy110": "Comments"
        },
        "sys": {
            "fy1": "System Messages",
            "fy2": "Pack Up",
            "fy3": "Details",
            "fy4": "To Ship"
        },
        "myCenter": {
            "fy1": "Log Out Of Login",
            "fy2": "Personal Center",
            "fy3": "My Delegation",
            "fy4": "My Invitation",
            "fy5": "My Wallet",
            "fy6": "Transfer",
            "fy7": "Deposit",
            "fy8": "Withdrawal",
            "fy9": "Bill Details",
            "fy10": "Binding",
            "fy11": "Functional Services",
            "fy12": "Change Password",
            "fy13": "Change payment password",
            "fy14": "Set Payment Password",
            "fy15": "Store Information",
            "fy16": "Follow",
            "fy17": "Visits",
            "fy18": "Promotion balance",
            "fy19": "My Balance",
            "fy20": "My Delegation",
            "fy21": "My Commission",
            "fy22": "Invited Store",
            "fy23": "Invitation Code",
            "fy24": "Invite Connection",
            "fy25": "Invitation Code",
            "fy26": "Number of first level personnel",
            "fy27": "Number of secondary personnel",
            "fy28": "Number of third level personnel",
            "fy29": "My Invitation",
            "fy30": "My Invited Users",
            "fy31": "Invitation/Benefit Record",
            "fy32": "Balance",
            "fy33": "Promotion Balance",
            "fy34": "Please Enter",
            "fy35": "Maximum Redeemable",
            "fy36": "Submit",
            "fy37": "Recharge My Balance",
            "fy38": "Maximum Input",
            "fy39": "Please enter the transfer amount",
            "fy40": "Transfer Successful",
            "fy41": "Deposit Amount",
            "fy42": "Please enter the deposit amount",
            "fy43": "Kind Reminder",
            "fy44": "It is an e-commerce platform that allows you to purchase known products online, discover new stores and sellers, search for daily necessities in stores, or browse a large selection of products in categories such as health and beauty, electronics, fashion, home and life, baby and toys, etc.",
            "fy45": "Recharge Amount",
            "fy46": "Address",
            "fy47": "Copy",
            "fy48": "UnionPay recharge, please contact the customer service secretary to handle the business",
            "fy49": "Please upload payment voucher",
            "fy50": "Submit",
            "fy51": "Please enter the amount",
            "fy52": "There is currently no payment channel available",
            "fy53": "Up To Upload",
            "fy54": "Withdrawal Method",
            "fy55": "Please select the withdrawal method",
            "fy56": "Service Rate",
            "fy57": "Withdrawal Amount",
            "fy58": "Please enter the amount",
            "fy59": "Service Charge",
            "fy60": "Activity",
            "fy61": "Balance Available",
            "fy62": "Withdrawal Address",
            "fy63": "Withdrawal",
            "fy64": "Payment Password",
            "fy65": "Please enter the payment password",
            "fy66": "To Pay",
            "fy67": "Please enter the amount",
            "fy68": "Please add withdrawal method",
            "fy69": "Bill Details",
            "fy70": "Time",
            "fy71": "Order Number",
            "fy72": "Money",
            "fy73": "Remarks",
            "fy74": "Binding",
            "fy75": "Please select a protocol",
            "fy76": "Wallet Address",
            "fy77": "Please Enter",
            "fy78": "Preserve",
            "fy79": "Successfully Added",
            "fy80": "Modify",
            "fy81": "Add",
            "fy82": "Address",
            "fy83": "Please select the main network",
            "fy84": "Please select a protocol",
            "fy85": "Wallet Address",
            "fy86": "Please Enter",
            "fy87": "Preserve",
            "fy88": "Delete",
            "fy89": "Successfully modified",
            "fy90": "Successfully Added",
            "fy91": "Successfully Deleted",
            "fy92": "Update store information",
            "fy93": "Please enter the store name",
            "fy94": "Preserve",
            "fy95": "Up To Upload",
            "fy96": "Please enter the store name"
        },
        size: {
            fy1: 'size'
        }
    }
}
