export default {
    receiving:'受付時間',
    shipments:'処理時間',
    size:'大きさ',
    rate:'利益率',
    "add3":{"fy1":"商品の総額","fy2":"単品利益","fy3":"総利益"},
    "out":"棚下りメンテナンス中は、カスタマーサービスにお問い合わせください",
    "add4":{"t":"店舗データ","dj":"店舗等級","bl":"コミッション比率","je":"充值金额","jl":"チャージ奨励金","ll":"毎日流量支援"},
    "rem":"アカウントパスワードを記憶する",
    confirm:'受取確認',
    color:'色',
    "add2":{"fy1":"Idは正面を照らす","fy2":"Idは裏面を照らす","fy3":"ハンドヘルドid写真"},
    "newAdd":{"fy1":"クレジット","fy2":"チャージ","fy3":"出荷可能商品数は","fy4":"私は知っている","fy5":"コースの説明"},
    dongjies:'このアカウントは凍結されています。カスタマーサービスにお問い合わせください',
    dongjie:'資金が凍結されている場合は、カスタマーサービスにお問い合わせください',
    "mg": {
        "fy1": "利子宝",
        "fy2": "クーポン券",
        "fy3": "携帯電話のチャージ",
        "fy4": "招待コード",
        "fy5": "管理中",
        "fy6": "日",
        "fy7": "日次収益率",
        "fy8": "最低購入",
        "fy9": "即時購買依頼",
        "fy10": "使用可能残高",
        "fy11": "購入金額を入力してください",
        "fy12": "すべて",
        "fy13": "継続期間",
        "fy14": "日次収益率",
        "fy15": "配当時間",
        "fy16": "毎日",
        "fy17": "供託資金",
        "fy18": "期限付き返却",
        "fy19": "最低購入",
        "fy20": "予想収益",
        "fy21": "即時購買依頼",
        "fy22": "最低購入",
        "fy23": "残高不足はチャージしてからお試しください",
        "fy24": "オーダーレコード",
        "fy25": "すべての注文",
        "fy26": "進行中",
        "fy27": "完了",
        "fy28": "ざんりゅうじかん",
        "fy29": "進行中",
        "fy30": "完了",
        "fy31": "注文番号",
        "fy32": "購買依頼金額",
        "fy33": "日利潤",
        "fy34": "継続期間",
        "fy35": "予想収益",
        "fy36": "利益を得る",
        "fy37": "購入日",
        "fy38": "請け出す",
        "fy39": "買い戻しの確認",
        "fy40": "買い戻しを取り消す",
        "fy41": "供託資金の償還を確認するか",
        "fy42": "注意：いったん信託資金を買い戻した場合、利息はすべて空になります",
        "fy43": "携帯電話のチャージ",
        "fy44": "チャージレコード",
        "fy45": "電話番号を入力してください",
        "fy46": "チャージ金額",
        "fy47": "選択されていません",
        "fy48": "チャージに成功したら通話料の入金に注意してください",
        "fy49": "即時チャージ",
        "fy50": "電話番号を入力してください",
        "fy51": "コメントに行く",
        "fy52": "コメント",
        "fy53": "コメントを書く",
        "fy54": "商品の評価",
        "fy55": "コメントを書く",
        "fy56": "あなたの貴重なコメントを残しましょう",
        "fy57": "干し物",
        "fy58": "送信",
        "fy59": "非常に悪い",
        "fy60": "ひどい",
        "fy61": "一般",
        "fy62": "いいですね",
        "fy63": "すばらしい",
        "fy64": "チャージレコード",
        "fy65": "チャージ成功",
        "fy66": "招待コード",
        "fy67": "招待コードを入力してください",
        "fy68": "総利益",
        "fy69": "今日の利益",
        "fy70": "未完了",
        "fy71": "現在のクーポンは購入上限に達しています",
        "fy72": "クーポンの敷居に達していない",
        "fy73": "ユーザチャージ",
        "fy74": "今回の支払い",
        "fy75": "数量を入力してください",
        "fy76": "迅速な支払い",
        "fy77": "即時チャージ",
        "fy78": "お支払い財布を選択してください",
        "fy79": "現在",
        "fy80": "波場鎖",
        "fy81": "エーテル坊鎖",
        "fy82": "通貨チェーン",
        "fy83": "ユークリッドチェーン",
        "fy84": "ヨーロッパ",
        "fy85": "チェーン本",
        "fy86": "チャネル",
        "fy87": "好ましい"
    },
    "newadd": { "fy1": "カード所有者名", "fy2": "カード所有者名を入力してください", "fy3": "銀行カード番号", "fy4": "銀行カード番号を入力してください", "fy5": "銀行名", "fy6": "銀行名を入力してください", "fy7": "銀行コード", "fy8": "銀行コードを入力してください", "fy9": "Eコマース配信", "fy10": "お客様の提供プロセスをシンプル化し、迅速で便利なお客様の提供オプションを提供します。デルのプラットフォームは、お客様の物品が毎回安全に時間通りに納品されることを保証します。", "fy11": "リアルタイム追跡", "fy12": "お客様にリアルタイム追跡アップデートの手順を理解してもらう。デルのプラットフォームは、優れた成果物を提供するために必要なツールの経験を提供します。", "fy13": "拡張性", "fy14": "ビジネスが成長するにつれて、デルのプラットフォームも成長していきます。デルのデリバリーネットワークは、お客様のビジネスに合わせて、追加の配送量を処理できるようにすることを目的としています。" },
    "components": {
        "fy1": "件",
        "fy2": "支払い",
        "fy3": "パスワードを忘れる",
        "fy4": "今すぐ決済",
        "fy5": "合計",
        "fy6": "商品",
        "fy7": "気になる商品を選びに行こう",
        "fy8": "先に出荷先住所を追加してください",
        "fy9": "まず支払いパスワードを設定してください",
        "fy10": "パスワードを入力してください",
        "fy11": "支払いに行く",
        "fy12": "支払いパスワードを入力してください",
        "fy13": "パスワードの更新",
        "fy14": "パスワードの設定",
        "fy15": "古いパスワード",
        "fy16": "パスワード",
        "fy17": "入力してください",
        "fy18": "新しいパスワード",
        "fy19": "パスワードの確認",
        "fy20": "新しいパスワードを入力してください",
        "fy21": "パスワードを確認してください",
        "fy22": "保存＃ホゾン＃",
        "fy23": "6桁のパスワードを入力してください",
        "fy24": "正しい6桁のパスワードを入力してください",
        "fy25": "2回入力されたパスワードが一致しません",
        "fy26": "メッセージ",
        "fy27": "送信",
        "fy28": "画像のアップロードに失敗しました",
        "fy29": "属性を入力してください",
        "fy30": "確認",
        "fy31": "追加のロード",
        "fy32": "買い手",
        "fy33": "売り手",
        "fy34": "メールボックス",
        "fy35": "電話番号",
        "fy36": "パスワード",
        "fy37": "パスワードを忘れる",
        "fy38": "登録",
        "fy39": "ログイン＃ログイン＃",
        "fy40": "電話によるログイン",
        "fy41": "メールボックスを使用したログイン",
        "fy42": "ヘルプ",
        "fy43": "電話番号を入力してください",
        "fy44": "メールアドレスを入力してください",
        "fy45": "メールボックスの検証",
        "fy46": "携帯電話の検証",
        "fy47": "認証コードを入力してください",
        "fy48": "追加のロード",
        "fy49": "追加のロード",
        "fy50": "あなたの性別を選ぶ",
        "fy51": "男の子",
        "fy52": "女の子",
        "fy53": "次のステップ",
        "fy54": "あなたの年齢を選ぶ",
        "fy55": "あなたの趣味を選ぶ",
        "fy56": "少なくとも4つの関心",
        "fy57": "探索を開始する",
        "fy58": "上にスライドして年齢を選択",
        "fy59": "認証コードの送信",
        "fy60": "認証コードエラー",
        "fy61": "歳",
        "fy62": "秒後に再試行",
        "fy63": "認証コードの送信に成功しました",
        "fy64": "リセットに成功しました。ログインしてください",
        "fy65": "現在のアカウントの公開を確認しますか？",
        "fy66": "ログオンの終了",
        "fy67": "スポンサー人",
        "fy68": "月間売上高",
        "fy69": "データが一時的にありません",
        "fy70": "即時支払い",
        "fy71": "財布",
        "fy72": "支払い保留中",
        "fy73": "支払いの確認",
        "fy74": "Id、運転免許証、パスポート",
        "fy75": "まず証明書をアップロードしてください",
        "fy76": "申請が成功しました。審査を待ってください",
        "fy77": "選択してください"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "詳細を示す",
            "fy2": "時間",
            "fy3": "を選択してオプションを設定します。",
            "fy4": "金額",
            "fy5": "ステータス",
            "fy6": "レビュー保留中",
            "fy7": "に通じをつける",
            "fy8": "拒否"
        },
        "withdraw": {
            "fy1": "具現化方式",
            "fy2": "なし",
            "fy3": "サービスレート",
            "fy4": "引き出し金額",
            "fy5": "金額を入力してください",
            "fy6": "サービス料",
            "fy7": "アクティビティ",
            "fy8": "キャッシュアドレス",
            "fy9": "現金化",
            "fy10": "支払いパスワード",
            "fy11": "支払いパスワードを入力してください",
            "fy12": "を選択して、",
            "fy13": "まず支払いパスワードを設定してください",
            "fy14": "金額を入力してください",
            "fy15": "パスワードを入力してください",
            "fy16": "現金引き出し方法を追加してください",
            "fy17": "使用可能残高"
        },
        "shippingAddress": {
            "fy1": "配送先住所"
        },
        "selected": {
            "fy1": "精選店舗",
            "fy2": "検索けんさく",
            "fy3": "に注目",
            "fy4": "アクセス",
            "fy5": "すべて"
        },
        "orderInfo": {
            "fy1": "注文の詳細",
            "fy2": "注文番号",
            "fy3": "注文時間",
            "fy4": "商品数",
            "fy5": "商品単価",
            "fy6": "払込",
            "fy7": "を選択して、"
        },
        "layout": {
            "fy1": "私たちについて",
            "fy2": "トップに戻る",
            "fy3": "ヘルプサービス",
            "fy4": "プライバシーポリシー",
            "fy5": "店を",
            "fy6": "無料",
            "fy7": "ただちに開店する",
            "fy8": "商品",
            "fy9": "店舗",
            "fy10": "検索けんさく",
            "fy11": "キャンセル",
            "fy12": "ログイン＃ログイン＃",
            "fy13": "詳細",
            "fy14": "精選店舗",
            "fy15": "少なくとも3文字を入力して検索してください",
            "fy16": "検索履歴",
            "fy17": "に注目",
            "fy18": "アクセス",
            "fy19": "すべてを表示"
        },
        "category": {
            "fy1": "推奨項目の選択",
            "fy2": "精選店舗",
            "fy3": "より抜く"
        },
        "goodsDetail": {
            "fy1": "数量",
            "fy2": "販売量",
            "fy3": "カートに追加",
            "fy4": "急速に出荷する",
            "fy5": "無料出荷/返品",
            "fy6": "商品の説明",
            "fy7": "商品の評価",
            "fy8": "星",
            "fy9": "評価なし",
            "fy10": "この商品は当分購入できません"
        },
        "home": {
            "fy1": "最新の特典",
            "fy2": "良いものの推薦",
            "fy3": "1を買うと1を贈る",
            "fy4": "今すぐ購入",
            "fy5": "時限特恵",
            "fy6": "カウントダウン",
            "fy7": "人気商品",
            "fy8": "送料無料",
            "fy9": "全商品",
            "fy10": "人気店",
            "fy11": "人気店舗の表示"
        },
        "myHeart": {
            "fy1": "に注目",
            "fy2": "すべてを表示",
            "fy3": "ウォレット使用可能残高"
        },
        "shopDetail": {
            "fy1": "に注目",
            "fy2": "アクセス",
            "fy3": "統合",
            "fy4": "時間",
            "fy5": "販売量",
            "fy6": "価格",
            "fy7": "すべてを表示"
        },
        "personal": {
            "fy1": "ログオンの終了",
            "fy2": "パーソナルセンター",
            "fy3": "基礎情報",
            "fy4": "私の財布",
            "fy5": "預金",
            "fy6": "引き出し",
            "fy7": "請求書明細",
            "fy8": "預金日誌",
            "fy9": "キャッシュログ",
            "fy10": "バインドusdt",
            "fy11": "受注センター",
            "fy12": "私の注文",
            "fy13": "配送先住所",
            "fy14": "機能サービス",
            "fy15": "セレクトショップ",
            "fy16": "購買依頼仕入先",
            "fy17": "パスワードの変更",
            "fy18": "支払パスワードの変更",
            "fy19": "支払パスワードの設定",
            "fy20": "カスタマーサービスへの連絡",
            "fy21": "私たちについて",
            "fy22": "ヘルプサービス",
            "fy23": "変更",
            "fy24": "追加",
            "fy25": "アドレス",
            "fy26": "名前",
            "fy27": "入力してください",
            "fy28": "連絡先",
            "fy29": "保存＃ホゾン＃",
            "fy30": "削除＃サクジョ＃",
            "fy31": "変更に成功しました",
            "fy32": "新規成功",
            "fy33": "デフォルトアドレスは削除できません",
            "fy34": "削除に成功しました",
            "fy35": "メインネットワークを選択してください",
            "fy36": "プロトコルを選択してください",
            "fy37": "ウォレットアドレス",
            "fy38": "USDTウォレットアドレスを選択してください",
            "fy39": "一般会員",
            "fy40": "マイ残高",
            "fy41": "検索けんさく",
            "fy42": "私の関心",
            "fy43": "に注目",
            "fy44": "アクセス",
            "fy45": "すべて",
            "fy46": "請求書明細",
            "fy47": "時間",
            "fy48": "注文番号",
            "fy49": "金額",
            "fy50": "コメント",
            "fy51": "パスワードの変更に成功しました。ログインしてください",
            "fy52": "2回のパスワードが一致しません",
            "fy53": "新しいパスワードを空にすることはできません",
            "fy54": "古いパスワードは空にできません",
            "fy55": "パスワードの変更",
            "fy56": "私のアカウント",
            "fy57": "古いパスワード",
            "fy58": "古いパスワードを入力してください",
            "fy59": "新しいパスワード",
            "fy60": "新しいパスワードを入力してください",
            "fy61": "新しいパスワードの繰り返し",
            "fy62": "パスワードを繰り返してください",
            "fy63": "パスワードの更新",
            "fy64": "パスワードの設定",
            "fy65": "パスワードの確認",
            "fy66": "パスワードを確認してください",
            "fy67": "6桁の数字のパスワードを入力してください",
            "fy68": "正しい6桁の数字のパスワードを入力してください",
            "fy69": "パスワード",
            "fy70": "預金金額",
            "fy71": "預金金額を入力してください",
            "fy72": "暖かいヒント",
            "fy73": "は、オンラインで既知の商品を購入したり、新しいショップや売り手を発見したり、ショップで日常の必需品を探したり、健康と美容、電子商品、ファッション、家と生活、赤ちゃんとおもちゃなどのカテゴリーの多くの選りすぐりの商品を閲覧したりすることができるEコマースプラットフォームです。",
            "fy74": "チャージ金額",
            "fy75": "Usdtアドレス",
            "fy76": "レプリケーション",
            "fy77": "銀聯チャージ、カスタマーサービス秘書に連絡して業務を行ってください",
            "fy78": "支払証明書をアップロードしてください",
            "fy79": "送信",
            "fy80": "金額を入力してください",
            "fy81": "当分の間支払チャネルは存在しない",
            "fy82": "ファイルが大きすぎる",
            "fy83": "預金の詳細",
            "fy84": "時間",
            "fy85": "類型",
            "fy86": "金額",
            "fy87": "ステータス",
            "fy88": "レビュー保留中",
            "fy89": "に通じをつける",
            "fy90": "拒否",
            "fy91": "ショッピングとは",
            "fy92": "は、すでに知っているブランドをオンラインで購入し、新しいショップや売り手を見つけることができるEコマースプラットフォームです。",
            "fy93": "商店内で日常必需品を探したり、健康美容、電子商品、ファッション、家庭と生活の赤ちゃんとおもちゃなどの種類を見たりして、多くの選りすぐりの商品を手に入れなければならない。",
            "fy94": "また、プラットフォーム上の無限大のプロモーション活動にも注意することができます。毎日のフラッシュ取引に間に合って、ゲームをして商品を勝ち取ったり、Live会議を見たりして、生中継の独占特典を受けたりします。",
            "fy95": "購入の準備ができたら、プラットフォームが安全でシームレスなパイプラインで購入できることを確認してください。商品の格付けやコメントを参照したり、Guaranteeを使用してリスクのない支払いをしたり、商品の配送状況を簡単に追跡することができます。",
            "fy96": "詳細については、デルの最終ショッピングヘルプセンターの記事を参照してください。または、このビデオを使用してクイックフローを終了することができます。",
            "fy97": "私の注文",
            "fy98": "注文番号",
            "fy99": "購入時期",
            "fy100": "払込",
            "fy101": "詳細",
            "fy102": "払い戻し",
            "fy103": "売り手に連絡する",
            "fy104": "すべて",
            "fy105": "買い手支払い",
            "fy106": "出荷待ち",
            "fy107": "出荷済み",
            "fy108": "配送中",
            "fy109": "バイヤー受入",
            "fy110": "退貨中",
            "fy111": "返品済",
            "fy112": "購買依頼仕入先",
            "fy113": "携帯電話",
            "fy114": "メールボックス",
            "fy115": "メールアドレスを入力してください",
            "fy116": "電話番号を入力してください",
            "fy117": "認証コード",
            "fy118": "認証コードを入力してください",
            "fy119": "新規から送信",
            "fy120": "認証コードの取得",
            "fy121": "パスワード",
            "fy122": "パスワードを入力してください",
            "fy123": "推奨コード",
            "fy124": "推奨コードを入力してください",
            "fy125": "仕入先カテゴリ",
            "fy126": "マーチャントタイプを選択してください",
            "fy127": "店舗名",
            "fy128": "店舗名を入力してください",
            "fy129": "店舗logo",
            "fy130": "店舗ロゴをアップロードしてください",
            "fy131": "Id、運転免許証、パスポート",
            "fy132": "ID、運転免許証、パスポートをアップロードしてください",
            "fy133": "送信",
            "fy134": "カテゴリを選択してください",
            "fy135": "手機驗證失敗",
            "fy136": "メールボックスの検証に失敗しました",
            "fy137": "マーチャントタイプを選択してください"
        }
    },
    "seller": {
        "layout": {
            "fy1": "トップページ",
            "fy2": "基礎ツール",
            "fy3": "オンラインカスタマーサービス",
            "fy4": "システムメッセージ",
            "fy5": "パーソナルセンター"
        },
        "home": {
            "fy1": "ビジネスデータ",
            "fy2": "受注合計数",
            "fy3": "総利益",
            "fy4": "総売上高",
            "fy5": "成功したオーダー",
            "fy6": "総取引高",
            "fy7": "総アクセス数",
            "fy8": "当日",
            "fy9": "今週",
            "fy10": "今月",
            "fy11": "オーダー数",
            "fy12": "成約オーダー数",
            "fy13": "オーダー数",
            "fy14": "成約オーダー数",
            "fy15": "ログオンの終了",
            "fy16": "に注目",
            "fy17": "アクセス",
            "fy18": "アカウント残高",
            "fy19": "オーダーサービス",
            "fy20": "すべての注文",
            "fy21": "未処理オーダー",
            "fy22": "出荷中の注文",
            "fy23": "返品/返金オーダー",
            "fy24": "評価保留中のオーダー",
            "fy25": "ビジネスデータ",
            "fy26": "ビジネスデータ",
            "fy27": "私の注文",
            "fy28": "注文番号",
            "fy29": "購入時期",
            "fy30": "払込",
            "fy31": "詳細",
            "fy32": "確認",
            "fy33": "すべて",
            "fy34": "買い手支払い",
            "fy35": "出荷待ち",
            "fy36": "出荷済み",
            "fy37": "配送中",
            "fy38": "バイヤー受入",
            "fy39": "返品中",
            "fy40": "返品済",
            "fy41": "買い手支払い",
            "fy42": "出荷待ち",
            "fy43": "已發貨",
            "fy44": "配送中",
            "fy45": "バイヤー受入",
            "fy46": "返品中",
            "fy47": "返品済",
            "fy48": "注文の詳細",
            "fy49": "注文番号",
            "fy50": "レプリケーション",
            "fy51": "注文時間",
            "fy52": "商品数",
            "fy53": "商品単価",
            "fy54": "払込",
            "fy55": "を選択して、"
        },
        "tool": {
            "fy1": "ログオンの終了",
            "fy2": "に注目",
            "fy3": "アクセス",
            "fy4": "基礎ツール",
            "fy5": "卸売センター",
            "fy6": "商品の管理",
            "fy7": "オーダー管理",
            "fy8": "デポジット",
            "fy9": "評価管理",
            "fy10": "ビジネスのアップグレード",
            "fy11": "人気が出る",
            "fy12": "オンラインカスタマーサービス",
            "fy13": "店鋪審核中",
            "fy14": "人気が出る",
            "fy15": "オーダー",
            "fy16": "指向性プッシュ",
            "fy17": "クイックプッシュ",
            "fy18": "人気のある推薦",
            "fy19": "日",
            "fy20": "新規店舗専用",
            "fy21": "未来には",
            "fy22": "おすすめ店舗",
            "fy23": "必要な費用",
            "fy24": "サービス契約書を読んで共通化",
            "fy25": "支払いの確定",
            "fy26": "残高不足は先にチャージしてください",
            "fy27": "店舗を潜在ユーザーに推薦する",
            "fy28": "システムインテリジェント推奨",
            "fy29": "カスタム指向性プッシュ",
            "fy30": "マイプッシュコード",
            "fy31": "レプリケーション",
            "fy32": "カスタムプッシュ構成",
            "fy33": "プッシュ符号自動照合スキームの入力",
            "fy34": "性別（単一選択）",
            "fy35": "年齢（複数選択）",
            "fy36": "関心カテゴリ（単一選択）",
            "fy37": "国家地区",
            "fy38": "確定",
            "fy39": "制限なし",
            "fy40": "男",
            "fy41": "女",
            "fy42": "データの詳細",
            "fy43": "新規アクセス",
            "fy44": "新規フォロワー数",
            "fy45": "新規取引高",
            "fy46": "成約金額",
            "fy47": "新規オーダー数量",
            "fy48": "占める割合",
            "fy49": "今日",
            "fy50": "投入状態",
            "fy51": "注文番号",
            "fy52": "購入時期",
            "fy53": "購入サイクル",
            "fy54": "購入金額",
            "fy55": "購入タイプ",
            "fy56": "指向性プッシュ",
            "fy57": "クイックプッシュ",
            "fy58": "データの詳細",
            "fy59": "すべての注文",
            "fy60": "投入中",
            "fy61": "投入終了",
            "fy62": "卸売センター",
            "fy63": "選択された商品",
            "fy64": "商品コード",
            "fy65": "商品コードを入力してください",
            "fy66": "検索けんさく",
            "fy67": "最小価格",
            "fy68": "最大価格",
            "fy69": "商品の検索",
            "fy70": "売れ筋ランキング",
            "fy71": "売れる前に",
            "fy72": "利益ランキング",
            "fy73": "利益前",
            "fy74": "需要ランキング",
            "fy75": "需要前",
            "fy76": "ラピッドキャリッジ",
            "fy77": "手動キャリッジアップ",
            "fy78": "戦略オプション",
            "fy79": "商品をお選びください",
            "fy80": "成功しました",
            "fy81": "せんたく",
            "fy82": "商品タイプの選択（複数選択可）",
            "fy83": "商品タイプをお選びください",
            "fy84": "金額を入力してください",
            "fy85": "商品数を入力してください",
            "fy86": "マッチング",
            "fy87": "システムマッチング推奨",
            "fy88": "共匹配",
            "fy89": "商品数",
            "fy90": "再マッチング",
            "fy91": "支払いと使用",
            "fy92": "入力してください",
            "fy93": "数値＃スウスウ＃",
            "fy94": "商品タイプをお選びください",
            "fy95": "商品数を入力してください",
            "fy96": "金額を入力してください",
            "fy97": "商品の検索",
            "fy98": "商品コード",
            "fy99": "レプリケーション",
            "fy100": "購入価格",
            "fy101": "販促価格",
            "fy102": "下積み商品",
            "fy103": "デポジット",
            "fy104": "送信",
            "fy105": "すべての商品",
            "fy106": "コメント数",
            "fy107": "操作",
            "fy108": "詳細の表示",
            "fy109": "本商品は",
            "fy110": "コメント"
        },
        "sys": {
            "fy1": "システムメッセージ",
            "fy2": "やめる",
            "fy3": "詳細",
            "fy4": "出荷に行く"
        },
        "myCenter": {
            "fy1": "ログオンの終了",
            "fy2": "パーソナルセンター",
            "fy3": "私の依頼",
            "fy4": "私の招待",
            "fy5": "私の財布",
            "fy6": "の方向をそらす",
            "fy7": "預金",
            "fy8": "現金化",
            "fy9": "請求書明細",
            "fy10": "バインド",
            "fy11": "機能サービス",
            "fy12": "パスワードの変更",
            "fy13": "支払パスワードの変更",
            "fy14": "支払パスワードの設定",
            "fy15": "店舗情報",
            "fy16": "に注目",
            "fy17": "アクセス",
            "fy18": "残高の普及",
            "fy19": "マイ残高",
            "fy20": "私の依頼",
            "fy21": "私のリベート",
            "fy22": "招待された店舗",
            "fy23": "招待コード",
            "fy24": "招待接続",
            "fy25": "招待コード",
            "fy26": "一級人数",
            "fy27": "二級人数",
            "fy28": "3級人数",
            "fy29": "私の招待",
            "fy30": "私の招待ユーザー",
            "fy31": "招待/収益記録",
            "fy32": "残高",
            "fy33": "残高の普及",
            "fy34": "入力してください",
            "fy35": "最大換算可能",
            "fy36": "送信",
            "fy37": "残高をチャージ",
            "fy38": "最大入力可能",
            "fy39": "振り替え金額を入力してください",
            "fy40": "スクロール成功",
            "fy41": "預金金額",
            "fy42": "預金金額を入力してください",
            "fy43": "暖かいヒント",
            "fy44": "は、オンラインで既知の商品を購入したり、新しいショップや売り手を発見したり、ショップで日常の必需品を探したり、健康と美容、電子商品、ファッション、家と生活、赤ちゃんとおもちゃなどのカテゴリーの多くの選りすぐりの商品を閲覧したりすることができるEコマースプラットフォームです。",
            "fy45": "チャージ金額",
            "fy46": "アドレス",
            "fy47": "レプリケーション",
            "fy48": "銀聯チャージ、カスタマーサービスの秘密取扱業務に連絡してください",
            "fy49": "支払証明書をアップロードしてください",
            "fy50": "送信",
            "fy51": "金額を入力してください",
            "fy52": "当分の間支払チャネルは存在しない",
            "fy53": "最大アップロード",
            "fy54": "キャッシュアウト方式",
            "fy55": "現金引き出し方法を選択してください",
            "fy56": "サービスレート",
            "fy57": "引き出し金額",
            "fy58": "金額を入力してください",
            "fy59": "サービス料",
            "fy60": "アクティビティ",
            "fy61": "残高可能",
            "fy62": "じつげんアドレス",
            "fy63": "現金化",
            "fy64": "支払いパスワード",
            "fy65": "支払いパスワードを入力してください",
            "fy66": "支払いに行く",
            "fy67": "金額を入力してください",
            "fy68": "現金引き出し方法を追加してください",
            "fy69": "請求書明細",
            "fy70": "時間",
            "fy71": "注文番号",
            "fy72": "金額",
            "fy73": "コメント",
            "fy74": "バインド",
            "fy75": "プロトコルを選択してください",
            "fy76": "ウォレットアドレス",
            "fy77": "入力してください",
            "fy78": "保存＃ホゾン＃",
            "fy79": "新規成功",
            "fy80": "変更",
            "fy81": "追加",
            "fy82": "アドレス",
            "fy83": "メインネットワークを選択してください",
            "fy84": "プロトコルを選択してください",
            "fy85": "ウォレットアドレス",
            "fy86": "入力してください",
            "fy87": "保存＃ホゾン＃",
            "fy88": "削除＃サクジョ＃",
            "fy89": "変更に成功しました",
            "fy90": "新規成功",
            "fy91": "削除に成功しました",
            "fy92": "店舗情報の更新",
            "fy93": "店舗名を入力してください",
            "fy94": "保存＃ホゾン＃",
            "fy95": "最大アップロード",
            "fy96": "店舗名を入力してください"
        },
        size: {
            fy1: '寸法すんぽう'
        }
    }
}
