export const calc = (symbol, number1, number2, ...args) =>{
    if(!['+','-','*','/'].includes(symbol)){
        throw new Error('第一个参数请传+ - * /计算符');
    }
    let result = 0
    if(symbol === '+'){
        result = accAdd(number1,number2)
    }
    if(symbol === '-'){
        result = accSub(number1,number2)
    }
    if(symbol === '*'){
        result = accMul(number1,number2)
    }
    if(symbol === '/'){
        result = accDiv(number1,number2)
    }
    // 有第三位计算数字
    const nextNum = args[0];
    if(nextNum || nextNum==0){
        args.shift()
        result =  calc(symbol, result, nextNum, ...args);
    }

    return result;
}


/**
 * 数字相加
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function accAdd(arg1, arg2) {
    return changeNum(arg1, arg2)
}

/**
 * 数字相减
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function accSub(arg1, arg2) {
    return changeNum(arg1, arg2, false)
}

/**
 * 数字相乘
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function accMul(arg1, arg2) {
    let m = 0;
    m = accAdd(m, getDecimalLength(arg1))
    m = accAdd(m, getDecimalLength(arg2))
    return getNum(arg1) * getNum(arg2) / Math.pow(10, m)
}

/**
 * 数字相除
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function accDiv(arg1, arg2) {
    let t1, t2;
    t1 = getDecimalLength(arg1)
    t2 = getDecimalLength(arg2)
    if(t1 - t2 > 0) {
        return (getNum(arg1) / getNum(arg2)) / Math.pow(10, t1 - t2)
    } else {
        return (getNum(arg1) / getNum(arg2)) * Math.pow(10, t2 - t1)
    }
}

function changeNum(arg1 = '', arg2 = '', isAdd = true){
    function changeInteger(arg, r, maxR){
        if(r != maxR){
            let addZero = ''
            for(let i = 0; i < maxR - r; i++){
                addZero += '0'
            }
            arg = Number(arg.toString().replace('.', '') + addZero)
        }else{
            arg = getNum(arg)
        }
        return arg
    }
    let r1, r2, maxR, m;
    r1 = getDecimalLength(arg1)
    r2 = getDecimalLength(arg2)
    maxR = Math.max(r1, r2)
    arg1 = changeInteger(arg1, r1, maxR)
    arg2 = changeInteger(arg2, r2, maxR)
    m = Math.pow(10, maxR)
    if(isAdd){
        return (arg1 + arg2) / m
    }else{
        return (arg1 - arg2) / m
    }
}

function getDecimalLength(arg = ''){
    try {
        return arg.toString().split(".")[1].length
    } catch (e) {
        return 0
    }
}

function getNum(arg = ''){
    return Number(arg.toString().replace(".", ""))
}


