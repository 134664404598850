import { createI18n } from 'vue-i18n' //引入vue-i18n组件
// import messages from './lang'
// english

const defaultLang = xb.$sys?.defaultLang?xb.$sys.defaultLang:sessionStorage.getItem('lang')
const language = xb.$sys?.lang.key?xb.$sys.lang.key:sessionStorage.getItem('lang')
const langList = ['de','en','fr','it','jp','pt','ru','sp','vi','zh','th','id','kr','may']
const lists = import.meta.glob('@/i18n/language/*.js', {eager: true})
let langMap = {}

for (let item in lists) {
    langMap[item.split('/')[4].split('.')[0]] = lists[item].default
}
const i18n = createI18n({
    fallbackLocale: 'en',
    allowComposition: true,
    globalInjection: true,
    legacy: false, // you must specify 'legacy: false' option
    locale: language??'en',
    messages:langMap,
});
export default i18n
